import React from "react";
import { Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const CustomVerticalStepLabel = ({ label }: { label: string }) => {
  return (
    <Grid container gap={3} alignItems="center">
      <FiberManualRecordIcon
        color="info"
        sx={{ width: "12px", height: "12px" }}
      />

      <Typography variant="body1" color="secondary" fontWeight={700}>
        {label}
      </Typography>
    </Grid>
  );
};

export default CustomVerticalStepLabel;
