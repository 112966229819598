import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IFeaturedProject } from "../../../../models/project.model";
import { useCountdown } from "../../../../shared/hooks/useCountdown";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone200,
  basicTone450,
  basicTone600,
  basicTone700,
  dropShadowBG,
  grayWhtTone,
  mainBorderRadius,
  mainColor1,
  mainColor2HalfTransparent,
  whiteTone,
} from "../../../../shared/styles/constants";
import { formatBigNumbersStrings, formatDate } from "../../../../shared/utils";
import FeaturedProjectStatus from "./FeaturedProjectStatus";
import Timer from "./Timer";
import { states, TStates } from "./utils";

export const FeaturedProjectCard = ({
  coverUrl,
  logoUrl,
  type,
  name,
  currentFundingRound,
  coinSymbol,
  id,
  urlName,
}: IFeaturedProject) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();
  // const [days] = useCountdown(currentFundingRound.signUpDeadline);
  const showTimer =
    currentFundingRound.status === "signUp" ||
    currentFundingRound.status === "funding" ||
    currentFundingRound.status === "pending";
  return (
    <Link
      to={`${urlName}`}
      style={{
        textDecoration: "none",
        pointerEvents: type === "placeholder" ? "none" : "all",
      }}
    >
      <Card
        sx={{
          padding: "12px",
          height: "100%",
          cursor: "pointer",
          minWidth: "274px",
          position: "relative",
          userSelect: type === "placeholder" ? "none" : undefined,
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          "&:hover": {
            backgroundColor: isLightMode ? whiteTone : basicTone600,
            border: `1px solid ${isLightMode ? grayWhtTone : basicTone450}`,
            boxShadow: isLightMode ? "none" : dropShadowBG,
          },
        }}
      >
        {type === "placeholder" && (
          <>
            <Box
              sx={{
                backgroundColor: isLightMode
                  ? mainColor2HalfTransparent
                  : "#33101d70",
                backdropFilter: "blur(15px)",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" color={basicTone0}>
                Coming soon
              </Typography>
            </Box>
          </>
        )}
        <CardMedia
          component={"img"}
          image={coverUrl!}
          alt={`featured-project-${id}`}
          sx={{
            borderTopLeftRadius: mainBorderRadius,
            borderTopRightRadius: mainBorderRadius,
            height: "192px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))",
          }}
        />
        {showTimer ? (
          <Timer
            startDate={
              currentFundingRound.status === "pending"
                ? currentFundingRound.createdAt
                : currentFundingRound.signUpStartDate
            }
            endDate={
              currentFundingRound.status === "funding"
                ? currentFundingRound.fundingDeadline
                : currentFundingRound.signUpDeadline
            }
          />
        ) : null}
        <CardContent
          sx={{
            height: "calc(100% - 224px)",
            padding: "0",
          }}
        >
          <FeaturedProjectStatus status={currentFundingRound.status} />

          <Grid
            container
            gap={1}
            alignItems="space-between"
            height={"100%"}
            sx={{ padding: "16px 8px 0px" }}
          >
            <Grid container gap={1}>
              <Grid container gap={2} flexWrap={"nowrap"} alignItems={"center"}>
                <img
                  src={logoUrl!}
                  alt={`logo-project-${id}`}
                  style={{ width: "24px", height: "24px" }}
                />
                <Typography variant="h6" color={mainColor1}>
                  {name}
                </Typography>
              </Grid>
              <Grid container gap={2}>
                {currentFundingRound.vestingChain ? (
                  <img
                    src={currentFundingRound.vestingChain.logoUrl!}
                    alt={`logo-chain-${currentFundingRound.vestingChain.chainId}`}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                    }}
                  />
                ) : null}
                <Typography variant="h6" color="secondary">
                  {coinSymbol}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="flex-end"
            >
              <Stack gap={1} width="100%">
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                >
                  <Typography
                    variant="body2"
                    color={basicTone200}
                    fontWeight={700}
                  >
                    Target amount:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight={700}
                  >
                    {`$${formatBigNumbersStrings(
                      currentFundingRound.dollarTargetAmount
                    )}`}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                >
                  <Typography
                    variant="body2"
                    color={basicTone200}
                    fontWeight={700}
                  >
                    {currentFundingRound.status === "funding"
                      ? "Funding deadline:"
                      : "Sign up deadline:"}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight={700}
                  >
                    {currentFundingRound.status === "funding"
                      ? formatDate(currentFundingRound.fundingDeadline)
                      : formatDate(currentFundingRound.signUpDeadline)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                >
                  <Typography
                    variant="body2"
                    color={basicTone200}
                    fontWeight={700}
                  >
                    Project state:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight={700}
                  >
                    {states[currentFundingRound.status as keyof TStates]}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                >
                  <Typography
                    variant="body2"
                    color={basicTone200}
                    fontWeight={700}
                  >
                    Max allocation:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight={700}
                  >
                    {`$${formatBigNumbersStrings(
                      currentFundingRound.maxAllocation
                    )}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

export default FeaturedProjectCard;
