export type TBlogPost = {
  blogLink: string;
  category: string;
  createdAt: string;
  description: string;
  id: number;
  isFeatured: boolean;
  photoUrl: string;
  title: string;
  updatedAt: string;
};
export type TBlogPosts = {
  updates: TBlogPost[];
  posts: TBlogPost[];
  mostNoticeable: TBlogPost[];
};

export const initialValues = {
  updates: [],
  posts: [],
  mostNoticeable: [],
};
