import { Button, Grid, Stack, Typography, Card, Skeleton } from "@mui/material";
import { useCall } from "@usedapp/core";
import React, { useContext, useState, useEffect } from "react";

import CreateBidOrderModal from "./ordersModals/CreateBidOrderModal";
import CreateAskOrderModal from "./ordersModals/CreateAskOrderModal";
import {
  basicTone50,
  linearBlackBG,
  mainColor2,
  secondaryBorderRadius,
} from "../../shared/styles/constants";
import { useContracts } from "../../hooks";
import { STABLE_COIN_ADDRESS } from "../../config/CoinAbi";
import { AccountContext } from "../../shared/providers/account-provider";
import BigNumber from "bignumber.js";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";

export const AccountInfoCard = () => {
  const { account } = useContext(AccountContext);
  const { isLightMode } = useContext(ThemeModeContext);
  const [balance, setBalance] = useState("0");

  const [showCreateBidOrderModal, setShowCreateBidOrderModal] =
    useState<boolean>(false);
  const [showCreateAskOrderModal, setShowCreateAskOrderModal] =
    useState<boolean>(false);

  const { stableCoinContract } = useContracts({
    stableCoinAddress: STABLE_COIN_ADDRESS,
  });
  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: symbol, error: symbolError } =
    useCall({
      contract: stableCoinContract,
      method: "symbol",
      args: [],
    }) ?? {};

  useEffect(() => {
    if (balanceOf) {
      const val = new BigNumber(balanceOf.toString()).dividedBy(1e6).toString();

      setBalance(val);
    }
  }, [balanceOf]);

  return (
    <Grid item xs={12} md={4}>
      {balanceOf && symbol ? (
        <Card sx={{ padding: 3 }}>
          <Stack sx={{ marginBottom: "24px" }}>
            <Typography variant="h4" color="secondary">
              Account info
            </Typography>
          </Stack>
          <Grid
            gap={5}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography variant="h6" color={mainColor2}>
                Balance: ${balance} {symbol}
              </Typography>
            </Stack>
            <Stack gap={2} direction="row">
              <Button
                variant="contained"
                onClick={() => setShowCreateBidOrderModal(true)}
              >
                Create bid order
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowCreateAskOrderModal(true)}
              >
                Create ask order
              </Button>
            </Stack>
          </Grid>
          <CreateBidOrderModal
            open={showCreateBidOrderModal}
            onClose={() => setShowCreateBidOrderModal(false)}
          />
          <CreateAskOrderModal
            open={showCreateAskOrderModal}
            onClose={() => setShowCreateAskOrderModal(false)}
          />
        </Card>
      ) : (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height="142px"
          sx={{
            background: isLightMode ? basicTone50 : linearBlackBG,
            borderRadius: secondaryBorderRadius,
          }}
        />
      )}
    </Grid>
  );
};

export default AccountInfoCard;
