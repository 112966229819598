export const LogoImageSVG = () => (
  <svg
    width="75"
    height="75"
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.6885 53.165C50.4766 54.1316 50.3452 55.5713 49.3137 56.2658C46.5454 58.1295 43.3894 59.3468 40.0759 59.8156C36.019 60.3896 31.8841 59.8192 28.1281 58.1675C24.3722 56.5158 21.1417 53.8471 18.7936 50.4565C16.4455 47.0659 15.0715 43.0854 14.8233 38.9552C14.5751 34.825 15.4625 30.7059 17.3873 27.0534C19.3122 23.4008 22.1995 20.357 25.73 18.2586C29.2606 16.1601 33.2969 15.0888 37.3927 15.1629C40.7381 15.2234 44.0166 16.0463 46.9877 17.5581C48.0949 18.1214 48.3977 19.5346 47.7311 20.5904C47.0646 21.6462 45.6814 21.9416 44.5603 21.407C42.299 20.3288 39.8293 19.7424 37.3123 19.6969C34.0443 19.6377 30.8238 20.4925 28.0068 22.1669C25.1898 23.8412 22.886 26.2698 21.3502 29.1841C19.8144 32.0985 19.1063 35.385 19.3044 38.6805C19.5024 41.976 20.5988 45.1519 22.4722 47.8572C24.3457 50.5626 26.9234 52.6919 29.9202 54.0097C32.917 55.3276 36.2162 55.7828 39.4531 55.3248C41.9463 54.9721 44.3276 54.0882 46.4432 52.7417C47.492 52.0741 48.9004 52.1984 49.6885 53.165Z"
      fill="#0D0D0E"
    />
    <path
      d="M49.6885 53.165C50.4766 54.1316 50.3452 55.5713 49.3137 56.2658C46.5454 58.1295 43.3894 59.3468 40.0759 59.8156C36.019 60.3896 31.8841 59.8192 28.1281 58.1675C24.3722 56.5158 21.1417 53.8471 18.7936 50.4565C16.4455 47.0659 15.0715 43.0854 14.8233 38.9552C14.5751 34.825 15.4625 30.7059 17.3873 27.0534C19.3122 23.4008 22.1995 20.357 25.73 18.2586C29.2606 16.1601 33.2969 15.0888 37.3927 15.1629C40.7381 15.2234 44.0166 16.0463 46.9877 17.5581C48.0949 18.1214 48.3977 19.5346 47.7311 20.5904C47.0646 21.6462 45.6814 21.9416 44.5603 21.407C42.299 20.3288 39.8293 19.7424 37.3123 19.6969C34.0443 19.6377 30.8238 20.4925 28.0068 22.1669C25.1898 23.8412 22.886 26.2698 21.3502 29.1841C19.8144 32.0985 19.1063 35.385 19.3044 38.6805C19.5024 41.976 20.5988 45.1519 22.4722 47.8572C24.3457 50.5626 26.9234 52.6919 29.9202 54.0097C32.917 55.3276 36.2162 55.7828 39.4531 55.3248C41.9463 54.9721 44.3276 54.0882 46.4432 52.7417C47.492 52.0741 48.9004 52.1984 49.6885 53.165Z"
      fill="url(#paint0_linear_1642_7067)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.4404 37.8143L51.3562 40.5034C52.424 43.6389 55.3701 45.8853 58.8283 45.8853C63.1909 45.8853 66.7275 42.313 66.7275 37.9063C66.7275 33.4996 63.1909 29.9273 58.8283 29.9273C55.4302 29.9273 52.5263 32.0961 51.4132 35.1476L50.4404 37.8143ZM50.5674 30.9496C52.5403 28.5619 55.5087 27.0427 58.8283 27.0427C64.7681 27.0427 69.5833 31.9065 69.5833 37.9063C69.5833 43.9061 64.7681 48.7699 58.8283 48.7699C55.4252 48.7699 52.3913 47.1733 50.4206 44.6813C49.7962 43.8917 49.2785 43.0123 48.8897 42.0653C48.8056 41.8605 48.7275 41.6526 48.6557 41.4417C48.5259 41.8598 48.3751 42.2685 48.2045 42.6666C47.8843 43.4139 47.4946 44.1237 47.0435 44.7876C44.7951 48.0969 41.0234 50.2675 36.7497 50.2675C29.8595 50.2675 24.2739 44.6255 24.2739 37.6657C24.2739 30.706 29.8595 25.064 36.7497 25.064C41.1199 25.064 44.9652 27.3337 47.1935 30.7698C47.6305 31.4437 48.0053 32.1625 48.3096 32.9178C48.4712 33.3187 48.6129 33.73 48.7335 34.1503C48.81 33.9405 48.8928 33.7338 48.9815 33.5304C49.3903 32.5933 49.9263 31.7256 50.5674 30.9496ZM46.8009 37.7764L45.9907 34.9535C44.8285 30.9042 41.1271 27.9486 36.7497 27.9486C31.4367 27.9486 27.1297 32.2991 27.1297 37.6657C27.1297 43.0324 31.4367 47.3829 36.7497 47.3829C41.0541 47.3829 44.7053 44.5252 45.9308 40.5784L46.8009 37.7764Z"
      fill="#0D0D0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.4404 37.8143L51.3562 40.5034C52.424 43.6389 55.3701 45.8853 58.8283 45.8853C63.1909 45.8853 66.7275 42.313 66.7275 37.9063C66.7275 33.4996 63.1909 29.9273 58.8283 29.9273C55.4302 29.9273 52.5263 32.0961 51.4132 35.1476L50.4404 37.8143ZM50.5674 30.9496C52.5403 28.5619 55.5087 27.0427 58.8283 27.0427C64.7681 27.0427 69.5833 31.9065 69.5833 37.9063C69.5833 43.9061 64.7681 48.7699 58.8283 48.7699C55.4252 48.7699 52.3913 47.1733 50.4206 44.6813C49.7962 43.8917 49.2785 43.0123 48.8897 42.0653C48.8056 41.8605 48.7275 41.6526 48.6557 41.4417C48.5259 41.8598 48.3751 42.2685 48.2045 42.6666C47.8843 43.4139 47.4946 44.1237 47.0435 44.7876C44.7951 48.0969 41.0234 50.2675 36.7497 50.2675C29.8595 50.2675 24.2739 44.6255 24.2739 37.6657C24.2739 30.706 29.8595 25.064 36.7497 25.064C41.1199 25.064 44.9652 27.3337 47.1935 30.7698C47.6305 31.4437 48.0053 32.1625 48.3096 32.9178C48.4712 33.3187 48.6129 33.73 48.7335 34.1503C48.81 33.9405 48.8928 33.7338 48.9815 33.5304C49.3903 32.5933 49.9263 31.7256 50.5674 30.9496ZM46.8009 37.7764L45.9907 34.9535C44.8285 30.9042 41.1271 27.9486 36.7497 27.9486C31.4367 27.9486 27.1297 32.2991 27.1297 37.6657C27.1297 43.0324 31.4367 47.3829 36.7497 47.3829C41.0541 47.3829 44.7053 44.5252 45.9308 40.5784L46.8009 37.7764Z"
      fill="url(#paint1_linear_1642_7067)"
    />
    <path
      d="M44.1557 37.5959C44.1557 41.603 40.9398 44.8513 36.9729 44.8513C33.0059 44.8513 29.79 41.603 29.79 37.5959C29.79 33.5889 33.0059 30.3406 36.9729 30.3406C40.9398 30.3406 44.1557 33.5889 44.1557 37.5959Z"
      fill="#0D0D0E"
    />
    <path
      d="M44.1557 37.5959C44.1557 41.603 40.9398 44.8513 36.9729 44.8513C33.0059 44.8513 29.79 41.603 29.79 37.5959C29.79 33.5889 33.0059 30.3406 36.9729 30.3406C40.9398 30.3406 44.1557 33.5889 44.1557 37.5959Z"
      fill="url(#paint2_linear_1642_7067)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1642_7067"
        x1="38.6938"
        y1="7.11646"
        x2="66.6355"
        y2="44.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1642_7067"
        x1="24.2739"
        y1="25.064"
        x2="71.6045"
        y2="29.7198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1642_7067"
        x1="29.79"
        y1="30.3406"
        x2="44.8974"
        y2="31.1589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
    </defs>
  </svg>
);
