import BigNumber from "bignumber.js";
import { TCoin } from "./types";
import { AvailableChains } from "../../shared/utils";

export const mapSupportedTokensList = (
  tokensList: TCoin[] | undefined,
  chainId: number | undefined
): TCoin[] => {
  return tokensList
    ? tokensList.map((coin) => ({
        ...coin,
        internalId: new BigNumber(coin.internalId.toString()).toString(),
        userBalance: "N/A",
        chainId,
        // ????
        availableTokensForSale: coin.availableTokensForSale
          ? new BigNumber(coin.availableTokensForSale.toString())
              .dividedBy(1e18)
              .toString()
          : "0",
      }))
    : [];
};

export const mapAvailableTokensForSaleList = (
  tokensList: TCoin[] | undefined,
  chainId: number | undefined
): TCoin[] => {
  return tokensList
    ? tokensList.map((coin) => ({
        ...coin,
        internalId: new BigNumber(coin.internalId.toString()).toString(),
        // userBalance: "N/A",
        chainId,
        // ????
        availableTokensForSale: coin.availableTokensForSale
          ? new BigNumber(coin.availableTokensForSale.toString())
              .dividedBy(1e18)
              .toString()
          : "0",
      }))
    : [];
};

export const getChainIdByContractIndex = (index: number) => {
  switch (index) {
    case 0:
      return AvailableChains.Polygon;
    case 1:
      return AvailableChains.BinanceTestNet;
    case 2:
      return AvailableChains.BinanceMainnet;
  }
};
