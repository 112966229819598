export const FireIconSVG = () => (
  <svg
    width="30"
    height="45"
    viewBox="0 0 30 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3386_11756)">
      <path
        d="M11.2241 0.263672C11.8361 1.7666 12.2068 2.82129 12.4051 3.62109C13.1551 6.57422 12.9224 9.17578 11.6896 11.6719C10.8361 13.3945 9.68098 14.9238 7.11201 17.71C3.58615 21.5332 2.74132 22.6406 1.6896 24.7676C0.97408 26.2178 0.422356 28.002 0.163735 29.6631C-5.81592e-05 30.709 0.0258039 32.915 0.206838 33.9697C1.00856 38.5225 3.99132 42.1172 8.61201 44.0947C9.33615 44.4023 11.1465 45 11.362 45C11.3879 45 11.0172 44.6221 10.5344 44.1562C8.69822 42.3984 7.84477 40.8428 7.84477 39.2432C7.84477 38.4258 8.03442 37.7314 8.4827 36.8613C8.92236 36.0176 9.24132 35.5693 10.5689 33.9258C11.862 32.3174 12.4396 31.4912 12.8017 30.7266C13.3534 29.5488 13.4827 28.5029 13.1982 27.3955C13.1206 27.0703 13.0861 26.8066 13.1206 26.8066C13.2672 26.8066 16.3448 30.0059 16.931 30.7617C19.7241 34.3477 21.0086 37.9951 20.4827 40.7988C20.2413 42.0732 19.6724 43.3125 18.9051 44.2002C18.6551 44.4902 18.5689 44.6484 18.6551 44.6484C18.9224 44.6484 20.7844 44.0244 21.681 43.6377C27.4482 41.124 30.3448 36.1494 29.9051 29.4873C29.7586 27.167 29.6896 26.9912 29.3965 28.0986C28.9655 29.7334 28.5258 30.665 27.862 31.3945C27.4827 31.8076 26.9224 32.2295 26.8534 32.1504C26.8275 32.124 26.8534 31.2539 26.9051 30.1992C27.0948 26.3672 26.7155 22.4561 25.8534 19.292C25.0344 16.3125 23.4913 13.2539 21.6034 10.8545C20.1724 9.04395 19.1982 8.05078 15.0861 4.20996C13.7155 2.93555 12.6293 1.82812 11.8189 0.878906C11.4655 0.474609 11.1982 0.193359 11.2241 0.263672Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3386_11756">
        <rect width="30" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
