import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

import { AccountContext } from "../../shared/providers/account-provider";
import {
  CreateAccountValues,
  registerInitialValues,
  registerValidationSchema,
} from "./register-utils";
import {  mainColor1 } from "../../shared/styles/constants";

export const CreateAccount = ({
  onCreateAccount,
}: {
  onCreateAccount?: () => void;
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = React.useState(false);
  const { handleCreateAccount, showUserExistsError, setShowUserExistsError } =
    useContext(AccountContext);

  const onSubmit = (values: CreateAccountValues) => {
    handleCreateAccount(values, onCreateAccount);
  };

  return (
    <Stack gap={2}>
      <Typography variant="h2" color="secondary">
        Create account
      </Typography>
      <Typography variant="body1">Step 1/2</Typography>

      <Formik
        initialValues={registerInitialValues}
        onSubmit={onSubmit}
        validationSchema={registerValidationSchema}
        validateOnBlur
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          dirty,
          touched,
          handleSubmit,
          setSubmitting,
          handleBlur,
        }) => {
          return (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="username"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setShowUserExistsError(false);
                    setSubmitting(false);
                    handleChange(e);
                  }}
                  placeholder="Username"
                  error={showUserExistsError}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon
                        fontSize="small"
                        color={values.username?.length ? "secondary" : "info"}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="email"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setShowUserExistsError(false);
                    setSubmitting(false);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  required
                  placeholder="E-mail"
                  error={
                    showUserExistsError || (!!errors.email && !!touched.email)
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon
                        fontSize="small"
                        color={values.email?.length ? "secondary" : "info"}
                      />
                    </InputAdornment>
                  }
                />
                {errors?.email && touched?.email ? (
                  <FormHelperText error>
                    *Please enter a valid e-mail.
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={!!errors.password && !!touched.password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  sx={{
                    color: errors.repeatedPassword ? mainColor1 : undefined,
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockRoundedIcon
                        fontSize="small"
                        color={values.password?.length ? "secondary" : "info"}
                      />
                    </InputAdornment>
                  }
         
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffRoundedIcon
                            fontSize="small"
                            color={
                              values.password?.length ? "secondary" : "info"
                            }
                          />
                        ) : (
                          <VisibilityRoundedIcon
                            fontSize="small"
                            color={
                              values.password?.length ? "secondary" : "info"
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.password && touched?.password ? (
                  <FormHelperText error>*{errors.password}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="repeatedPassword"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  sx={{
                    color: errors.repeatedPassword ? mainColor1 : undefined,
                  }}
                  placeholder="Repeat password"
                  type={showRepeatedPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockRoundedIcon
                        fontSize="small"
                        color={
                          values.repeatedPassword?.length ? "secondary" : "info"
                        }
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle repeated password visibility"
                        onMouseDown={() => setShowRepeatedPassword(true)}
                        onMouseUp={() => setShowRepeatedPassword(false)}
                        edge="end"
                      >
                        {showRepeatedPassword ? (
                          <VisibilityOffRoundedIcon
                            fontSize="small"
                            color={
                              values.repeatedPassword?.length
                                ? "secondary"
                                : "info"
                            }
                          />
                        ) : (
                          <VisibilityRoundedIcon
                            fontSize="small"
                            color={
                              values.repeatedPassword?.length
                                ? "secondary"
                                : "info"
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.repeatedPassword && touched?.repeatedPassword ? (
                  <FormHelperText error>
                    {errors.repeatedPassword}
                  </FormHelperText>
                ) : null}
              </FormControl>

              {showUserExistsError ? (
                <FormHelperText error>
                  *E-mail or username already exists.
                </FormHelperText>
              ) : null}
  
              <Button
                variant="contained"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                sx={{ height: "48px", marginTop: "24px" }}
              >
                Continue
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default CreateAccount;
