import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";

interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const blogApi: any = createApi({
  reducerPath: "blogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    getBlogPosts: builder.query<ResultFromBackend, string>({
      query: () => {
        return `/blogs/list-blog-posts`;
      },
    }),
  }),
});

export const { useGetBlogPostsQuery } = blogApi;
