import {
  Typography,
  Stack,
  Grid,
  Card,
  OutlinedInput,
  FormControl,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

import React, { useState, useEffect, useContext } from "react";
import { useContactUsMutation } from "../../services/contactApi";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone50,
  basicTone600,
  basicTone700,
  mainColor1,
  mainColor2,
} from "../../shared/styles/constants";
import { Form, Formik } from "formik";
import { contactUsValidationSchema } from "./utils";
import { NotificationsContext } from "../../shared/providers/notifications-provider";
import { useNavigate } from "react-router";
import SubmitYourProjectCard from "../../components/SubmitYourProjectCard";

type TContactFormValues = {
  name: string | undefined;
  email: string | undefined;
  message: string | undefined;
};

const initialValues: TContactFormValues = {
  name: undefined,
  email: undefined,
  message: undefined,
};

export const ContactPage = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [contactUs] = useContactUsMutation();

  const onSubmit = (values: TContactFormValues) => {
    setIsLoading(true);
    contactUs({ ...values })
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setNotification({
          type: "success",
          message: "Thank you for contacting us!",
        });
        navigate("/");
      })
      .catch((er: any) => {
        setIsLoading(false);
        setNotification({ type: "error", message: "Something went wrong..." });
      });
  };

  return (
    <div className="Content">
      <Stack
        gap={7}
        paddingTop={3}
        paddingBottom={10}
        paddingRight={1}
        paddingLeft={1}
      >
        <Card
          sx={{
            backgroundColor: isLightMode ? basicTone0 : basicTone700,
            border: `1px solid ${isLightMode ? basicTone50 : basicTone600}`,
            padding: { xs: "16px", md: "56px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            alignItems={"center"}
            gap={3}
            width={{ xs: "100%", sm: "70%", md: "50%" }}
          >
            <Stack alignItems={"center"} gap={1}>
              <Typography
                variant="h2"
                fontSize={{ xs: "32px", md: "40px" }}
                fontWeight={700}
                color="secondary"
              >{`Contact us`}</Typography>
              <Typography
                variant="body1"
                color={mainColor2}
                textAlign="center"
              >{`Get quick answers and the support you need!`}</Typography>
            </Stack>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={contactUsValidationSchema}
              validateOnBlur
              enableReinitialize
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                handleBlur,
                touched,
                dirty,
              }) => {
                return (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        color="primary"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors?.name && touched?.name}
                        required
                        placeholder="Name"
                        startAdornment={
                          <InputAdornment position="start">
                            <PersonIcon
                              fontSize="small"
                              color={values.name?.length ? "secondary" : "info"}
                            />
                          </InputAdornment>
                        }
                      />
                      {errors?.name && touched?.name ? (
                        <FormHelperText error>
                          *Please type your name.
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        color="primary"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="E-mail"
                        error={!!errors?.email && touched?.email}
                        startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon
                              fontSize="small"
                              color={
                                values.email?.length ? "secondary" : "info"
                              }
                            />
                          </InputAdornment>
                        }
                      />
                      {errors?.email && touched?.email ? (
                        <FormHelperText error>
                          *Please type a valid e-mail.
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        color="primary"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        multiline
                        error={!!errors?.message && touched?.message}
                        minRows={6}
                        placeholder="Message"
                      />
                      {errors?.message && touched?.message ? (
                        <FormHelperText error>
                          *Please send us a message.
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <Grid container justifyContent={"center"} marginTop={6}>
                      <LoadingButton
                        color="primary"
                        type={"submit"}
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<EmailIcon />}
                        variant="contained"
                        sx={{ height: "48px" }}
                      >
                        <span>Send e-mail</span>
                      </LoadingButton>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Card>
        <SubmitYourProjectCard />
      </Stack>
    </div>
  );
};

export default ContactPage;
