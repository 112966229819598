import { Button } from "@mui/material";
import { useEthers } from "@usedapp/core";
import React, { useContext } from "react";
import { MetamaskFoxSVG } from "../../assets/MetamaskFoxSVG";

import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone200,
  basicTone50,
  basicTone500,
  basicTone800,
} from "../../shared/styles/constants";
import { formatAddress } from "../../shared/utils";

export const ConnectToMetamask = () => {
  const { isLightMode } = useContext(ThemeModeContext);

  const { account, deactivate } = useEthers();
  const {
    handleConnectToMetamask,
    isConnectToMetamaskDisabled,
    isConnectedToMetamask,
    setIsConnectToMetamaskDisabled,
    setIsConnectedToMetamask,
  } = useContext(AccountContext);

  return account && isConnectedToMetamask && !isConnectToMetamaskDisabled ? (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<MetamaskFoxSVG />}
      fullWidth
      sx={{
        marginTop: "16px",
      }}
      onClick={() => {
        deactivate();
        setIsConnectedToMetamask(false);
        localStorage.removeItem("isMetamaskConnected");
      }}
    >
      Disconnect ({formatAddress(account)})
    </Button>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<MetamaskFoxSVG />}
      fullWidth
      disabled={isConnectToMetamaskDisabled}
      sx={{
        marginTop: "16px",
        backgroundColor: isLightMode ? "transparent" : basicTone500,
        "&:disabled": {
          color: isLightMode ? basicTone200 : basicTone0,
        },
        "&:hover": {
          backgroundColor: isLightMode ? basicTone50 : basicTone800,
        },
      }}
      onClick={() => {
        setIsConnectToMetamaskDisabled(true);
        handleConnectToMetamask();
      }}
    >
      Connect Metamask
    </Button>
  );
};
export default ConnectToMetamask;
