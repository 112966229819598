export const TropheyColorIconSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2568 5.84357H18.4813V4H6.51874V5.84357H5.74305C4.51029 5.84357 3.5 6.79789 3.5 7.96395C3.5 9.13001 4.50954 10.0845 5.74305 10.0845H6.57109C6.91019 12.4869 8.86118 14.4248 11.354 14.8868V17H13.646V14.8861C16.1388 14.4241 18.0896 12.4862 18.4287 10.0838H19.2568C20.4905 10.0838 21.5 9.12947 21.5 7.96323C21.5 6.797 20.4905 5.84357 19.2568 5.84357ZM6.51874 9.00039H5.74305C5.45459 8.9968 5.17923 8.88596 4.97659 8.69186C4.77394 8.49777 4.66032 8.23603 4.66032 7.96332C4.66032 7.69061 4.77394 7.42888 4.97659 7.23478C5.17923 7.04069 5.45459 6.92985 5.74305 6.92626H6.51874V9.00039ZM19.2568 9.00039H18.4813V6.92626H19.2568C19.402 6.92445 19.5463 6.94994 19.681 7.00125C19.8158 7.05256 19.9385 7.12866 20.0419 7.22514C20.1453 7.32162 20.2274 7.43655 20.2834 7.56327C20.3395 7.68999 20.3683 7.82597 20.3683 7.96332C20.3683 8.10067 20.3395 8.23665 20.2834 8.36337C20.2274 8.49009 20.1453 8.60503 20.0419 8.70151C19.9385 8.79799 19.8158 8.87409 19.681 8.9254C19.5463 8.9767 19.402 9.00219 19.2568 9.00039Z"
      fill="#FFD276"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2568 5.84367H18.4813V4H12.5V17H13.6461V14.8867C16.1389 14.4246 18.0897 12.4867 18.4288 10.0841H19.2568C20.4905 10.0841 21.5 9.12975 21.5 7.96345C21.5 6.79715 20.4905 5.84367 19.2568 5.84367ZM19.2568 9.00138H18.4813V6.92642H19.2568C19.4021 6.92462 19.5463 6.95011 19.681 7.00142C19.8158 7.05273 19.9385 7.12883 20.0419 7.22532C20.1453 7.3218 20.2274 7.43674 20.2834 7.56347C20.3395 7.6902 20.3683 7.82619 20.3683 7.96354C20.3683 8.1009 20.3395 8.23689 20.2834 8.36361C20.2274 8.49034 20.1453 8.60528 20.0419 8.70177C19.9385 8.79825 19.8158 8.87436 19.681 8.92567C19.5463 8.97698 19.4021 9.00247 19.2568 9.00066V9.00138Z"
      fill="#FFC248"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6187 17H9.38143V17.5297H8.5V21H16.5V17.5297H15.6187V17Z"
      fill="#FFC248"
    />
  </svg>
);
