import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import Image404 from "../../assets/404.png";
import { basicTone200 } from "../../shared/styles/constants";
import { useNavigate } from "react-router-dom";

export const Error404Page = () => {
  const navigate = useNavigate();
  return (
    <div className="Content">
      <Stack
        gap={4}
        py={{ xs: 10, md: 20 }}
        px={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            pt: 1,
            pb: 4,
            px: { xs: 6, md: 13 },
          }}
        >
          <img src={Image404} alt="404" style={{ width: "100%" }} />
        </Card>
        <Stack gap={1} alignItems={"center"}>
          <Typography variant="h3" color={"secondary"}>
            Ooops!
          </Typography>
          <Typography variant="h5" color={basicTone200} textAlign={"center"}>
            Sorry, we cant find the page you’re looking for
          </Typography>
        </Stack>
        <Grid container justifyContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Go to dashboard
          </Button>
        </Grid>
      </Stack>
    </div>
  );
};

export default Error404Page;
