import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
  Typography,
} from '@mui/material'

import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import DarkLightModeSwitcher from '../DarkLightModeSwitcher'
import SortIcon from '@mui/icons-material/Sort'
import SignInButton from '../SignInButton'
import NavMenuDrawer from './NavMenuDrawer'
import { Community } from './Community'
import { AccountContext } from '../../shared/providers/account-provider'
import { basicTone0 } from '../../shared/styles/constants'

import Logo from '../../logo'
import { config } from '../../config/config'

export const AppHeaderBar = () => {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = React.useState(false)
  const [showCommunity, setShowCommunity] = React.useState(false)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { DEV_MODE } = config

  const { account } = useContext(AccountContext)

  const handleOpenNavMenu = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen)
  }

  return (
    <AppBar
      position='sticky'
      color='primary'
      component={'nav'}
      sx={{ backgroundColor: '#131314' }}
    >
      <Toolbar sx={{ position: 'relative' }}>
        <Grid
          container
          wrap='nowrap'
          justifyContent={'space-between'}
          alignItems='center'
        >
          {/* logo */}
          <Link to={'/'}>
            <Logo
              width={isMobile ? 140 : undefined}
              style={{ marginTop: '8px' }}
            />
          </Link>
          {/* menu - desktop */}
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <NavLink
              to={account ? '/dashboard' : '/register'}
              style={{ textDecoration: 'none' }}
            >
              <div
                key={'Dashboard'}
                style={{
                  padding: '0 18px',
                  position: 'relative',
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <Typography fontWeight={700} color={basicTone0}>
                  {'Dashboard'}
                </Typography>
              </div>
            </NavLink>
            {DEV_MODE && (
              <NavLink to='/marketplace' style={{ textDecoration: 'none' }}>
                <div
                  key={'Marketplace'}
                  style={{
                    padding: '0 18px',
                    position: 'relative',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  <Typography fontWeight={700} color={basicTone0}>
                    {'Marketplace'}
                  </Typography>
                </div>
              </NavLink>
            )}
            <div
              key={'Community'}
              onMouseOver={() => setShowCommunity(true)}
              style={{
                padding: '0 18px',
                position: 'relative',
                display: 'flex',
                cursor: 'pointer',
              }}
            >
              <Typography fontWeight={700} color={basicTone0}>
                {'Community'}
              </Typography>
              <KeyboardArrowDownIcon />
              {showCommunity && (
                <Community onMouseOut={() => setShowCommunity(false)} />
              )}
            </div>
            <NavLink to='/blog' style={{ textDecoration: 'none' }}>
              <div
                key={'Blog'}
                style={{
                  padding: '0 18px',
                  position: 'relative',
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <Typography fontWeight={700} color={basicTone0}>
                  {'Blog'}
                </Typography>
              </div>
            </NavLink>
            <NavLink to='/contact' style={{ textDecoration: 'none' }}>
              <div
                key={'Contact'}
                style={{
                  padding: '0 18px',
                  position: 'relative',
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <Typography fontWeight={700} color={basicTone0}>
                  {'Contact'}
                </Typography>
              </div>
            </NavLink>
          </Box>
          <Box>
            <Grid container wrap='nowrap' gap={1} alignItems='center'>
              {/* theme switch */}
              <DarkLightModeSwitcher />

              {/* sign in button */}
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'flex',
                  },

                  gap: '4px',
                }}
              >
                <SignInButton />
              </Box>
              <Box sx={{ display: { xs: 'inline-block', lg: 'none' } }}>
                <IconButton
                  aria-label='mobile-menu'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                >
                  <SortIcon htmlColor='#FFF' />
                </IconButton>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Toolbar>
      <Box component='nav'>
        <Drawer
          anchor='right'
          variant='temporary'
          open={isMobileDrawerOpen}
          onClose={handleOpenNavMenu}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          color='primary'
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          <NavMenuDrawer
            handleOpenNavMenu={handleOpenNavMenu}
            expandCommunity={showCommunity}
            onCollapseCommunity={() => setShowCommunity(!showCommunity)}
          />
        </Drawer>
      </Box>
    </AppBar>
  )
}

export default AppHeaderBar
