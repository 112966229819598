import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCheckUserKycMutation } from "../../services/kycAPI";
import { Button, Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone50,
  basicTone600,
  basicTone700,
  mainColor2,
} from "../../shared/styles/constants";

export const KYCProcessFinishedPage = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const { isLightMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");

  const error = searchParams.get("error");
  const errorDescription = searchParams.get("error_description");

  const [checkUserKyc] = useCheckUserKycMutation();

  useEffect(() => {
    if (!(code || error)) {
      navigate("/");
    }
    if (error) {
      setLoadingPage(false);
    } else if (code && state) {
      const stateParam = localStorage.getItem("stateParam");
      const isSameState = stateParam === state;

      const token = localStorage.getItem("token");
      if (isSameState) {
        checkUserKyc({ code, token })
          .unwrap()
          .then(async (res: any) => {
            setLoadingPage(false);
            localStorage.removeItem("stateParam");
            localStorage.setItem("kycStatus", "pending");
          });
      } else {
        navigate("/404");
        setLoadingPage(false);
      }
    }
  }, [code, error, state]);

  return (
    <div className="Content">
      <Stack
        gap={7}
        paddingTop={3}
        paddingBottom={10}
        paddingRight={5}
        paddingLeft={5}
        alignItems={"center"}
      >
        {loadingPage ? (
          <Skeleton
            variant="rounded"
            height={"254px"}
            sx={{
              padding: { xs: "16px", md: "56px" },
              width: { xs: "100%", md: "62%" },
            }}
          />
        ) : (
          <Card
            sx={{
              backgroundColor: isLightMode ? basicTone0 : basicTone700,
              border: `1px solid ${isLightMode ? basicTone50 : basicTone600}`,
              padding: { xs: "16px", md: "56px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack alignItems={"center"} gap={3}>
              <Stack alignItems={"center"} gap={1}>
                <Typography
                  variant="h2"
                  fontSize={{ xs: "32px", md: "40px" }}
                  fontWeight={700}
                  color="secondary"
                  textAlign={"center"}
                >
                  {error
                    ? "Ooops! Looks like something went wrong! Please try again later!"
                    : `Thank your for submitting kyc docs!`}
                </Typography>
                <Typography
                  variant="body1"
                  color={mainColor2}
                  textAlign="center"
                >
                  {error || errorDescription
                    ? errorDescription
                    : `You're now pending approval for the kyc process. Soon you'll hear from us on your e-mail`}
                </Typography>
              </Stack>
              <Grid container justifyContent={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/")}
                >
                  Go to dashboard
                </Button>
              </Grid>
            </Stack>
          </Card>
        )}
      </Stack>
    </div>
  );
};

export default KYCProcessFinishedPage;
