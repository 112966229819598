export const CheckedColorIconSVG = () => (
  <svg
    width="106"
    height="106"
    viewBox="0 0 106 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M73.4353 75.9715C74.6538 77.451 74.4507 79.6547 72.8558 80.7177C68.5758 83.5703 63.6963 85.4336 58.5732 86.1512C52.301 87.0297 45.9079 86.1567 40.1009 83.6285C34.2939 81.1003 29.2991 77.0155 25.6688 71.8258C22.0385 66.636 19.914 60.5434 19.5303 54.2215C19.1466 47.8996 20.5186 41.5948 23.4946 36.0041C26.4705 30.4133 30.9346 25.7544 36.3932 22.5424C41.8518 19.3305 48.0924 17.6906 54.4248 17.8041C59.5971 17.8968 64.6661 19.1562 69.2597 21.4702C70.9715 22.3325 71.4396 24.4955 70.409 26.1116C69.3785 27.7276 67.2399 28.1798 65.5066 27.3616C62.0105 25.7112 58.1921 24.8137 54.3005 24.7439C49.2479 24.6534 44.2686 25.9618 39.9133 28.5246C35.5579 31.0874 31.9961 34.8047 29.6215 39.2655C27.247 43.7263 26.1523 48.7568 26.4585 53.801C26.7647 58.8451 28.4598 63.7063 31.3563 67.8472C34.2529 71.9881 38.2382 75.2473 42.8716 77.2645C47.5049 79.2817 52.6059 79.9783 57.6104 79.2773C61.4651 78.7374 65.1469 77.3846 68.4177 75.3235C70.0393 74.3017 72.2169 74.4919 73.4353 75.9715Z"
      fill="#0D0D0E"
    />
    <path
      d="M73.4353 75.9715C74.6538 77.451 74.4507 79.6547 72.8558 80.7177C68.5758 83.5703 63.6963 85.4336 58.5732 86.1512C52.301 87.0297 45.9079 86.1567 40.1009 83.6285C34.2939 81.1003 29.2991 77.0155 25.6688 71.8258C22.0385 66.636 19.914 60.5434 19.5303 54.2215C19.1466 47.8996 20.5186 41.5948 23.4946 36.0041C26.4705 30.4133 30.9346 25.7544 36.3932 22.5424C41.8518 19.3305 48.0924 17.6906 54.4248 17.8041C59.5971 17.8968 64.6661 19.1562 69.2597 21.4702C70.9715 22.3325 71.4396 24.4955 70.409 26.1116C69.3785 27.7276 67.2399 28.1798 65.5066 27.3616C62.0105 25.7112 58.1921 24.8137 54.3005 24.7439C49.2479 24.6534 44.2686 25.9618 39.9133 28.5246C35.5579 31.0874 31.9961 34.8047 29.6215 39.2655C27.247 43.7263 26.1523 48.7568 26.4585 53.801C26.7647 58.8451 28.4598 63.7063 31.3563 67.8472C34.2529 71.9881 38.2382 75.2473 42.8716 77.2645C47.5049 79.2817 52.6059 79.9783 57.6104 79.2773C61.4651 78.7374 65.1469 77.3846 68.4177 75.3235C70.0393 74.3017 72.2169 74.4919 73.4353 75.9715Z"
      fill="url(#paint0_linear_1642_5671)"
    />
    <path
      d="M42 47.6286L59.2407 65L91 33"
      stroke="#0D0D0E"
      strokeWidth="9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 47.6286L59.2407 65L91 33"
      stroke="url(#paint1_linear_1642_5671)"
      strokeWidth="9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1642_5671"
        x1="56.4365"
        y1="5.48792"
        x2="99.0775"
        y2="63.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1642_5671"
        x1="42"
        y1="33"
        x2="93.3209"
        y2="37.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
    </defs>
  </svg>
);
