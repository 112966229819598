import React, { useContext } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Avatar,
} from "@mui/material";
import {
  basicTone200,
  basicTone500,
  basicTone100,
  mainBorderRadius,
  basicTone50,
  basicTone600,
  dropShadowSM,
  basicTone450,
  whiteTone,
} from "../../shared/styles/constants";
import { TApplicationItem } from "./types";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { useNavigate } from "react-router-dom";

export const ApplicationsList = ({ apps }: { apps: TApplicationItem[] }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();

  return apps.length ? (
    <Stack justifyContent={"space-between"}>
      <List>
        <ListItem sx={{ display: { xs: "none", sm: "block" } }}>
          <Grid container>
            <Grid item sm={7}>
              <Typography variant="body2" color={basicTone200}>
                Name:
              </Typography>
            </Grid>
            <Grid item sm={3} paddingRight={"5px"}>
              <Typography variant="body2" color={basicTone200}>
                Round:
              </Typography>
            </Grid>

            <Grid item sm={2}>
              <Typography variant="body2" color={basicTone200}>
                Max allocation:
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Stack gap={1}>
          {apps.map((application) => {
            return application.FundingRounds.map((app) => (
              <ListItem
                component="a"
                href={`/project/${application.id}`}
                key={app.id}
                sx={{
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone500
                  }`,
                  cursor: "pointer",
                  filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,
                  borderRadius: mainBorderRadius,
                  padding: "12px",
                  background: isLightMode ? whiteTone : basicTone600,
                  "&:hover": {
                    border: `1px solid ${
                      isLightMode ? basicTone50 : basicTone450
                    }`,
                    background: isLightMode ? basicTone100 : basicTone500,
                  },
                }}
              >
                <Grid container alignItems={"center"}>
                  <Grid item sm={7} container alignItems={"center"} gap={3}>
                    <Avatar
                      src={application.logoUrl}
                      variant="rounded"
                      sx={{ width: "48px", height: "48px" }}
                    />
                    <Typography variant="h6" color="secondary">
                      {application.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} paddingRight={"5px"}>
                    <Typography variant="h6" color={basicTone200}>
                      {app.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={2}>
                    <Typography variant="h6" color="secondary">
                      {`$${app.maxAllocation} `}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ));
          })}
        </Stack>
      </List>
      <Stack alignItems={"center"} marginTop={4}>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          sx={{ padding: "10px 28px" }}
        >
          See new projects
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Grid
      container
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      gap={4}
      sx={{ padding: "40px 0" }}
    >
      <Stack alignItems={"center"}>
        <Typography
          variant="h2"
          color="secondary"
          textAlign={"center"}
        >{`Seems you don't have any applications yet`}</Typography>
        <Typography
          variant="h6"
          color={basicTone200}
        >{`It's the best time to apply`}</Typography>
      </Stack>
      <Button
        onClick={() => navigate("/")}
        variant="contained"
        sx={{ padding: "10px 28px" }}
      >
        See new projects
      </Button>
    </Grid>
  );
};

export default ApplicationsList;
