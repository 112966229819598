import { Grid, useMediaQuery, Theme } from "@mui/material";
import { useEthers } from "@usedapp/core";
import React from "react";
import { useNavigate } from "react-router";

import { WalletProvider } from "../../shared/providers/wallet-provider";
import ValidateEmailCard from "./ValidateEmailCard";
import WalletActionsCard from "./WalletActionsCard";
import WalletInfoCard from "./WalletInfoCard";

export const WalletPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const account = localStorage.getItem("accountAddress");

  if (!account) {
    navigate("/");
  }

  const showValidateEmailCard =
    localStorage.getItem("emailValidated") !== "yes";

  return (
    <WalletProvider>
      <div className="Content">
        <Grid
          container
          gap={1.5}
          sx={{ padding: { xs: "10px 0 60px", md: "60px 0" } }}
          flexWrap={{ md: "nowrap" }}
        >
          <Grid item xs={12} md={4}>
            {showValidateEmailCard && isMobile && <ValidateEmailCard />}
            <WalletInfoCard />
          </Grid>
          <Grid item xs={12} md={8} sx={{ height: "100%" }}>
            {showValidateEmailCard && !isMobile && <ValidateEmailCard />}
            <WalletActionsCard />
          </Grid>
        </Grid>
      </div>
    </WalletProvider>
  );
};
export default WalletPage;
