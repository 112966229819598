import React, { useEffect, useContext, useState } from "react";
import { useCall } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { Card, Stack, Typography } from "@mui/material";
import {
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  lightShadow,
  whiteTone,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { AccountContext } from "../../../shared/providers/account-provider";
import { StableCoinType, WalletInfoProps } from "../types";
import { useContracts } from "../../../hooks";
import { getDividedByChainId } from "../../FeaturedProjectPage/sections/utils";
import SupportedTokensList from "./SupportedTokensList";

export const BalanceSection = ({
  walletInfo,
  usdcInfoByChain,
}: {
  walletInfo: WalletInfoProps;
  usdcInfoByChain: StableCoinType;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, userMetamaskChainId } = useContext(AccountContext);

  const [balance, setBallance] = useState<string>("0");

  const { stableCoinContract } = useContracts({
    stableCoinAddress: usdcInfoByChain.address,
  });

  // const getBalanceOf = () => {
  //   const { value: balanceOf, error: balanceOfError } =
  //     useCall({
  //       contract: stableCoinContract,
  //       method: "balanceOf",
  //       args: [account],
  //     }) ?? {};

  //   if (balanceOfError) {
  //     return "0";
  //   }
  //   if (balanceOf && userMetamaskChainId) {
  //     const dividedBy = getDividedByChainId(userMetamaskChainId);
  //     const val = new BigNumber(balanceOf?.toString())
  //       .dividedBy(dividedBy)
  //       .toString();

  //     return val;
  //   }
  //   return "0";
  // };

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOfError) {
      setBallance("0");
    } else if (balanceOf && userMetamaskChainId) {
      const dividedBy = getDividedByChainId(userMetamaskChainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(dividedBy)
        .toString();

      setBallance(val);
    }
  }, [balanceOf, balanceOfError, userMetamaskChainId]);

  return (
    <Stack gap={1.5}>
      <Typography variant="h6" color="secondary" fontWeight={700}>
        My wallet
      </Typography>
      <Card
        sx={{
          padding: "30px 22px",
          display: "flex",
          alignItems: "flex-end",
          gap: 1,
          boxShadow: !isLightMode ? dropShadowSM : lightShadow,
          border: isLightMode
            ? `1px solid ${basicTone50}`
            : `1px solid ${basicTone500}`,
          backgroundColor: isLightMode ? whiteTone : basicTone600,
        }}
      >
        <Typography
          variant="h2"
          fontSize={"36px"}
          color="secondary"
          fontWeight={700}
          lineHeight="0.9"
        >
          {/* {getBalanceOf()} */}
          {balance}
        </Typography>
        <Typography variant="body1" color="secondary">
          {usdcInfoByChain?.symbol}
        </Typography>
        {/* <img
          src={getChainLogoById(usdcInfoByChain?.chainId)}
          alt="logourl"
          style={{
            height: "24px",
            width: "24px",
            borderRadius: "50%",
          }}
        />
        <Typography variant="body1" color="secondary">
          {getChainNameById(usdcInfoByChain?.chainId)}
        </Typography> */}
      </Card>
      <SupportedTokensList supportedTokens={walletInfo.supportedTokens} />
    </Stack>
  );
};
