import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type CancelOrderConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  orderType: "ASK" | "BID";
  handleCancelOrder: () => void;
  loadingCancel: boolean;
};
export const CancelOrderConfirmationDialog = ({
  handleClose,
  open,
  orderType,
  handleCancelOrder,
  loadingCancel,
}: CancelOrderConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ backgroundColor: `rgba(13, 13, 14, .2)` }}
      PaperProps={{ sx: { p: 3 } }}
    >
      <>
        <DialogTitle id="alert-dialog-title" typography={"h5"}>
          {`Are you sure you want to cancel this ${orderType.toLowerCase()} order?`}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            No
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleCancelOrder}
            loading={loadingCancel}
          >
            <span>Yes</span>
          </LoadingButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default CancelOrderConfirmationDialog;
