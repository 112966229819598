import { useEffect, useContext, useState } from "react";
import { Grid, Stack, Typography, ListItem, Button } from "@mui/material";
import { MARKETPLACE_MATIC_ADDRESS } from "../../config/MarketPlaceAbi";
import { useContracts } from "../../hooks";
import { useCall, useContractFunction } from "@usedapp/core";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../shared/providers/account-provider";
import { TOrder } from "./types";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainBorderRadius,
  whiteTone,
} from "../../shared/styles/constants";
import { mapOrdersListResponse } from "./ordersModals/ordersModals.utils";
import { NotificationsContext } from "../../shared/providers/notifications-provider";
import CancelOrderConfirmationDialog from "./ordersModals/CancelOrderConfirmationDialog";

export const MyOrdersList = () => {
  const { tokenAddress } = useParams();
  const { account } = useContext(AccountContext);
  const { setNotification } = useContext(NotificationsContext);
  const { isLightMode } = useContext(ThemeModeContext);
  const [activeOrders, setActiveOrders] = useState<TOrder[]>([]);
  const [openCancelConfirmationDialog, setOpenCancelDialog] = useState(false);
  const [loadingCancelOrder, setLoadingCancelOrder] = useState(false);
  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: MARKETPLACE_MATIC_ADDRESS,
  });

  const { value: activeOrdersResult, error: activeOrdersError } =
    useCall({
      contract: marketplaceContract,
      method: "getActiveOrders",
      args: [account, tokenAddress],
    }) ?? {};

  useEffect(() => {
    if (activeOrdersResult) {
      setActiveOrders(mapOrdersListResponse(activeOrdersResult[0]));
    }
  }, [activeOrdersResult, activeOrdersError]);

  const { state: cancelAskOrderState, send: cancelAskOrder } =
    useContractFunction(marketplaceContract, "cancelAskOrder", {
      transactionName: "Cancel Ask Order",
    });
  const { state: cancelBidOrderState, send: cancelBidOrder } =
    useContractFunction(marketplaceContract, "cancelBidOrder", {
      transactionName: "Cancel Bid Order",
    });

  useEffect(() => {
    if (cancelAskOrderState?.status === "Success") {
      setNotification({
        message: "You successfully canceled an ask order!",
        type: "success",
      });
      setOpenCancelDialog(false);
      setLoadingCancelOrder(false);
    }
  }, [cancelAskOrderState]);

  useEffect(() => {
    if (cancelBidOrderState?.status === "Success") {
      setNotification({
        message: "You successfully canceled a bid order!",
        type: "success",
      });
      setOpenCancelDialog(false);
      setLoadingCancelOrder(false);
    }
  }, [cancelBidOrderState]);

  const handleCancelOrder = (
    orderType: string,
    id: string,
    fundingTermsAddress: string
  ) => {
    setLoadingCancelOrder(true);
    if (orderType === "BID") {
      cancelBidOrder(fundingTermsAddress, id);
    } else {
      cancelAskOrder(fundingTermsAddress, id);
    }
  };

  return (
    <Stack gap={2}>
      <Typography variant="h3" color="secondary">
        My orders
      </Typography>

      <Stack gap={0.5}>
        <ListItem sx={{ padding: "8px 12px 0" }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={2} gap={1} container alignItems={"center"}>
              <Typography variant="h6">{`Type`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Quantity`}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">{`Price per token`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Full price`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Sold`}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {activeOrders.map((item) => (
          <ListItem
            key={item.id.toString()}
            sx={{
              border: `1px solid ${isLightMode ? basicTone100 : basicTone500}`,
              filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,
              borderRadius: mainBorderRadius,
              padding: "2px 12px",
              background: isLightMode ? basicTone0 : basicTone600,
              "&:hover": {
                background: isLightMode ? basicTone50 : basicTone500,
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={2} gap={1} container alignItems={"center"}>
                <Typography variant="body1" fontWeight={700} color="secondary">
                  {`${item.orderType}`}
                </Typography>
              </Grid>
              <Grid item xs={2} gap={1} container alignItems={"center"}>
                <Typography variant="body1" fontWeight={700} color="secondary">
                  {`${item.quantity}`}
                </Typography>
              </Grid>
              <Grid item xs={3} justifyContent={"center"}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {`$${item.pricePerToken}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {`$${item.fullPrice}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {`${item.sold ? "YES" : "NO"}`}
                </Typography>
              </Grid>
              <Grid item xs={1} justifyContent="flex-end" display={"flex"}>
                <Button
                  variant="outlined"
                  disabled={item.sold}
                  onClick={() => setOpenCancelDialog(true)}
                >
                  Cancel
                </Button>
                <CancelOrderConfirmationDialog
                  open={openCancelConfirmationDialog}
                  handleClose={() => setOpenCancelDialog(false)}
                  orderType={item.orderType}
                  loadingCancel={loadingCancelOrder}
                  handleCancelOrder={() =>
                    handleCancelOrder(
                      item.orderType,
                      item.id,
                      item.fundingTermsAddress
                    )
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};

export default MyOrdersList;
