import React, { useContext } from "react";
import "./App.css";
import { createTheme } from "@mui/material/styles";

import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { ThemeModeContext } from "./shared/providers/theme-mode-provider";
import { darkTheme, lightTheme } from "./theme";
import { AccountProvider } from "./shared/providers/account-provider";
import { NotificationsProvider } from "./shared/providers/notifications-provider";

function AppWrapper() {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <ThemeProvider theme={isLightMode ? lightTheme : darkTheme}>
      <NotificationsProvider>
        <Router>
          <AccountProvider>
            <App />
          </AccountProvider>
        </Router>
      </NotificationsProvider>
    </ThemeProvider>
  );
}

export default AppWrapper;
