export const NotificationColorIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4444 11.9252C17.0556 12.0305 16.6667 12.0952 16.2778 12.0952C15.1439 12.0931 14.057 11.6233 13.2552 10.7888C12.4534 9.95429 12.0021 8.82305 12 7.64286C12 6.45286 12.4511 5.37619 13.1667 4.57477C13.0255 4.39468 12.8475 4.24964 12.6457 4.15021C12.4439 4.05078 12.2233 3.99946 12 4C11.1444 4 10.4444 4.72858 10.4444 5.61905V5.85381C8.13444 6.56619 6.55556 8.77619 6.55556 11.2857V16.1429L5 17.7619V18.5714H19V17.7619L17.4444 16.1429V11.9252ZM12 21C12.8633 21 13.5556 20.2795 13.5556 19.381H10.4444C10.4444 19.8104 10.6083 20.2222 10.9001 20.5258C11.1918 20.8294 11.5874 21 12 21Z"
      fill="white"
    />
    <circle cx="16.5" cy="7.5" r="2.5" fill="#FF377F" />
  </svg>
);
