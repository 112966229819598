import { Card, Grid, Typography } from "@mui/material";
import { useCall } from "@usedapp/core";
import BigNumber from "bignumber.js";

import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useState, useContext, useEffect } from "react";
import { useContracts } from "../../hooks";
import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  whiteTone,
} from "../../shared/styles/constants";

import IconButton from "@mui/material/IconButton";

import { useNavigate } from "react-router-dom";
import { StableCoinType } from "./types";
import { getDividedByChainId } from "../FeaturedProjectPage/sections/utils";

export const BalanceSection = ({
  usdcInfoByChain,
}: {
  usdcInfoByChain: StableCoinType;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, userMetamaskChainId } = useContext(AccountContext);

  const navigate = useNavigate();

  const [balance, setBallance] = useState<string>("0");

  const { stableCoinContract } = useContracts({
    stableCoinAddress: usdcInfoByChain.address,
  });

  const { value: balanceOf } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOf && userMetamaskChainId) {
      const dividedBy = getDividedByChainId(userMetamaskChainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(dividedBy)
        .toString();
      setBallance(val);
    }
  }, [balanceOf, userMetamaskChainId]);

  return (
    <Card
      sx={{
        padding: "30px 22px",
        display: "flex",
        alignItems: "center",
        gap: 1,
        boxShadow: !isLightMode ? dropShadowSM : "none",
        border: isLightMode
          ? `1px solid ${basicTone50}`
          : `1px solid ${basicTone500}`,
        backgroundColor: isLightMode ? whiteTone : basicTone600,
      }}
    >
      <Grid container sx={{ alignItems: "flex-end" }} gap={1}>
        <Typography
          variant="h2"
          fontSize={"36px"}
          color="secondary"
          fontWeight={700}
          lineHeight="0.9"
        >
          {balance}
        </Typography>
        <Typography variant="body1" color="secondary">
          {usdcInfoByChain?.symbol}
        </Typography>
      </Grid>
      <IconButton onClick={() => navigate("/my-wallet")}>
        <ArrowCircleRightOutlinedIcon color="info" fontSize="large" />
      </IconButton>
    </Card>
  );
};

export default BalanceSection;
