import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardProvider } from "../../shared/providers/dashboard-provider";
import RightSideContent from "./RightSideContent";

import UserInfoCard from "./UserInfoCard";

export const DashboardPage = () => {
  const navigate = useNavigate();

  const account = localStorage.getItem("accountAddress");

  useEffect(() => {
    if (!account) {
      navigate("/");
    }
  }, []);

  return (
    <DashboardProvider>
      <div className="Content">
        <Grid
          container
          gap={1.5}
          sx={{ padding: { xs: "10px 8px 60px", md: "60px 0" } }}
          flexWrap={{ md: "nowrap" }}
          justifyContent={"center"}
        >
          <Grid item xs={12} md={4}>
            <UserInfoCard />
          </Grid>
          <Grid item xs={12} md={7.5} sx={{ height: "100%" }}>
            <RightSideContent />
          </Grid>
        </Grid>
      </div>
    </DashboardProvider>
  );
};
export default DashboardPage;
