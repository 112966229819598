import {
  Card,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { TRoundPhase } from "./utils";
import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone450,
  basicTone500,
  basicTone700,
  mainVerticalLinearGradient,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
interface ProjectProgressProps {
  phases: TRoundPhase[];
}

const StepIconComponent = (props: StepIconProps) => {
  const { active, completed } = props;
  const { isLightMode } = useContext(ThemeModeContext);

  const borderBackgroundDark = active
    ? `${basicTone700}, ${basicTone700}`
    : completed
    ? `${basicTone0}, ${basicTone0}`
    : `${basicTone450}, ${basicTone450}`;

  const borderBackgroundLight = active
    ? `#FAFAFA, #FAFAFA`
    : completed
    ? `${basicTone500}, ${basicTone500}`
    : `${basicTone100}, ${basicTone100}`;

  const borderBackground = isLightMode
    ? borderBackgroundLight
    : borderBackgroundDark;

  const iconStyle =
    active || completed
      ? {
          background: `linear-gradient(${borderBackground}) padding-box,
                    ${mainVerticalLinearGradient} border-box`,
          border: `3px solid transparent`,
        }
      : {
          backgroundColor: isLightMode ? basicTone100 : basicTone450,
        };

  return (
    <div
      style={{
        ...iconStyle,
        width: active ? "20px" : "12px",
        height: active ? "20px" : "12px",
        borderRadius: "50%",
        marginLeft: active ? 0 : "4px",
      }}
    />
  );
};
export const ProjectProgress = ({ phases }: ProjectProgressProps) => {
  return (
    <Card
      sx={{
        padding: "38px 35px",
        display: "flex",
      }}
    >
      <Stepper orientation="vertical" connector={<StepConnector />}>
        {phases.map((step, index) => (
          <Step key={step.id} active={step.active} completed={step.completed}>
            <StepLabel
              sx={{ padding: 0 }}
              optional={
                <Typography variant="body2" fontWeight={700}>
                  {step.completed ? "100%" : "0%"}
                </Typography>
              }
              StepIconComponent={StepIconComponent}
            >
              Step {index}: {step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Card>
  );
};

export default ProjectProgress;
