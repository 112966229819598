import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { useContext, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";

import { DiscordSVG } from "../../../assets/DiscordSVG";

import {
  basicTone0,
  basicTone400,
  basicTone50,
  basicTone700,
  basicTone800,
  mainLinearGradient,
} from "../../../shared/styles/constants";
import Logo from "../../../logo";
import { FireIconSVG } from "../../../assets/icons/FireIconSVG";
import { communityUrls } from "../../../components/AppHeaderBar/header-bar-utils.utils";

type IncreaseChancesOnSignUpModalProps = {
  open: boolean;
  handleClose: () => void;
  projectTitle: string;
  handleSignUp: (
    socialMediaCounter: SocialMediaCounter,
    telegramUser: string,
    handleDiscord: string,
    handleTwitter: string
  ) => void;
  socialMedia: {
    twitterUrl: string | undefined;
    discordUrl: string | undefined;
    telegramUrl: string | undefined;
  };
};

export type SocialMediaCounter = {
  projectTwitter: number;
  collectivePadTwitter: number;
  projectDiscord: number;
  collectivePadDiscord: number;
  projectTelegram: number;
  collectivePadTelegram: number;
};

type SocialMediaListItemProps = {
  socialMediaName: "Twitter" | "Telegram" | "Discord";
  projectName: string;
  socialMediaUrl: string | undefined;
  handleJoin: () => void;
};

const SocialMediaListItem = ({
  socialMediaName,
  projectName,
  socialMediaUrl,
  handleJoin,
}: SocialMediaListItemProps) => {
  const [alreadyJoinedDisabled, setAlreadyJoinedDisabled] = useState(false);
  const { isLightMode } = useContext(ThemeModeContext);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const getIconByName = (name: "Twitter" | "Telegram" | "Discord") => {
    switch (name) {
      case "Twitter": {
        return <TwitterIcon color="secondary" />;
      }
      case "Telegram": {
        return <TelegramIcon color="secondary" />;
      }
      case "Discord": {
        return <DiscordSVG color={isLightMode ? basicTone700 : basicTone0} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Paper
      sx={{
        padding: "16px 22px",
        borderRadius: "4px",
        display: "flex",
        alignItems: isMobile ? "flex-start" : "center",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
        gap: isTablet ? "8px" : "60px",
      }}
    >
      <Stack direction={"row"} justifyContent={"center"} gap="10px">
        {getIconByName(socialMediaName)}
        <Typography variant="h6" fontWeight={700} color="secondary">
          {`${projectName} - ${socialMediaName}`}
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"center"} gap="10px">
        <Link
          color="secondary"
          href={socialMediaUrl}
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          variant="body1"
        >
          <Button
            variant="contained"
            onClick={() => {
              setAlreadyJoinedDisabled(true);
              handleJoin();
            }}
          >
            Join
          </Button>
        </Link>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setAlreadyJoinedDisabled(true);
            handleJoin();
          }}
          disabled={alreadyJoinedDisabled}
          sx={{ textWrap: "nowrap" }}
        >
          I already joined
        </Button>
      </Stack>
    </Paper>
  );
};

export const IncreaseChancesOnSignUpModal = ({
  open,
  handleClose,
  socialMedia,
  projectTitle,
  handleSignUp,
}: IncreaseChancesOnSignUpModalProps) => {
  const [handleTwitter, setHandleTwitter] = useState("");
  const [handleDiscord, setHandleDiscord] = useState("");
  const [telegramUser, setTelegramUser] = useState("");
  const [socialMediaCounter, setSocialMediaCounter] = useState({
    projectTwitter: 0,
    collectivePadTwitter: 0,
    projectDiscord: 0,
    collectivePadDiscord: 0,
    projectTelegram: 0,
    collectivePadTelegram: 0,
  });
  const { isLightMode } = useContext(ThemeModeContext);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={handleClose}
      sx={{
        overflow: "auto",
      }}
      PaperProps={{
        sx: {
          backgroundColor: isLightMode ? basicTone50 : basicTone800,
          padding: `${isMobile ? "12px" : "40px"} 0 60px`,
        },
      }}
    >
      <Grid
        justifyContent={"center"}
        display="flex"
        flexDirection={"column"}
        padding={2}
        sx={{
          margin: "0 auto",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Logo width={isMobile ? 140 : undefined} />
          <IconButton
            color="secondary"
            onClick={handleClose}
            aria-label="close"
            sx={{
              height: "40px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <CancelRoundedIcon color={isLightMode ? "disabled" : "info"} />
          </IconButton>
        </Grid>
        <Stack gap={6}>
          {/* title */}
          <Stack gap={2} alignItems={"center"}>
            <Paper
              sx={{
                background: mainLinearGradient,
                width: "58px",
                height: "58px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "12px",
              }}
            >
              <FireIconSVG />
            </Paper>
            <Typography
              variant="h2"
              letterSpacing={"1px"}
              color="secondary"
              fontStyle={"normal"}
              lineHeight={"38px"}
            >
              Increase your chances to win!
            </Typography>
          </Stack>
          {/* social media list */}
          <Stack gap={2}>
            <SocialMediaListItem
              projectName="CollectivePad"
              socialMediaName="Twitter"
              socialMediaUrl={communityUrls.twitterURL}
              handleJoin={() =>
                setSocialMediaCounter((prevState) => ({
                  ...prevState,
                  collectivePadTwitter: 1,
                }))
              }
            />
            {socialMedia.twitterUrl ? (
              <SocialMediaListItem
                projectName={projectTitle}
                socialMediaName="Twitter"
                socialMediaUrl={socialMedia.twitterUrl}
                handleJoin={() =>
                  setSocialMediaCounter((prevState) => ({
                    ...prevState,
                    projectTwitter: 1,
                  }))
                }
              />
            ) : null}
            <SocialMediaListItem
              projectName="CollectivePad"
              socialMediaName="Discord"
              socialMediaUrl={communityUrls.discordURL}
              handleJoin={() =>
                setSocialMediaCounter((prevState) => ({
                  ...prevState,
                  collectivePadDiscord: 1,
                }))
              }
            />
            {socialMedia.discordUrl ? (
              <SocialMediaListItem
                projectName={projectTitle}
                socialMediaName="Discord"
                socialMediaUrl={socialMedia.discordUrl}
                handleJoin={() =>
                  setSocialMediaCounter((prevState) => ({
                    ...prevState,
                    projectDiscord: 1,
                  }))
                }
              />
            ) : null}
            <SocialMediaListItem
              projectName="CollectivePad"
              socialMediaName="Telegram"
              socialMediaUrl={communityUrls.telegramURL}
              handleJoin={() =>
                setSocialMediaCounter((prevState) => ({
                  ...prevState,
                  collectivePadTelegram: 1,
                }))
              }
            />
            {socialMedia.telegramUrl ? (
              <SocialMediaListItem
                projectName={projectTitle}
                socialMediaName="Telegram"
                socialMediaUrl={socialMedia.telegramUrl}
                handleJoin={() =>
                  setSocialMediaCounter((prevState) => ({
                    ...prevState,
                    projectTelegram: 1,
                  }))
                }
              />
            ) : null}
          </Stack>
          {/* optional inputs for user handlers */}
          <Stack gap={2}>
            <Typography variant="h6" textAlign={"center"}>
              Optional, you can tell us your social media handlers.
            </Typography>
            <Paper
              sx={{
                gap: "16px",
                display: "flex",
                padding: "16px 22px",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <OutlinedInput
                color="primary"
                name="handleTwitter"
                onChange={(e: React.ChangeEvent<any>) => {
                  setHandleTwitter(e.target.value);
                }}
                placeholder="Handle Twitter"
                startAdornment={
                  <InputAdornment position="start">
                    <TwitterIcon fontSize="small" color={"info"} />
                  </InputAdornment>
                }
              />
              <OutlinedInput
                color="primary"
                name="handleDiscord"
                onChange={(e: React.ChangeEvent<any>) => {
                  setHandleDiscord(e.target.value);
                }}
                placeholder="Handle Discord"
                startAdornment={
                  <InputAdornment position="start">
                    <DiscordSVG color={basicTone400} />;
                  </InputAdornment>
                }
              />
              <OutlinedInput
                color="primary"
                name="telegramUser"
                onChange={(e: React.ChangeEvent<any>) => {
                  setTelegramUser(e.target.value);
                }}
                placeholder="Telegram user"
                startAdornment={
                  <InputAdornment position="start">
                    <TelegramIcon fontSize="small" color={"info"} />
                  </InputAdornment>
                }
              />
            </Paper>
          </Stack>
          {/* sign up button */}
          <Stack alignItems={"center"}>
            <Button
              variant="contained"
              onClick={() =>
                handleSignUp(
                  socialMediaCounter,
                  telegramUser,
                  handleDiscord,
                  handleTwitter
                )
              }
            >
              Sign up
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Dialog>
  );
};

export default IncreaseChancesOnSignUpModal;
