import React, { createContext, FC, useState } from "react";

export interface IThemeModeContext {
  isLightMode: boolean;
  changeTheme: (isDarkMode: boolean) => void;
}

export const ThemeModeContext = createContext<IThemeModeContext>({
  isLightMode: true,
  changeTheme: (isDarkMode: boolean) => {},
});

export const ThemeModeProvider: FC<{ children?: React.ReactNode }> = (
  props
) => {
  const theme = localStorage.getItem("theme") || "dark";
  const [isLightMode, setIsLightMode] = useState(theme !== "dark");

  const changeTheme = (changedToLight: boolean) => {
    localStorage.setItem("theme", changedToLight ? "light" : "dark");
    setIsLightMode(changedToLight);
  };

  return (
    <ThemeModeContext.Provider value={{ isLightMode, changeTheme }}>
      {props.children}
    </ThemeModeContext.Provider>
  );
};
