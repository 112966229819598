import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";
import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone400,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone600,
  basicTone700,
  basicTone800,
  darkLinearGradient,
  dropShadowBG,
  dropShadowSM,
  linearBlackBG,
  mainBorderRadius,
  mainColor1,
  mainColor2,
  mainLinearGradient,
  mainVerticalLinearGradient,
  secondaryBorderRadius,
  secondaryColor1,
  successColor,
} from "./shared/styles/constants";

// LIGHT AND DARK THEMES
export let lightTheme = createTheme();
export let darkTheme = createTheme();

export const fontFamily = `'Nunito', sans-serif`;

lightTheme = createTheme(lightTheme, {
  palette: {
    primary: {
      main: basicTone0,
    },
    secondary: {
      main: basicTone700,
    },
    success: { main: mainColor2 },
    info: {
      main: basicTone400,
    },
    error: { main: mainColor1 },
  },
  typography: {
    allVariants: {
      fontFamily: fontFamily,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
        h1: {
          fontSize: "48px",
          fontWeight: 700,
          [lightTheme.breakpoints.down("sm")]: {
            fontSize: "46px",
          },
        },
        h2: {
          fontSize: "32px",
          fontWeight: 700,
          [lightTheme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
        },
        h3: { fontSize: "28px", fontWeight: 700 },
        h4: { fontSize: "24px ", fontWeight: 700 },
        h5: { fontSize: "18px " },
        h6: { fontSize: "16px", fontWeight: 600, color: mainColor2 },
        body1: { fontSize: "14px", color: basicTone200 },
        body2: { fontSize: "12px" },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: mainColor2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "12px",
          textTransform: "none",
          fontWeight: 800,
          borderRadius: "8px",
        },
        outlinedPrimary: {
          color: basicTone400,
          backgroundColor: "transparent",
          border: `1px solid ${basicTone100}`,
          padding: "5px 24px",
          fontSize: "12px",
          lineHeight: "26px",
          fontWeight: 700,
          letterSpacing: "-2%",
          transition: "none",
          "&:hover": {
            backgroundColor: basicTone50,
          },
          "& .MuiButton-startIcon": {
            color: mainColor2,
            // color: "red",
            // background: mainLinearGradient,
          },
        },

        outlinedSecondary: {
          color: basicTone400,
          backgroundColor: "transparent",
          border: `1px solid ${basicTone100}`,
          padding: "10px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "19px",
          "&:hover": {
            backgroundColor: basicTone50,
            border: `1px solid ${basicTone100}`,
          },
        },
        containedPrimary: {
          color: basicTone0,
          backgroundColor: mainColor2,
          background: mainLinearGradient,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "19px",
          padding: "9px 24px",
          transition: "none",
          border: "1px solid transparent",
          boxShadow: "none",
          "&.Mui-disabled": {
            background: basicTone100,
            color: basicTone400,
          },
          "&:hover": {
            background: basicTone500,
          },
          "& .MuiButton-startIcon": {
            color: basicTone0,
          },
        },
        containedSecondary: {
          color: basicTone400,
          backgroundColor: "transparent",
          border: `1px solid ${basicTone100}`,
          padding: "9px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "100%",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: basicTone50,
            boxShadow: "none",
          },
        },
        textPrimary: {
          color: basicTone400,
          fontWeight: 800,
          "&:hover": {
            backgroundColor: basicTone100,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          height: "40px",
          "& .MuiSwitch-track": {
            borderRadius: "20px",
            opacity: 1,
            border: `2px solid ${basicTone400}`,
            "&:before, &:after": {
              content: '""',
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: 12,
              height: 12,
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
            backgroundColor: basicTone100,
            width: 12,
            height: 12,
            margin: 5,
          },
          "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: basicTone700,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            backgroundColor: basicTone0,
            color: basicTone700,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: basicTone0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
          borderColor: basicTone100,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: basicTone800,
        },
        paper: { backgroundColor: basicTone800 },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: "8px",
          fontSize: "14px",
        },
        input: {
          fontFamily,
          "&:-webkit-autofill": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
          "&[data-autocompleted]": {
            backgroundColor: `transparent !important`,
          },
          "&:-webkit-autofill:focus": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
        },
        colorPrimary: {
          color: basicTone700,
          border: `1px solid ${basicTone100}`,
        },
        colorSecondary: {
          // backgroundColor: "#121318",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: "10px",
          padding: "10px",
          color: basicTone700,
          "&:before": {
            border: "none",
          },
          "&:after": {
            border: "none",
          },
          "&:hover": {
            "&:not(.Mui-disabled):before": {
              border: "none",
            },
          },
        },
        // colorPrimary: {
        //   backgroundColor: basicTone0,
        // },
        colorSecondary: {
          background: basicTone100,
        },
        input: {
          fontFamily,
          "&:-webkit-autofill": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
          "&[data-autocompleted]": {
            backgroundColor: `transparent !important`,
          },
          "&:-webkit-autofill:focus": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // border: `1px solid ${basicTone50}`,
          border: `1px solid ${basicTone0}`,
          background: basicTone0,
          borderRadius: secondaryBorderRadius,
          boxShadow: "0px 8px 20px rgba(77, 91, 142, 0.1)",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: `1px solid ${basicTone100}`,
          fontWeight: 700,
          minHeight: "40px",
          textTransform: "none",
          padding: "8px 30px",
          margin: "0 16px 8px 0",
        },
        textColorPrimary: {
          borderRadius: mainBorderRadius,
          color: basicTone700,
          padding: "7px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "100%",
          width: "150px",
          "&:hover": {
            backgroundColor: basicTone50,
          },
          "&.Mui-selected": {
            background: mainLinearGradient,
            color: basicTone0,
            border: "none",
          },
        },
        textColorSecondary: {
          borderRadius: "200px",
          color: basicTone700,
          "&.Mui-selected": {
            background: basicTone700,
            color: basicTone0,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          fontWeight: 700,
          color: basicTone100,
          marginBottom: "4px",
          paddingLeft: "4px",
          "&+p": { paddingLeft: "5px" },
          "&.Mui-active": {
            color: basicTone700,
            fontWeight: 700,
            paddingLeft: "0",
            "&+p": { paddingLeft: "2px" },
          },
          "&.Mui-completed": {
            color: basicTone100,
            fontSize: "14px",
            fontWeight: 700,
          },
        },
        labelContainer: {
          color: basicTone100,
          fontWeight: 700,
          paddingLeft: "24px",
        },
        iconContainer: { paddingRight: 0, justifyContent: "center" },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          marginLeft: "8px",
          marginTop: "-15px",
          marginBottom: "-15px",
          "&.Mui-active .MuiStepConnector-line": {
            borderStyle: "solid",
            borderImage: mainVerticalLinearGradient,
            borderImageSlice: 1,
            borderImageWidth: "0 0 0 4px",
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderStyle: "solid",
            borderImage: mainVerticalLinearGradient,
            borderImageSlice: 1,
            borderImageWidth: "0 0 0 4px",
          },
        },
        lineVertical: {
          minHeight: "40px",
          borderLeft: `4px solid ${basicTone100}`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          top: "-1px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: "row-reverse",
          padding: 0,
          gap: "12px",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "8px 0 16px",
          display: "flex",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          bottom: "0px !important",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "12px 12px 0px 0px",
          color: basicTone0,
          "& .MuiAlert-icon": {
            color: basicTone0,
          },
        },
        standardSuccess: {
          backgroundColor: successColor,
        },
        standardError: {
          backgroundColor: mainColor1,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: basicTone50,
          borderRadius: secondaryBorderRadius,
        },
      },
    },
  },
});

darkTheme = createTheme(darkTheme, {
  palette: {
    primary: {
      main: basicTone700,
    },
    secondary: {
      main: basicTone0,
    },
    success: { main: mainColor2 },
    info: {
      main: basicTone400,
    },
    error: { main: mainColor1 },
  },
  typography: {
    typography: {
      allVariants: {
        fontFamily: fontFamily,
      },
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
        h1: {
          fontSize: "48px",
          fontWeight: 700,
          [darkTheme.breakpoints.down("sm")]: {
            fontSize: "46px",
          },
        },
        h2: {
          fontSize: "32px",
          fontWeight: 700,
          [darkTheme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
        },
        h3: { fontSize: "28px", fontWeight: 700 },
        h4: { fontSize: "24px ", fontWeight: 700 },
        h5: { fontSize: "18px " },
        h6: { fontSize: "16px", fontWeight: 600, color: mainColor2 },
        body1: { fontSize: "14px", color: basicTone200 },
        body2: { fontSize: "12px" },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          top: "-1px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: mainColor2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "12px",
          textTransform: "none",
          fontWeight: 800,
          borderRadius: "8px",
        },
        outlinedPrimary: {
          color: basicTone0,
          backgroundColor: "transparent",
          border: `1px solid ${secondaryColor1}`,
          padding: "5px 24px",
          fontSize: "12px",
          lineHeight: "26px",
          fontWeight: 700,
          letterSpacing: "-2%",
          transition: "none",

          "&:hover": {
            backgroundColor: mainColor2,
            background: mainLinearGradient,
            "& .MuiButton-startIcon": {
              color: basicTone0,
            },
          },
        },
        outlinedSecondary: {
          color: basicTone0,
          backgroundColor: basicTone800,
          border: `1px solid ${basicTone500}`,
          padding: "10px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "19px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
          "&:hover": {
            backgroundColor: basicTone500,
          },
        },
        containedPrimary: {
          color: basicTone0,
          backgroundColor: mainColor2,
          background: mainLinearGradient,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "19px",
          padding: "9px 24px",
          transition: "none",
          border: "1px solid transparent",
          boxShadow: "none",
          "&.Mui-disabled": {
            background: basicTone500,
            color: basicTone400,
          },
          "&:hover": {
            background: basicTone500,
          },
          "& .MuiButton-startIcon": {
            color: basicTone0,
          },
        },
        containedSecondary: {
          color: basicTone0,
          backgroundColor: basicTone600,
          border: `1px solid ${basicTone500}`,
          padding: "9px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "100%",
          boxShadow: dropShadowSM,
          "&:hover": {
            backgroundColor: basicTone500,
          },
          "&.Mui-disabled": {
            color: basicTone400,
          },
        },
        textPrimary: {
          color: basicTone0,
          fontWeight: 800,
          "&:hover": {
            backgroundColor: basicTone500,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          height: "40px",
          "& .MuiSwitch-track": {
            borderRadius: "20px",
            opacity: 1,
            border: `2px solid ${basicTone400}`,
            "&:before, &:after": {
              content: '""',
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: 12,
              height: 12,
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
            backgroundColor: basicTone100,
            width: 12,
            height: 12,
            margin: 5,
          },
          "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: basicTone700,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            backgroundColor: basicTone700,
            color: basicTone0,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: basicTone600,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
          borderColor: basicTone500,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: basicTone800,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: "8px",
          fontSize: "14px",
        },
        input: {
          fontFamily,
          "&:-webkit-autofill": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
          "&[data-autocompleted]": {
            backgroundColor: `transparent !important`,
          },
          "&:-webkit-autofill:focus": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
          "&.Mui-disabled": {
            textFillColor: basicTone400,
          },
        },
        colorPrimary: {
          backgroundColor: basicTone800,
          color: basicTone0,
          border: `1px solid ${basicTone500}`,
        },
        colorSecondary: {
          border: `1px solid ${basicTone500}`,
          color: basicTone0,
        },
      },
    },
    MuiFormLabel: { styleOverrides: { root: { color: basicTone0 } } },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: "10px",
          padding: "10px",
          color: basicTone0,
          "&:before": {
            border: "none",
          },
          "&:after": {
            border: "none",
          },
          "&:hover": {
            "&:not(.Mui-disabled):before": {
              border: "none",
            },
          },
        },
        // colorPrimary: {
        //   backgroundColor: basicTone0,
        // },
        colorSecondary: {
          background: darkLinearGradient,
        },
        input: {
          fontFamily,
          "&:-webkit-autofill": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
          "&[data-autocompleted]": {
            backgroundColor: `transparent !important`,
          },
          "&:-webkit-autofill:focus": {
            transition: `background-color 600000s 0s, color 600000s 0s`,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          minHeight: "40px",
          textTransform: "none",
          padding: "8px 30px",
          margin: "0 16px 8px 0",
        },
        textColorPrimary: {
          borderRadius: mainBorderRadius,
          border: `1px solid ${basicTone500}`,
          color: basicTone0,
          backgroundColor: basicTone600,
          padding: "7px 24px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "100%",
          width: "150px",
          "&:hover": {
            backgroundColor: basicTone500,
          },
          "&.Mui-selected": {
            background: mainLinearGradient,
            color: basicTone0,
            border: "none",
          },
        },
        textColorSecondary: {
          border: `1px solid ${basicTone450}`,
          borderRadius: "200px",
          color: basicTone0,
          "&.Mui-selected": {
            background: basicTone0,
            color: basicTone800,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${basicTone500}`,
          background: linearBlackBG,
          borderRadius: secondaryBorderRadius,
          boxShadow: dropShadowBG,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          fontWeight: 700,
          color: basicTone400,
          marginBottom: "4px",
          paddingLeft: "4px",
          "&+p": { paddingLeft: "5px" },
          "&.Mui-active": {
            color: basicTone0,
            fontWeight: 700,
            paddingLeft: "0",
            "&+p": { paddingLeft: "2px" },
          },
          "&.Mui-completed": {
            color: basicTone400,
            fontSize: "14px",
            fontWeight: 700,
          },
        },
        labelContainer: {
          color: basicTone400,
          fontWeight: 700,
          paddingLeft: "24px",
        },
        iconContainer: { paddingRight: 0, justifyContent: "center" },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          marginLeft: "8px",
          marginTop: "-15px",
          marginBottom: "-15px",
          "&.Mui-active .MuiStepConnector-line": {
            borderStyle: "solid",
            borderImage: mainVerticalLinearGradient,
            borderImageSlice: 1,
            borderImageWidth: "0 0 0 4px",
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderStyle: "solid",
            borderImage: mainVerticalLinearGradient,
            borderImageSlice: 1,
            borderImageWidth: "0 0 0 4px",
          },
        },
        lineVertical: {
          minHeight: "40px",
          borderLeft: `4px solid ${basicTone500}`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: "row-reverse",
          padding: 0,
          gap: "12px",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "8px 0 16px",
          display: "flex",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          bottom: "0px !important",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "12px 12px 0px 0px",
          color: basicTone0,
          "& .MuiAlert-icon": {
            color: basicTone0,
          },
        },
        standardSuccess: {
          backgroundColor: successColor,
        },
        standardError: {
          backgroundColor: mainColor1,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: linearBlackBG,
          borderRadius: secondaryBorderRadius,
        },
      },
    },
  },
});

// darkTheme = responsiveFontSizes(darkTheme);
// lightTheme = responsiveFontSizes(lightTheme);
