import React from "react";
import { Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { SupportedToken } from "../types";
import { basicTone200 } from "../../../shared/styles/constants";
import usdcLogo from "../../../assets/icons/USDCLogo.png";

export const CurrencyMenuItem = ({
  supportedToken,
  selected = false,
}: {
  supportedToken: SupportedToken;
  selected?: boolean;
}) => {
  const getLogo = (tokenItem: SupportedToken) => {
    if (tokenItem.symbol === "USDC") {
      return usdcLogo;
    }
    return tokenItem.logoUrl;
  };
  return (
    <>
      <Grid container alignItems={"center"} gap={1} flexWrap="nowrap">
        {selected && (
          <CheckIcon
            htmlColor={basicTone200}
            fontSize="small"
            sx={{
              width: "12px",
              height: "12px",
            }}
          />
        )}
        <img
          src={getLogo(supportedToken)}
          alt="logourl"
          style={{
            height: "24px",
            width: "24px",
            borderRadius: "50%",
          }}
        />
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {supportedToken.symbol}
        </Typography>
      </Grid>
      <Grid container alignItems={"center"} justifyContent="flex-end">
        <Typography
          variant="body1"
          color={basicTone200}
          fontWeight={500}
          fontStyle="italic"
        >
          {supportedToken.name}
        </Typography>
      </Grid>
    </>
  );
};
