import {
  Card,
  Grid,
  Theme,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext } from "react";

import {
  basicTone0,
  basicTone600,
  dropShadowBG,
  dropShadowSmWhiteBg,
} from "../../shared/styles/constants";

import LogoutIcon from "@mui/icons-material/Logout";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { AccountContext } from "../../shared/providers/account-provider";
import OutlinedPrimaryCustomButton from "../../components/OutlinedPrimaryCustomButton/OutlinedPrimaryCustomButton";
import { LogoImageSVG } from "../../assets/icons/LogoImageSVG";

export const AlreadySignedIn = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { isLightMode } = useContext(ThemeModeContext);
  const { handleDisconnect } = useContext(AccountContext);

  return (
    <Card
      sx={{
        boxShadow: isLightMode ? dropShadowSmWhiteBg : dropShadowBG,
        backgroundColor: isLightMode ? basicTone0 : basicTone600,
        padding: "32px 48px",
        borderRadius: "20px",
        minHeight: "584px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        minWidth: isMobile ? "0" : "450px",
        maxWidth: isMobile ? "max-content" : "480px",
      }}
    >
      <Grid container alignItems="center" height="100%">
        <Stack gap={3} alignItems="center">
          <LogoImageSVG />
          <Typography
            variant="h2"
            textAlign={"center"}
            color="secondary"
          >{`You are already signed in. Do you want to log in with another account? `}</Typography>
        </Stack>
      </Grid>
      <Grid container justifyContent={"center"}>
        <OutlinedPrimaryCustomButton
          onClick={() => handleDisconnect()}
          text="Disconnect"
          StartIcon={<LogoutIcon />}
        />
      </Grid>
    </Card>
  );
};

export default AlreadySignedIn;
