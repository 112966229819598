import { Typography, Stack, Grid } from '@mui/material'
import TextsmsIcon from '@mui/icons-material/Textsms'

import React, { useState, useEffect } from 'react'
import { useGetBlogPostsQuery } from '../../services/blogApi'

import { initialValues, TBlogPosts } from './types'
import BlogPostCard from './BlogPostCard'

export const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState<TBlogPosts>(initialValues)
  const { data, isSuccess } = useGetBlogPostsQuery()

  useEffect(() => {
    if (isSuccess && data) {
      setBlogPosts(data.data)
    }
  }, [isSuccess, data])

  return (
    <div className='Content'>
      <Stack
        gap={7}
        paddingTop={3}
        paddingBottom={8}
        paddingRight={1}
        paddingLeft={1}
      >
        <Typography variant='h1' color='secondary'>
          Review our latest news
        </Typography>
        {blogPosts.mostNoticeable.length ? (
          <Stack gap={5}>
            <Grid container gap={1} alignItems='center'>
              <TextsmsIcon color='info' />
              <Typography variant='h5' color='secondary' fontSize={'20px'}>
                Most noticeable this week
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={4}>
              {blogPosts.mostNoticeable.map((post) => (
                <Grid key={post.id} item xs={12} sm={6} md={4} lg={4}>
                  <BlogPostCard post={post} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : null}
        {blogPosts.updates.length ? (
          <Stack gap={5}>
            <Grid container>
              <Typography variant='h4' color='secondary'>
                Collective Hub updates
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={4}>
              {blogPosts.updates.map((post) => (
                <Grid key={post.id} item xs={12} sm={6} md={4} lg={3}>
                  <BlogPostCard post={post} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : null}
        {blogPosts.posts.length ? (
          <Stack gap={5}>
            <Grid container>
              <Typography variant='h4' color='secondary'>
                All posts
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={4}>
              {blogPosts.posts.map((post) => (
                <Grid key={post.id} item xs={12} sm={6} md={4} lg={3}>
                  <BlogPostCard post={post} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : null}
      </Stack>
    </div>
  )
}

export default BlogPage
