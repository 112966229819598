import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BigNumber from "bignumber.js";

import { ethers } from "ethers";
import { useState, useEffect, useContext } from "react";

import { useCall, useContractFunction, useEthers } from "@usedapp/core";
import { useContracts } from "../../hooks";
import { AccountContext } from "../../shared/providers/account-provider";
import { NotificationsContext } from "../../shared/providers/notifications-provider";

type CardContentProps = {
  fundingContract: any;
  tokenForSaleAddress: string;
  fundingContractAddress: string;
};
export const DepositTabContent = ({
  fundingContract,
  fundingContractAddress,
  tokenForSaleAddress,
}: CardContentProps) => {
  const [allowanceValue, setAllowanceValue] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationsContext);

  // const { account } = useContext(AccountContext);
  const { account } = useEthers();

  const { stableCoinContract } = useContracts({
    stableCoinAddress: tokenForSaleAddress,
  });

  // Allowance
  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, fundingContractAddress],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());
      setAllowanceValue(!val.eq(new BigNumber(0)));
    }
  }, [allowance]);

  // APPROVE
  const { state: approveState, send: approve } = useContractFunction(
    stableCoinContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );

  useEffect(() => {
    if (approveState.status === "Success") {
      setNotification({
        message: "Successfully approved!",
        type: "success",
      });
    } else if (
      approveState.status === "Fail" ||
      approveState.status === "Exception"
    ) {
      setNotification({
        message: "Approve failed!",
        type: "error",
      });
    }
  }, [approveState]);

  const handleApprove = () => {
    approve(fundingContractAddress, ethers.constants.MaxUint256);
  };

  // DEPOSIT
  const { state: depositState, send: depositFundingTokens } =
    useContractFunction(fundingContract, "depositFundingTokens", {
      transactionName: "Deposit funding tokens",
    });

  useEffect(() => {
    if (depositState.status === "Success") {
      setNotification({
        message: "Successfully deposited tokens!",
        type: "success",
      });
    } else if (
      depositState.status === "Fail" ||
      depositState.status === "Exception"
    ) {
      setNotification({
        message: "Deposit tokens failed!",
        type: "error",
      });
    }
  }, [depositState]);

  const handleDepositFundingTokens = () => {
    depositFundingTokens();
  };

  return (
    <Grid container justifyContent={"center"} padding={4}>
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ width: "100%", padding: "40px 0 8px" }}
      >
        <Grid item>
          <Typography variant="body2" color="secondary">
            Stake Amount
            {/* {`Stake amount ${maxAllocation ? `(max: ${maxAllocation})` : ""}`} */}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="secondary">
            {/* {` Balance: ${balance || 0}`} */}
          </Typography>
        </Grid>
      </Grid>

      <Grid item sx={{ padding: "32px 0" }}>
        {allowanceValue ? (
          <LoadingButton
            variant="outlined"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => handleDepositFundingTokens()}
            loading={depositState.status === "Mining"}
            loadingPosition="start"
          >
            DEPOSIT
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="outlined"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => handleApprove()}
            loading={approveState.status === "Mining"}
            loadingPosition="start"
          >
            APPROVE
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
export default DepositTabContent;
