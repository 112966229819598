export const CreditCardGradientIconSVG = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6666 6H9.33331C6.02531 6 3.33331 8.692 3.33331 12V20C3.33331 23.308 6.02531 26 9.33331 26H22.6666C25.9746 26 28.6666 23.308 28.6666 20V12C28.6666 8.69067 25.9746 6 22.6666 6ZM9.33331 7.33333H22.6666C25.24 7.33333 27.3333 9.42667 27.3333 12V12.6667H4.66665V12C4.66665 9.42667 6.75998 7.33333 9.33331 7.33333ZM22.6666 24.6667H9.33331C6.75998 24.6667 4.66665 22.5733 4.66665 20V14H27.3333V20C27.3333 22.572 25.24 24.6667 22.6666 24.6667ZM14 20C14 20.368 13.7013 20.6667 13.3333 20.6667H9.33331C8.96531 20.6667 8.66665 20.368 8.66665 20C8.66665 19.632 8.96531 19.3333 9.33331 19.3333H13.3333C13.7013 19.3333 14 19.6307 14 20Z"
      fill="#0D0D0E"
    />
    <path
      d="M22.6666 6H9.33331C6.02531 6 3.33331 8.692 3.33331 12V20C3.33331 23.308 6.02531 26 9.33331 26H22.6666C25.9746 26 28.6666 23.308 28.6666 20V12C28.6666 8.69067 25.9746 6 22.6666 6ZM9.33331 7.33333H22.6666C25.24 7.33333 27.3333 9.42667 27.3333 12V12.6667H4.66665V12C4.66665 9.42667 6.75998 7.33333 9.33331 7.33333ZM22.6666 24.6667H9.33331C6.75998 24.6667 4.66665 22.5733 4.66665 20V14H27.3333V20C27.3333 22.572 25.24 24.6667 22.6666 24.6667ZM14 20C14 20.368 13.7013 20.6667 13.3333 20.6667H9.33331C8.96531 20.6667 8.66665 20.368 8.66665 20C8.66665 19.632 8.96531 19.3333 9.33331 19.3333H13.3333C13.7013 19.3333 14 19.6307 14 20Z"
      fill="url(#paint0_linear_2080_5834)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2080_5834"
        x1="3.33331"
        y1="6"
        x2="29.9251"
        y2="7.84305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
    </defs>
  </svg>
);
