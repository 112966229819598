import { Typography } from "@mui/material";
import React from "react";

import StepLabel from "../../../components/CustomVerticalStepper/CustomVerticalStepLabel";
import StepContent from "../../../components/CustomVerticalStepper/CustomVerticalStepContent";

export const CreditCardSteps = () => {
  return (
    <>
      <StepLabel label="Choose amount" />
      <StepContent>
        <Typography variant="h6" color="secondary">
          Coming soon...
        </Typography>
      </StepContent>
    </>
  );
};

export default CreditCardSteps;
