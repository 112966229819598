// colors
export const basicTone800 = "#0D0D0E";
export const basicTone700 = "#131314";
export const basicTone600 = "#181718";
export const basicTone500 = "#242323";
export const basicTone450 = "#323030";
export const basicTone400 = "#515154";

export const basicTone200 = "#7E7E84";
export const basicTone100 = "#D0D0D0";
export const basicTone50 = "#ECEDF0";
export const basicTone0 = "#FFFFFF";
export const whiteTone = "#FAFAFA";
export const grayWhtTone = "#D9DEE9";
export const lightText = "#FFFFFFBF";
export const successColor = "#1A8137";

export const mainLinearGradient =
  "linear-gradient(93.13deg, #FF377F 0%, #FF3743 100%), #0D0D0E";
export const mainVerticalLinearGradient =
  "linear-gradient(180deg, #FF377F 0%, #FF3743 100%)";
export const darkLinearGradient =
  "linear-gradient(94.17deg, rgba(255, 255, 255, 0.038) 0%, rgba(255, 255, 255, 0.04) 99.62%)";
export const lightLinearGradient = "linear-gradient(0deg,#fafafa,#fafafa),#fff";

export const footerLinearGradient =
  "linear-gradient(-45deg, rgba(255, 55, 127, 1) 5%, rgba(255, 55, 67, 1) 10%, rgba(255, 85, 187, 1) 13%, rgba(166, 112, 255, 1) 42%, rgba(246, 181, 56, 1) 74%, rgba(255, 55, 127, 1) 84%, rgba(255, 55, 67, 1) 90%, rgba(255, 55, 163, 1) 98%)";

export const mainColor1 = "#FF3743";
export const mainColor2 = "#FF377F";
export const secondaryColor1 = "#802C3C";
export const mainColor2HalfTransparent = "#FF377F1F";

export const linearBlackBG =
  "linear-gradient(104.53deg, #1B191C -1.9%, #121112 99.38%)";

// shadows
export const dropShadowSM = "0px 4px 10px rgba(0, 0, 0, 0.4)";
export const dropShadowBG = "0px 7px 20px rgba(0, 0, 0, 0.51)";
export const dropShadowRed = "0px 8px 32px rgba(255, 55, 127, 0.3)";
export const dropShadowXL = "-6px 4px 40px rgba(0, 0, 0, 0.6)";
export const lightShadow = "0px 8px 20px rgba(77, 91, 142, 0.1)";
export const dropShadowSmWhiteBg = "0px 2px 12px rgba(32, 50, 71, 0.12)";

// radius
export const mainBorderRadius = "8px";
export const secondaryBorderRadius = "12px";
