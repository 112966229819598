import { Button } from "@mui/material";
import { ReactElement } from "react";

interface IProjectsSelectionButtonProps {
  text: string;
  onClick: () => void;
  isSelected: boolean;
  icon?: ReactElement;
}

export const ProjectsSelectionButton = ({
  text,
  onClick,
  isSelected,
  icon,
}: IProjectsSelectionButtonProps) => {
  return (
    <Button
      onClick={onClick}
      startIcon={icon}
      variant="contained"
      color={isSelected ? "primary" : "secondary"}
    >
      {text}
    </Button>
  );
};

export default ProjectsSelectionButton;
