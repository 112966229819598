import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Button, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useState, useEffect, useContext } from "react";
import { useCall, useContractFunction } from "@usedapp/core";
import { NotificationsContext } from "../../shared/providers/notifications-provider";

type CardContentProps = {
  fundingContract: any;
  tokenForSaleAddress: string;
  fundingContractAddress: string;
};
export const WithdrawTabContent = ({
  fundingContract,
  fundingContractAddress,
  tokenForSaleAddress,
}: CardContentProps) => {
  const [isFundingEnded, setFundingEnded] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationsContext);

  const { value: fundingEnded, error: fundingEndedError } =
    useCall({
      contract: fundingContract,
      method: "fundingEnded",
      args: [],
    }) ?? {};

  useEffect(() => {
    if (fundingEnded !== undefined) {
      setFundingEnded(fundingEnded[0]);
    }
  }, [fundingEnded]);

  const { state: withdrawState, send: withdraw } = useContractFunction(
    fundingContract,
    "releaseFundingTokens",
    {
      transactionName: "Release funding tokens",
    }
  );

  useEffect(() => {
    if (withdrawState.status === "Success") {
      setNotification({
        message: "Successfully released tokens!",
        type: "success",
      });
    } else if (
      withdrawState.status === "Fail" ||
      withdrawState.status === "Exception"
    ) {
      setNotification({
        message: "Withdraw failed!",
        type: "error",
      });
    }
  }, [withdrawState]);

  const handleWithdraw = () => {
    withdraw();
  };

  return (
    <Grid container justifyContent={"center"} padding={4}>
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ width: "100%", padding: "40px 0 8px" }}
      >
        <Grid item>
          {!isFundingEnded && (
            <Typography variant="body2" color="error">
              Funding is not finished yet.
            </Typography>
          )}
        </Grid>
      </Grid>

      {isFundingEnded && (
        <Grid item sx={{ padding: "32px 0" }}>
          <LoadingButton
            variant="outlined"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => handleWithdraw()}
            loading={withdrawState.status === "Mining"}
            loadingPosition="start"
          >
            WITHDRAW
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};
export default WithdrawTabContent;
