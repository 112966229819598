import { utils } from "ethers";
import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  StableCoinType,
  SupportedToken,
  TWithdrawData,
  WalletInfoProps,
} from "../../pages/WalletPage/types";
import {
  useGetUserWalletDataQuery,
  useSendWithdrawEmailMutation,
  useWithdrawMutation,
} from "../../services/walletApi";
import { AccountContext } from "./account-provider";
import { NotificationsContext } from "./notifications-provider";

export interface IWalletContext {
  walletInfo: WalletInfoProps | undefined;
  showConfirmationCodeSection: boolean;
  handleSubmitWithdraw: (authCode?: number) => void;
  handleSendEmailCodeWithdraw: (props: TWithdrawData) => void;
  withdrawSuccessful: boolean;
  allSupportedTokens: SupportedToken[];
  withdrawData: TWithdrawData | undefined;
  isSendEmailLoading: boolean;
  withdrawLoading: boolean;
  isWalletAddressValid: boolean;
  setIsWalletAddressValid: () => void;
  usdcInfoByChain: StableCoinType | undefined;
}

export const WalletContext = createContext<IWalletContext>({
  walletInfo: undefined,
  showConfirmationCodeSection: false,
  handleSubmitWithdraw: (authCode?: number) => {},
  handleSendEmailCodeWithdraw: (props: TWithdrawData) => {},
  withdrawSuccessful: false,
  allSupportedTokens: [],
  withdrawData: undefined,
  isSendEmailLoading: false,
  withdrawLoading: false,
  isWalletAddressValid: true,
  setIsWalletAddressValid: () => {},
  usdcInfoByChain: undefined,
});

export const WalletProvider: FC<{ children?: React.ReactNode }> = (props) => {
  // state
  const [walletInfo, setWalletInfo] = useState<WalletInfoProps | undefined>();

  const [withdrawData, setWithdrawData] = useState<TWithdrawData | undefined>(
    undefined
  );
  const [showConfirmationCodeSection, showWithdrawConfirmationCodePage] =
    useState(false);
  const [withdrawSuccessful, setWithdrawSuccessful] = useState(false);
  const [isSendEmailLoading, setSendEmailLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [isWalletAddressValid, setIsWalletAddressValid] = useState(true);
  const [usdcInfoByChain, setUsdcInfoByChain] = useState<
    StableCoinType | undefined
  >(undefined);

  //constants
  const token = localStorage.getItem("token");

  //dependencies
  const { handleDisconnect, userMetamaskChainId } = useContext(AccountContext);
  const { setNotification } = useContext(NotificationsContext);
  const navigate = useNavigate();

  // requests declarations
  const [sendWithdrawEmail] = useSendWithdrawEmailMutation();
  const [submitWithdraw] = useWithdrawMutation();
  const { data: userWalletData, error: userWalletError } =
    useGetUserWalletDataQuery(token);

  useEffect(() => {
    if (isSendEmailLoading) {
      const timer = setTimeout(() => {
        setSendEmailLoading(false);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [isSendEmailLoading]);

  useEffect(() => {
    if (userMetamaskChainId && userWalletData) {
      const usdcStableCoin = userWalletData.data.stableCoins.find(
        (stableCoin: StableCoinType) =>
          stableCoin.chainId === userMetamaskChainId
      );
      setUsdcInfoByChain(usdcStableCoin);
    }
  }, [userMetamaskChainId, userWalletData]);

  useEffect(() => {
    if (userWalletData) {
      const usdcStableCoin = userWalletData.data.stableCoins.find(
        (stableCoin: StableCoinType) =>
          stableCoin.chainId === userMetamaskChainId
      );
      setUsdcInfoByChain(usdcStableCoin);
      setWalletInfo(userWalletData.data);
    }
  }, [userWalletData]);

  useEffect(() => {
    if (userWalletError?.status === 403) {
      handleDisconnect();
      navigate("/register");
    }
  }, [userWalletError]);

  const handleSendEmailCodeWithdraw = ({
    amount,
    symbol,
    destinationAddress,
    tokenAddress,
  }: TWithdrawData) => {
    setWithdrawData({
      amount: amount?.toString(),
      destinationAddress,
      tokenAddress,
      symbol,
    });
    if (destinationAddress && utils.isAddress(destinationAddress)) {
      setSendEmailLoading(true);
      sendWithdrawEmail({ token, amount: amount?.toString(), symbol })
        .unwrap()
        .then(() => {
          setSendEmailLoading(false);
          showWithdrawConfirmationCodePage(true);
        })
        .catch((err: any) => {
          setSendEmailLoading(false);
          if (err?.status === 403) {
            handleDisconnect();
            navigate("/register");
          } else {
            setNotification({
              type: "error",
              message:
                err?.message || "Something went wrong. Please try again!",
            });
          }
        });
    } else {
      setIsWalletAddressValid(false);
    }
  };

  const handleSubmitWithdraw = (authCode?: number) => {
    if (authCode && withdrawData) {
      setWithdrawLoading(true);
      submitWithdraw({
        token,
        amount: withdrawData.amount,
        destinationAddress: withdrawData.destinationAddress,
        tokenAddress: withdrawData.tokenAddress,
        authCode,
      })
        .unwrap()
        .then((data: any) => {
          if (data?.data.receipt.status === 1) {
            setWithdrawSuccessful(true);
            showWithdrawConfirmationCodePage(false);
            setWithdrawLoading(false);
          }
        })
        .catch((err: any) => {
          setWithdrawLoading(false);
          if (err?.status === 403) {
            handleDisconnect();
            navigate("/register");
          }
        });
    }
  };

  const getAllSupportedTokens = (): SupportedToken[] => {
    if (!walletInfo) {
      return [];
    }
    const usdc = usdcInfoByChain
      ? { ...usdcInfoByChain, name: "USDC", id: "usdc" }
      : undefined;

    const tokensById =
      walletInfo?.supportedTokens.find(
        (list) => list.chainId === userMetamaskChainId
      )?.tokens || [];

    return usdc ? [usdc as SupportedToken, ...tokensById] : [...tokensById];
  };

  return (
    <WalletContext.Provider
      value={{
        walletInfo,
        showConfirmationCodeSection,
        handleSubmitWithdraw,
        handleSendEmailCodeWithdraw,
        withdrawSuccessful,
        allSupportedTokens: getAllSupportedTokens(),
        withdrawData,
        isSendEmailLoading,
        withdrawLoading,
        isWalletAddressValid,
        setIsWalletAddressValid: () => setIsWalletAddressValid(true),
        usdcInfoByChain,
      }}
    >
      {walletInfo && props.children}
    </WalletContext.Provider>
  );
};
