import { BSC, BSCTestnet, Mainnet, Polygon } from "@usedapp/core";
import { basicTone50 } from "./styles/constants";
import PolygonLogo from "../assets/Polygonlogo.png";
import BscTestNetLogo from "../assets/BscTestNetLogo.png";
import MainnetLogo from "../assets/MainnetLogo.png";

export const getRemainingTime = (deadline: string) => {
  const total = Date.parse(deadline) - Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const formatAddress = (address?: string) =>
  address
    ? `${address.slice(0, 6)}...${address.slice(
        address.length - 6,
        address.length
      )}`
    : "";

export const formatAddressShort = (address?: string) =>
  address
    ? `${address.slice(0, 3)}...${address.slice(
        address.length - 3,
        address.length
      )}`
    : "";

export const formatDate = (dateAsString: string) => {
  const d = new Date(dateAsString);
  return `${d.getDate()}.${("0" + (d.getMonth() + 1)).slice(
    -2
  )}.${d.getFullYear()}`;
};

export const formatShortDate = (dateAsString: string) => {
  const d = new Date(dateAsString);
  return `${d.getDate()}.${("0" + (d.getMonth() + 1)).slice(-2)}`;
};

export const formatBigNumbersStrings = (nr: string | undefined) => {
  if (!nr) {
    return "0";
  }
  if (nr.length <= 3) {
    return nr;
  }
  const isMillionary = nr.length > 6;
  const integer = parseFloat(nr);
  const formattedNr = isMillionary ? integer / 1e6 : integer / 1e3;
  return `${formattedNr}${isMillionary ? "M" : "k"}`;
};

export const getDisplayedDays = (nrOfDays: string | number) =>
  `${nrOfDays} ${nrOfDays === 1 ? "Day" : "Days"}`;

// STYLING

export const gradientCardBackground = (isLightMode: boolean) =>
  isLightMode
    ? `linear-gradient(112.71deg, rgba(255, 55, 127, 0.2) 0%, ${basicTone50} 20%)`
    : "linear-gradient(90deg, #2d1623 0%, #131314 15%)";

export const AvailableChains = {
  Polygon: 137,
  BinanceTestNet: 97,
  BinanceMainnet: 56,
  Mainnet: 1,
};

export const getChainNameById = (chain: number | undefined) => {
  switch (chain) {
    case BSCTestnet.chainId: {
      return BSCTestnet.chainName;
    }
    case Polygon.chainId: {
      return Polygon.chainName;
    }
    case BSC.chainId: {
      return BSC.chainName;
    }
    case Mainnet.chainId: {
      return "Ethereum Mainnet";
    }
    default: {
      return "Unsupported chain";
    }
  }
};

export const getChainLogoById = (chain: number | undefined) => {
  switch (chain) {
    case BSCTestnet.chainId: {
      return BscTestNetLogo;
    }
    case Polygon.chainId: {
      return PolygonLogo;
    }
    case BSC.chainId: {
      return BscTestNetLogo;
    }
    case Mainnet.chainId: {
      return MainnetLogo;
    }
    default: {
      return undefined;
    }
  }
};
