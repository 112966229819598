import { Button, ListItem, Grid, Stack, Typography } from "@mui/material";
import { Polygon, useCall } from "@usedapp/core";
import React, { useContext, useState, useEffect } from "react";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainBorderRadius,
  whiteTone,
} from "../../shared/styles/constants";
import { AccountContext } from "../../shared/providers/account-provider";
import { useContracts } from "../../hooks";
import {
  MARKETPLACE_BSCTESTNET_ADDRESS,
  MARKETPLACE_MATIC_ADDRESS,
} from "../../config/MarketPlaceAbi";
import { mapAskOrdersListData } from "./ordersModals/ordersModals.utils";
import { TAskOrder } from "./types";
import BuyTokensModal from "./sellBuyModals/BuyTokensModal";
import { STABLE_COIN_ADDRESS } from "../../config/CoinAbi";
import BigNumber from "bignumber.js";

export const AskOrdersList = ({ tokenAddress }: { tokenAddress: string }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const [askOrders, setAskOrders] = useState<TAskOrder[]>([]);
  const [selectedAskOrder, setSelectedAskOrder] = useState<
    TAskOrder | undefined
  >(undefined);
  const { userMetamaskChainId } = useContext(AccountContext);

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress:
      userMetamaskChainId === Polygon.chainId
        ? MARKETPLACE_MATIC_ADDRESS
        : MARKETPLACE_BSCTESTNET_ADDRESS,
  });

  const {
    value: listAskOrdersPerTokenResult,
    error: listAskOrdersPerTokenError,
  } =
    useCall({
      contract: marketplaceContract,
      method: "listAskOrdersPerToken",
      args: [tokenAddress],
    }) ?? {};

  useEffect(() => {
    if (listAskOrdersPerTokenResult) {
      setAskOrders(mapAskOrdersListData(listAskOrdersPerTokenResult[0]));
    }
  }, [listAskOrdersPerTokenResult]);

  return (
    <Grid item xs={12} md={6}>
      <Stack gap={2}>
        <Typography variant="h3" color="secondary" textAlign={"center"}>
          Asks
        </Typography>
        <Stack gap={0.5}>
          <ListItem sx={{ padding: "8px 12px 0" }}>
            <Grid container alignItems={"center"}>
              <Grid item xs={3} gap={1} container alignItems={"center"}>
                <Typography variant="h6">{` `}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">{`Full price`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">{`Price per token`}</Typography>
              </Grid>
              <Grid item xs={2} gap={1} container alignItems={"center"}>
                <Typography variant="h6">{`Quantity`}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          {askOrders.map((item) => (
            <ListItem
              key={item.id.toString()}
              sx={{
                border: `1px solid ${
                  isLightMode ? basicTone100 : basicTone500
                }`,
                filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,
                borderRadius: mainBorderRadius,
                padding: "2px 12px",
                background: isLightMode ? basicTone0 : basicTone600,
                "&:hover": {
                  background: isLightMode ? basicTone50 : basicTone500,
                },
              }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={3}>
                  <Button
                    variant="text"
                    color="success"
                    onClick={() => setSelectedAskOrder(item)}
                    sx={{
                      padding: "2px",
                      fontSize: "14px",
                    }}
                  >
                    Buy
                  </Button>
                  {selectedAskOrder ? (
                    <BuyTokensModal
                      open={!!selectedAskOrder}
                      onClose={() => setSelectedAskOrder(undefined)}
                      saleId={selectedAskOrder?.id}
                      payToken={STABLE_COIN_ADDRESS}
                      fullPrice={new BigNumber(
                        selectedAskOrder?.fullPrice.toString()
                      )
                        .multipliedBy(1e6)
                        .toString()}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color={"secondary"}
                  >
                    {item.fullPrice}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color={"secondary"}
                  >
                    {item.pricePerToken}
                  </Typography>
                </Grid>
                <Grid item xs={2} gap={1} container alignItems={"center"}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color="secondary"
                  >
                    {`$${item.quantity.toString()}`}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default AskOrdersList;
