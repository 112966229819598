import { Card, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeModeContext } from "../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone700,
  mainColor2,
} from "../shared/styles/constants";
import { config } from "../config/config";

export const SubmitYourProjectCard = () => {
  const navigate = useNavigate();
  const { isLightMode } = useContext(ThemeModeContext);
  const mainColor = isLightMode ? basicTone100 : "#FFFFFF1A";
  const [iconColor, setIconColor] = useState(mainColor);
  return (
    <Card
      component={"a"}
      href={`${config.LANDING_PAGE_URL}/contact.html`}
      sx={{
        background: "transparent",
        border: `2px dashed ${mainColor}`,
        height: "400px",
        width: "100%",
        borderRadius: "19px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "none",
        color: mainColor,
        textDecoration: "none",
        "&:hover": {
          color: isLightMode ? basicTone700 : basicTone0,
        },
      }}
      onMouseOver={() => setIconColor(mainColor2)}
      onMouseOut={() => setIconColor(mainColor)}
    >
      <Typography
        variant="h1"
        sx={{
          lineHeight: "0.8",
          fontSize: { xs: "46px", sm: "70px", md: "128px" },
        }}
      >
        Submit your
      </Typography>
      <Typography
        variant="h1"
        sx={{
          lineHeight: "0.8",
          fontSize: { xs: "46px", sm: "70px", md: "128px" },
        }}
      >
        project
      </Typography>
      <Box
        sx={{
          marginTop: "30px",
          padding: "8px",
          borderRadius: "20px",
          border: `1px solid ${iconColor}`,
        }}
      >
        <IconButton sx={{ "&:hover": { background: "transparent" } }}>
          <AddOutlinedIcon fontSize="medium" htmlColor={iconColor} />
        </IconButton>
      </Box>
    </Card>
  );
};

export default SubmitYourProjectCard;
