import React, { useContext } from 'react'
import {
  Button,
  Card,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { FingerPrintSVG } from '../../../assets/FingerPrint'
import { ThemeModeContext } from '../../../shared/providers/theme-mode-provider'
import { gradientCardBackground } from '../../../shared/utils'
import {
  basicTone100,
  basicTone200,
  basicTone400,
  basicTone50,
  basicTone500,
  basicTone600,
  mainBorderRadius,
  whiteTone,
} from '../../../shared/styles/constants'
import { Link } from 'react-router-dom'
import { config } from '../../../config/config'

export const VerifyKycCard = () => {
  const { isLightMode } = useContext(ThemeModeContext)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const kycStatus = localStorage.getItem('kycStatus')

  const generateKYCLink = () => {
    const uniqueID = uuidv4()
    const stateParam = uniqueID.slice(0, 8)
    localStorage.setItem('stateParam', stateParam)

    const url = `https://app.next.fractal.id/authorize?client_id=${config.CLIENT_ID}&redirect_uri=${config.ENV_URL}/kyc&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread&state=${stateParam}`
    window.location.href = url
  }
  return (
    <Card
      sx={{
        width: '100%',
        padding: { xs: '40px 24px', md: '16px 36px' },
        borderRadius: '12px',
        backgroundColor: 'rgb(78,37,61)',
        background: gradientCardBackground(isLightMode),

        display: 'flex',
        alignItems: 'center',
        border: 'none',
      }}
    >
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems='center'
        gap={{ xs: 1, md: 0 }}
      >
        <Grid item>
          <Grid container wrap='nowrap' gap={2} alignItems='center'>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <FingerPrintSVG width={isMobile ? '32' : undefined} />
            </Box>
            <Grid container flexDirection={'column'} maxWidth={450}>
              <Typography
                variant={isMobile ? 'h6' : 'h4'}
                color='secondary'
                fontWeight={400}
              >
                Verify your account now to get started with
                <strong> Collective Hub</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} gap={1}>
          <Button
            variant='contained'
            color={'primary'}
            onClick={() => generateKYCLink()}
            disabled={kycStatus === 'pending'}
          >
            {kycStatus === 'pending' ? 'Pending' : ' Verify now'}
          </Button>
          <Link to={`kyc-info`} style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                cursor: 'pointer',
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                border: `1px solid ${isLightMode ? basicTone50 : basicTone500}`,
                filter: 'drop-shadow(1px 4px 4px rgba(0, 0, 0, 0.08))',
                padding: '4px 15px',
                borderRadius: mainBorderRadius,
                color: isLightMode ? basicTone200 : basicTone200,
                '&:hover': {
                  backgroundColor: isLightMode ? whiteTone : basicTone600,
                  color: isLightMode ? basicTone400 : basicTone100,
                },
              }}
            >
              <Typography variant='h4'>?</Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Card>
  )
}

export default VerifyKycCard
