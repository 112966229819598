import {
  Typography,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

import React, { useContext } from "react";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { basicTone100 } from "../../shared/styles/constants";

export const KYCVerificationStepsPage = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <div className="Content">
      <Stack
        gap={4}
        paddingTop={3}
        paddingBottom={8}
        margin={"0 auto"}
        paddingRight={{ xs: 2, sm: 6, md: 8 }}
        paddingLeft={{ xs: 2, sm: 6, md: 8 }}
      >
        <Stack gap={1}>
          <Typography variant="h1" color="secondary">
            Completing Your KYC Verification on CollectivePad
          </Typography>
        </Stack>
        <Divider />

        <Stack gap={2}>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            To initiate your KYC verification process on CollectivePad, please
            follow these steps
          </Typography>
          <List>
            <Typography
              fontSize={"16px"}
              textAlign={"justify"}
              color={isLightMode ? "secondary" : basicTone100}
            >
              1. Register on CollectivePad by creating an account.
            </Typography>
            <Typography
              fontSize={"16px"}
              textAlign={"justify"}
              color={isLightMode ? "secondary" : basicTone100}
            >
              2. Prepare a valid identification document such as your passport,
              driving license, government-issued ID, or residence permit.
            </Typography>
          </List>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Once you have successfully logged in to CollectivePad, access your
            dashboard and click on the KYC verification button. Then, proceed
            with the following actions
          </Typography>
          <List>
            <Typography
              fontSize={"16px"}
              textAlign={"justify"}
              color={isLightMode ? "secondary" : basicTone100}
            >
              1. Select the type of identification document and the issuing
              country.
            </Typography>
            <Typography
              fontSize={"16px"}
              textAlign={"justify"}
              color={isLightMode ? "secondary" : basicTone100}
            >
              2. Capture clear photos of the front and back of your chosen ID.
            </Typography>
            <Typography
              fontSize={"16px"}
              textAlign={"justify"}
              color={isLightMode ? "secondary" : basicTone100}
            >
              3. Record a selfie video as instructed.
            </Typography>
          </List>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            The entire process is designed to be completed within approximately
            30 seconds. You will receive feedback via email after each step has
            been successfully verified.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            For detailed instructions, please refer to our comprehensive How-to
            Guide on completing the KYC process.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Understanding KYC and AML:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            KYC, short for Know Your Customer (or Client), and AML, an acronym
            for Anti-Money Laundering, encompass a set of comprehensive measures
            employed by traditional financial institutions in numerous countries
            to uphold regulatory standards. These standards are established by
            governments to safeguard customers and mitigate the risks associated
            with fraud and money laundering.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            While KYC represents an integral part of AML initiatives to combat
            financial crimes, it constitutes only a fraction of the overall
            efforts. The KYC process employs a risk-based approach that
            evaluates the level of financial risk on a case-by-case basis,
            enabling the application of appropriate KYC measures. Consequently,
            the complexity of your KYC process may vary depending on the risk
            assessment associated with your financial activities.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            KYC for Crypto:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            KYC procedures for cryptocurrencies, also known as crypto, serve the
            purpose of verifying customers' identities within crypto exchanges
            and service providers. These measures are implemented to prevent
            identity fraud, money laundering, terrorist financing, and evasion
            of sanctions. The implementation of KYC in the crypto industry
            mirrors the practices observed in traditional financial
            institutions.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            As the crypto industry continues to expand and attract increased
            traditional financial capital, these verification steps are expected
            to become mandatory for crypto exchanges and service providers
            worldwide, including those operating within the European Union (EU),
            in the coming years.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Collaboration with Fractal and InvestReady:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            To ensure a rapid and seamless KYC verification process,
            CollectivePad has partnered with Fractal. For US members, an
            additional verification process compliant with the Securities and
            Exchange Commission (SEC) is facilitated through our collaboration
            with InvestReady.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            The Importance of KYC for all CollectivePad Users:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Completion of the KYC process is essential for all users of
            CollectivePad to prevent impersonation and ensure the utmost safety
            and security while utilizing our platform. The information gathered
            through KYC enables CollectivePad to tailor safety measures and
            enhancements specific to each user.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Safety of the KYC Process:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Advancements in financial security technology have made the KYC
            verification process in the crypto industry a pivotal instrument in
            facilitating secure and swift cryptocurrency transactions. This
            process effectively protects users against various forms of fraud
            and cyberattacks. The CollectivePad KYC process offers a high level
            of security while minimizing the amount of data required.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            When is KYC Required for CollectivePad:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Upon successful registration using your CollectivePad email and
            password, the next step is to complete the KYC verification process.
            This verification is necessary to enable you to hold $CollectivePad
            tokens, engage in staking activities, and participate in all types
            of Initial DEX Offerings (IDOs). Discover other unique applications
            for $CollectivePad tokens on our platform.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Required Documents for KYC Verification:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            To complete the KYC Identity Verification, you will need a valid
            passport, driving license, residence permit, or any other
            government-issued identification document.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            KYC Process Oversight:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Our KYC process is managed by Fractal, a highly secure and efficient
            customer verification company. For US investors, an additional
            SEC-compliant KYC verification is facilitated through our partner,
            InvestReady.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Duration of the CollectivePad KYC Process:
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            For users outside the United States, the entire KYC process
            typically takes between 15 and 45 seconds, depending on factors such
            as internet speed and risk assessment outcome.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            For US investors, the verification process may take between 24 and
            72 hours.
          </Typography>
        </Stack>

        <Typography variant="h5" color="secondary" fontWeight={700}>
          Steps to Complete Your KYC with Fractal:
        </Typography>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Step 1: ID Verification
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Ensure you have your preferred identification document readily
                available.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Select the issuing country of your chosen ID.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Capture and submit clear front and back photos of your ID.
              </Typography>
            </ListItem>
          </List>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Tips for KYC ID Verification
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Avoid capturing images with glare from light sources.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Choose a well-lit area for capturing photos.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Use a plain background for better clarity.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Fill the image frame with the document.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Place the ID on a flat surface during capture.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Ensure your ID has a validity of at least six months.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Take a clear photo without applying any color filters.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Use only original documents; scans and photocopies may not be
                accepted.
              </Typography>
            </ListItem>
          </List>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Step 2: Selfie Capture
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Following the completion of ID verification, proceed to take a video
            selfie as the next step. Please read and understand the instructions
            provided before clicking Next.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Grant Fractal access to your camera to proceed with the selfie
            capture process. Capture a selfie using the front camera, ensuring
            your face is clearly visible. Click on the circle below to take a
            picture when you are satisfied with the positioning.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h5" color="secondary" fontWeight={700}>
            Tips for Your KYC Selfie Capture
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Choose a well-lit area with a plain background.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Ensure you are the only person in the selfie.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Remove any objects or accessories that may obstruct your face,
                such as glasses.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Avoid capturing images with glare from light sources.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Position your face within the oval outline provided.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Maintain a neutral facial expression.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <ArrowRightOutlinedIcon color="secondary" />
              </ListItemIcon>
              <Typography
                fontSize={"16px"}
                textAlign={"justify"}
                color={isLightMode ? "secondary" : basicTone100}
              >
                Do not use any filters or modifications.
              </Typography>
            </ListItem>
          </List>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Upon successful submission and approval of your documents, you will
            receive an email confirmation. If your KYC verification attempt is
            unsuccessful, please consult the guidelines, contact CollectivePad
            support, or reach out to our 24-hour helpdesk.
          </Typography>
          <Typography
            fontSize={"16px"}
            textAlign={"justify"}
            color={isLightMode ? "secondary" : basicTone100}
          >
            Note: US residents must also complete the InvestReady KYC process
            using the same email associated with their CollectivePad account.
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default KYCVerificationStepsPage;
