import { useContext, useState, useEffect } from 'react'
import {
  Button,
  Card,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Stack,
  FormControl,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'

import { FingerPrintSVG } from '../../assets/FingerPrint'
import { ThemeModeContext } from '../../shared/providers/theme-mode-provider'
import { gradientCardBackground } from '../../shared/utils'
import {
  useSendValidationEmailMutation,
  useSetNotificationEmailMutation,
} from '../../services/authenticationApi'
import { AccountContext } from '../../shared/providers/account-provider'

export const ValidateEmailCard = () => {
  const { isLightMode } = useContext(ThemeModeContext)
  const { isConnectedToMetamask } = useContext(AccountContext)
  const [emailSent, setEmailSent] = useState(false)
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [notificationEmail, setNotificationEmail] = useState<
    string | undefined
  >(undefined)
  const [sendValidationEmail] = useSendValidationEmailMutation()
  const [setValidationEmailForMetamask] = useSetNotificationEmailMutation()

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false)
      }, 60000)
      return () => clearTimeout(timer)
    }
  }, [emailSent])

  const handleSendValidationEmail = () => {
    const token = localStorage.getItem('token')
    if (isConnectedToMetamask) {
      setValidationEmailForMetamask({
        token,
        notificationEmail: notificationEmail?.trim(),
      })
        .unwrap()
        .then(() => {
          setEmailSent(true)
        })
        .catch(() => {
          setEmailAlreadyExists(true)
        })
    } else {
      sendValidationEmail({ token })
        .unwrap()
        .then(() => {
          setEmailSent(true)
        })
    }
  }
  return (
    <Card
      sx={{
        width: '100%',
        padding: { xs: '40px 24px', md: '16px 28px' },
        borderRadius: '12px',
        backgroundColor: 'rgb(78,37,61)',
        background: gradientCardBackground(isLightMode),
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        border: 'none',
      }}
    >
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems='center'
        gap={{ xs: 1, md: 0 }}
      >
        <Grid item>
          <Grid container wrap='nowrap' gap={2} alignItems='center'>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <FingerPrintSVG width={isMobile ? '32' : undefined} />
            </Box>
            <Grid container flexDirection={'column'} maxWidth={450}>
              <Typography
                variant={isMobile ? 'h6' : 'h4'}
                color='secondary'
                fontWeight={400}
                textAlign={{ xs: 'center', md: 'start' }}
              >
                Verify your e-mail now to get started with
                <strong> Collective Hub</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          gap={2}
        >
          {isConnectedToMetamask && (
            <Stack>
              <OutlinedInput
                fullWidth
                color='primary'
                name='email'
                size='small'
                onChange={(ev) => {
                  setEmailAlreadyExists(false)
                  setNotificationEmail(ev.target.value)
                }}
                required
                placeholder='E-mail'
                startAdornment={
                  <InputAdornment position='start'>
                    <EmailIcon fontSize='small' color={'secondary'} />
                  </InputAdornment>
                }
              />
              {emailAlreadyExists ? (
                <FormHelperText error>
                  *This e-mail exists or it is incorrect.
                </FormHelperText>
              ) : null}
            </Stack>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={handleSendValidationEmail}
            disabled={emailSent}
          >
            {emailSent
              ? `Try again in 60 seconds...`
              : `Send validation e-mail`}
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ValidateEmailCard
