import { Grid } from "@mui/material";
import React, { useContext } from "react";
import SubmitYourProjectCard from "../../components/SubmitYourProjectCard";
import FeaturedProjects from "./sections/featured-projects/FeaturedProjects";
import VerifyKycCard from "./sections/VerifyKycCard";
import { AccountContext } from "../../shared/providers/account-provider";
import LatestNews from "./sections/LatestNews";

export const ExplorePage = () => {
  const hasKyc = localStorage.getItem("kyc") === "yes";
  const { account } = useContext(AccountContext);

  return (
    <div className="Content">
      <Grid
        container
        sx={{
          padding: "30px 24px 12px",
        }}
        gap={6}
      >
        {!hasKyc && account ? (
          <Grid item xs={12}>
            <VerifyKycCard />
          </Grid>
        ) : null}
        <LatestNews />
        <FeaturedProjects />
        <SubmitYourProjectCard />
      </Grid>
    </div>
  );
};

export default ExplorePage;
