import { Button, Card, Grid, Typography } from "@mui/material";
import { useState, useContext } from "react";
import { BSC, BSCTestnet, Polygon, useEthers } from "@usedapp/core";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { IChain } from "../../../../models/project.model";

type DifferentChainCardProps = {
  projectChain: IChain;
};

export const DifferentChainCard = ({
  projectChain,
}: DifferentChainCardProps) => {
  const { switchNetwork } = useEthers();
  const [isSwitchNetworkDisabled, setIsSwitchNetworkDisabled] = useState(false);

  const { setUserMetamaskChainId, isConnectedToMetamask } =
    useContext(AccountContext);

  const handleSwitchNetwork = async () => {
    setIsSwitchNetworkDisabled(true);
    let toUpdateChainId = projectChain.chainId;
    if (projectChain.chainId && isConnectedToMetamask) {
      switch (projectChain.chainId) {
        case BSCTestnet.chainId: {
          toUpdateChainId = BSCTestnet.chainId;
          break;
        }
        case Polygon.chainId: {
          toUpdateChainId = Polygon.chainId;
          break;
        }
        case BSC.chainId: {
          toUpdateChainId = BSC.chainId;
          break;
        }
        default: {
          break;
        }
      }
      if (toUpdateChainId) {
        await switchNetwork(toUpdateChainId)
          .then(() => {
            setUserMetamaskChainId(toUpdateChainId);
          })
          .catch((err) => console.log(err));
        setIsSwitchNetworkDisabled(false);
      }
    }
  };

  return (
    <Card sx={{ padding: "24px" }}>
      <Grid container justifyContent={"center"} padding={3}>
        <Typography variant="body1" textAlign={"center"} fontWeight={500}>
          {`This project is on a different chain. Please switch the chain if you want to see if you're a winner for this round.`}
        </Typography>
      </Grid>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleSwitchNetwork}
        sx={{ height: "48px" }}
        disabled={isSwitchNetworkDisabled}
      >
        SWITCH TO {projectChain.label.toUpperCase()}
      </Button>
    </Card>
  );
};

export default DifferentChainCard;
