import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";

//Set a return type for result from backend(object)
interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const authenticationApi: any = createApi({
  reducerPath: "authenticationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    getSalt: builder.mutation<void, { address: string }>({
      query: (body) => ({
        url: "/accounts/get-salt",
        method: "POST",
        body: body,
      }),
    }),
    authenticateUserWallet: builder.mutation<
      void,
      { address: string; signature: string }
    >({
      query: (body) => ({
        url: "/accounts/authenticate-user-wallet",
        method: "POST",
        body: body,
      }),
    }),
    registerUserWallet: builder.mutation<void, { address: string }>({
      query: (body) => ({
        url: "/accounts/register-user-wallet",
        method: "POST",
        body: body,
      }),
    }),
    registerUser: builder.mutation<
      void,
      { email: string; password: string; username?: string }
    >({
      query: (body) => ({
        url: "/accounts/register-user",
        method: "POST",
        body: body,
      }),
    }),
    authenticateUser: builder.mutation<
      void,
      { email: string; password: string }
    >({
      query: (body) => ({
        url: "/accounts/authenticate-user",
        method: "POST",
        body: body,
      }),
    }),

    validateEmail: builder.mutation<
      void,
      { userId: string; validateToken: string }
    >({
      query: ({ userId, validateToken }) => ({
        url: "/accounts/validate-email",
        method: "POST",
        body: {
          token: validateToken,
          userId: parseInt(userId),
        },
      }),
    }),
    sendValidationEmail: builder.mutation<void, { token: string }>({
      query: ({ token }) => ({
        url: "accounts/send-validation-email",
        method: "POST",
        body: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    setNotificationEmail: builder.mutation<
      void,
      { token: string; notificationEmail: string }
    >({
      query: ({ token, notificationEmail }) => ({
        url: "accounts/set-notification-email",
        method: "POST",
        body: { notificationEmail },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    uploadAvatar: builder.mutation<void, { token: string; file: any }>({
      query: ({ token, file }) => ({
        url: "accounts/upload-avatar",
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    updateInfo: builder.mutation<void, { token: string; username: string }>({
      query: ({ token, username }) => ({
        url: "accounts/update-info",
        method: "POST",
        body: { username },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUserConfig: builder.query<ResultFromBackend, string>({
      query: (token) => ({
        url: `/web/get-user-info`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useAuthenticateUserWalletMutation,
  useRegisterUserWalletMutation,
  useRegisterUserMutation,
  useAuthenticateUserMutation,
  useValidateEmailMutation,
  useSendValidationEmailMutation,
  useSetNotificationEmailMutation,
  useUploadAvatarMutation,
  useUpdateInfoMutation,
  useGetSaltMutation,
  useGetUserConfigQuery,
} = authenticationApi;
