import { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import BigNumber from "bignumber.js";

import { SupportedTokenCard } from "../SupportedTokenCard";
import { SupportedToken } from "../types";
import { Contract, utils } from "ethers";
import { STABLE_COIN_ABI } from "../../../config/CoinAbi";
import { AccountContext } from "../../../shared/providers/account-provider";
import { useCalls } from "@usedapp/core";
import { mapSupportedTokensWithBalancesInitialValues } from "../WalletPage.utils";

export const SupportedTokensByChain = ({
  supportedTokens,
  chainId,
}: {
  supportedTokens: SupportedToken[];
  chainId: number;
}) => {
  const { account, userMetamaskChainId } = useContext(AccountContext);
  const [tokensWithBalances, setTokensWithBalances] = useState<
    (SupportedToken & { balance?: string })[]
  >(mapSupportedTokensWithBalancesInitialValues(supportedTokens));
  const stableCoinInterface = new utils.Interface(STABLE_COIN_ABI);

  const contracts = supportedTokens.map((token) => {
    const contract = new Contract(token.address, stableCoinInterface);
    return contract;
  });

  const balancesCalls = contracts.map((contract) => ({
    contract,
    method: "balanceOf",
    args: [account],
  }));

  const balancesResults = useCalls(balancesCalls) ?? [];

  useEffect(() => {
    if (balancesResults && chainId === userMetamaskChainId) {
      const updatedTokensList = [...tokensWithBalances];
      balancesResults.forEach((result, index) => {
        if (result?.value) {
          const val = new BigNumber(result.value.toString())
            .dividedBy(1e18)
            .decimalPlaces(2, 1)
            .toString();
          updatedTokensList[index].balance = val;
        }
      });
      setTokensWithBalances(updatedTokensList);
    }
  }, [balancesResults, userMetamaskChainId]);

  return (
    <Stack gap={0.5}>
      {tokensWithBalances.map((supportedToken) => (
        <SupportedTokenCard key={supportedToken.id} {...supportedToken} />
      ))}
    </Stack>
  );
};

export default SupportedTokensByChain;
