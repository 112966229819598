import { Avatar, Card, Grid, Stack, Typography } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import AppsIcon from "@mui/icons-material/Apps";
import React, { useContext } from "react";
import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainLinearGradient,
  whiteTone,
} from "../../shared/styles/constants";
import { formatAddress } from "../../shared/utils";
import IconButton from "@mui/material/IconButton";
import { DashboardContext } from "../../shared/providers/dashboard-provider";
import Tooltip from "@mui/material/Tooltip";
import AvatarDefault from "../../assets/Avatar.jpg";
import BalanceSection from "./BalanceSection";

export const UserInfoCard = () => {
  const { usdcInfoByChain, showEditUserProfile, setShowEditUserProfile } =
    useContext(DashboardContext);
  const { isLightMode } = useContext(ThemeModeContext);
  // const [balance, setBallance] = useState<string>("0");
  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("username");
  const avatar = localStorage.getItem("avatar");

  // const { stableCoinContract } = useContracts({
  //   stableCoinAddress: usdcInfoByChain?.address,
  // });

  // const { value: balanceOf } =
  //   useCall({
  //     contract: stableCoinContract,
  //     method: "balanceOf",
  //     args: [account],
  //   }) ?? {};

  // useEffect(() => {
  //   if (balanceOf) {
  //     const val = new BigNumber(balanceOf.toString()).dividedBy(1e6).toString();
  //     setBallance(val);
  //   }
  // }, [balanceOf]);

  return (
    <Stack gap={1}>
      <Card sx={{ padding: { xs: "20px", md: "40px 20px 40px 40px" } }}>
        <Grid
          container
          justifyContent={"space-between"}
          flexWrap="nowrap"
          alignItems="center"
        >
          <Grid
            xs={11}
            item
            container
            gap={2}
            alignItems="center"
            flexWrap={{ xs: "wrap", md: "nowrap" }}
          >
            <Avatar
              alt={"avatar"}
              src={avatar !== "null" ? avatar || undefined : AvatarDefault}
              sx={{
                width: { xs: "30px", md: "64px" },
                height: { xs: "30px", md: "64px" },
              }}
              variant="rounded"
            />
            <Grid flexDirection={"column"}>
              {userName !== "null" ? (
                <Typography variant="h4" color="secondary">
                  {userName}
                </Typography>
              ) : null}
              {email && email.length && email !== "null" ? (
                <Typography
                  variant="body1"
                  color="default"
                  fontWeight={700}
                  sx={{
                    background: mainLinearGradient,
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {email}
                </Typography>
              ) : null}
              <Typography
                variant="body1"
                color="default"
                fontWeight={700}
                sx={{
                  background: mainLinearGradient,
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {formatAddress(account)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={1}
            container
            item
            justifyContent={"flex-end"}
            alignItems={{ xs: "flex-end", md: "center" }}
          >
            {showEditUserProfile ? (
              <Tooltip title="Your applications">
                <IconButton onClick={() => setShowEditUserProfile(false)}>
                  <AppsIcon color="info" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit profile">
                <IconButton onClick={() => setShowEditUserProfile(true)}>
                  <SettingsIcon color="info" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ padding: { xs: "24px", md: "40px" } }}>
        <Stack gap={1.5}>
          <Typography variant="h6" color="secondary" fontWeight={700}>
            Current balance:
          </Typography>
          {usdcInfoByChain && (
            <BalanceSection usdcInfoByChain={usdcInfoByChain} />
          )}
          {isConnectedToMetamask && !usdcInfoByChain && (
            <Card
              sx={{
                padding: "30px 22px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                boxShadow: !isLightMode ? dropShadowSM : "none",
                border: isLightMode
                  ? `1px solid ${basicTone50}`
                  : `1px solid ${basicTone500}`,
                backgroundColor: isLightMode ? whiteTone : basicTone600,
              }}
            >
              <Stack gap={1}>
                <Typography variant="h5" color="secondary">
                  Unsupported chain!
                </Typography>
                <Typography>
                  Please switch network to be able to see wallet info.
                </Typography>
              </Stack>
            </Card>
          )}
        </Stack>
      </Card>
    </Stack>
  );
};

export default UserInfoCard;
