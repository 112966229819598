import React, { useContext } from "react";
import { List, ListItem, Stack, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { DiscordSVG } from "../../assets/DiscordSVG";
import { basicTone0, basicTone700 } from "../../shared/styles/constants";

import { communityUrls } from "./header-bar-utils.utils";
import Grid from "@mui/material/Grid";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { MediumIconSVG } from "../../assets/icons/MediumIconSVG";

export const CommunityMobile = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <List
      sx={{
        padding: "0 60px",
      }}
    >
      <ListItem
        component="a"
        href={communityUrls.twitterURL}
        sx={{ color: isLightMode ? basicTone700 : basicTone0 }}
      >
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Grid container alignItems={"center"} gap={1}>
            <TwitterIcon />
            <Typography variant="body1" fontWeight={700} color={"secondary"}>
              Twitter
            </Typography>
          </Grid>
          <Typography variant="body1" color="info">
            Stay informed with the latest CollectivePad news and developments
          </Typography>
        </Stack>
      </ListItem>
      <ListItem component="a" href={communityUrls.discordURL}>
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Grid container alignItems={"center"} gap={1}>
            <DiscordSVG color={isLightMode ? basicTone700 : basicTone0} />
            <Typography variant="body1" fontWeight={700} color={"secondary"}>
              Discord
            </Typography>
          </Grid>
          <Typography variant="body1" color="info">
            Join the CollectivePad community and receive support in real-time
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        component="a"
        href={communityUrls.telegramURL}
        sx={{ color: isLightMode ? basicTone700 : basicTone0 }}
      >
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Grid container alignItems={"center"} gap={1}>
            <TelegramIcon />
            <Typography variant="body1" fontWeight={700} color={"secondary"}>
              Telegram
            </Typography>
          </Grid>
          <Typography variant="body1" color="info">
            Meet the CollectivePad community and enjoy live support
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        component="a"
        href={communityUrls.mediumURL}
        sx={{ color: basicTone0 }}
      >
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Grid container alignItems={"center"} gap={1}>
            <MediumIconSVG
              width={"24px"}
              color={isLightMode ? basicTone700 : basicTone0}
            />
            <Typography variant="body1" fontWeight={700} color={"secondary"}>
              Medium
            </Typography>
          </Grid>
          <Typography variant="body1" color="info">
            Stay informed with updates and gain industry knowledge with our
            informative content
          </Typography>
        </Stack>
      </ListItem>
    </List>
  );
};
