import { useEthers } from "@usedapp/core";
import { Web3Provider } from "@ethersproject/providers";

import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  CreateAccountValues,
  SignInValues,
} from "../../pages/RegisterPage/register-utils";
import {
  useAuthenticateUserMutation,
  useAuthenticateUserWalletMutation,
  useGetSaltMutation,
  useGetUserConfigQuery,
  useRegisterUserMutation,
  useRegisterUserWalletMutation,
} from "../../services/authenticationApi";
import { NotificationsContext } from "./notifications-provider";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export interface IAccountContext {
  account: string | undefined;
  setAccount: (acc: string | undefined, email?: string, token?: string) => void;
  handleDisconnect: (callBack?: (() => void) | undefined) => void;
  handleSignIn: (values: SignInValues, callBack?: () => void) => void;
  handleCreateAccount: (
    values: CreateAccountValues,
    callBack?: () => void
  ) => void;
  showUserExistsError: boolean;
  setShowUserExistsError: (val: boolean) => void;
  signInError: boolean;
  setSignInError: (val: boolean) => void;
  handleConnectToMetamask: () => void;
  resetState: () => void;
  onSign: (salt: string) => Promise<string | undefined>;
  isConnectToMetamaskDisabled: boolean;
  setIsConnectToMetamaskDisabled: (val: boolean) => void;
  showVerifyEmailForMetamask: boolean;
  setShowVerifyEmailForMetamask: (val: boolean) => void;
  isEmailVerifying: boolean;
  setEmailVerifying: (val: boolean) => void;
  userMetamaskChainId: number | undefined;
  setUserMetamaskChainId: (val: number | undefined) => void;
  isConnectedToMetamask: boolean;
  setIsConnectedToMetamask: (val: boolean) => void;
}

export const AccountContext = createContext<IAccountContext>({
  account: undefined,
  setAccount: (acc: string | undefined, email?: string, token?: string) => {},
  handleDisconnect: (callBack?: (() => void) | undefined) => {},
  handleSignIn: (values: SignInValues, callBack?: () => void) => {},
  handleCreateAccount: (
    values: CreateAccountValues,
    callBack?: () => void
  ) => {},
  showUserExistsError: false,
  setShowUserExistsError: (val: boolean) => {},
  signInError: false,
  setSignInError: (val: boolean) => {},
  handleConnectToMetamask: () => {},
  resetState: () => {},
  onSign: async (salt: string) => undefined,
  isConnectToMetamaskDisabled: false,
  setIsConnectToMetamaskDisabled: (val: boolean) => {},
  showVerifyEmailForMetamask: false,
  setShowVerifyEmailForMetamask: (val: boolean) => {},
  isEmailVerifying: false,
  setEmailVerifying: (val: boolean) => {},
  userMetamaskChainId: undefined,
  setUserMetamaskChainId: (val: number | undefined) => {},
  isConnectedToMetamask: false,
  setIsConnectedToMetamask: (val: boolean) => {},
});

export const AccountProvider: FC<{ children?: React.ReactNode }> = (props) => {
  const [isNewConnection, setIsNewConnection] = useState<boolean>(false);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [showUserExistsError, setShowUserExistsError] = useState(false);
  const [signInError, setSignInError] = useState(false);
  const [isConnectToMetamaskDisabled, setIsConnectToMetamaskDisabled] =
    useState(false);
  const [showVerifyEmailForMetamask, setShowVerifyEmailForMetamask] =
    useState<boolean>(false);
  const [isEmailVerifying, setEmailVerifying] = useState(false);
  const userToken = localStorage.getItem("token");

  const { setNotification } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const {
    account: metamaskAccount,
    deactivate,
    activateBrowserWallet,
    library,
    chainId,
  } = useEthers();
  const [isConnectedToMetamask, setIsConnectedToMetamask] = useState(false);
  const [userMetamaskChainId, setUserMetamaskChainId] = useState<
    number | undefined
  >(chainId);

  useEffect(() => {
    if (chainId) {
      setUserMetamaskChainId(chainId);
    }
  }, [chainId]);

  // *********

  const { data: userConfigData, isSuccess: userConfigSuccess } =
    useGetUserConfigQuery(userToken);

  const [authenticateUser] = useAuthenticateUserMutation();
  const [registerUser] = useRegisterUserMutation();

  const [authenticateUserWallet] = useAuthenticateUserWalletMutation();
  const [registerUserWallet] = useRegisterUserWalletMutation();
  const [getSalt] = useGetSaltMutation();

  useEffect(() => {
    if (userConfigSuccess && userConfigData) {
      if (userConfigData.data.emailValidated) {
        localStorage.setItem("emailValidated", "yes");
      } else {
        localStorage.removeItem("emailValidated");
      }
      if (userConfigData.data.kyc) {
        localStorage.setItem("kyc", "yes");
      } else {
        localStorage.removeItem("kyc");
      }
      if (userConfigData.data.kycStatus) {
        localStorage.setItem("kycStatus", userConfigData.data.kycStatus);
      } else {
        localStorage.removeItem("kycStatus");
      }
    }
  }, [userConfigSuccess, userConfigData]);

  const handleConnectToMetamask = async () => {
    setIsNewConnection(true);
    const currentUserAgent = window.navigator.userAgent;
    const isMetamaskMobile =
      currentUserAgent.includes("MetaMaskMobile") && isMobile;

    if (isMetamaskMobile || !isMobile) {
      activateBrowserWallet();
    } else {
      window.open(
        "https://metamask.app.link/dapp/www.app.dev.collectivepad.com/register"
      );
    }
  };

  const onSign = async (salt: string) => {
    const provider = library as Web3Provider;
    try {
      const signedMsg = await provider?.getSigner().signMessage(salt);

      return signedMsg;
    } catch (error) {
      setIsConnectToMetamaskDisabled(false);
      deactivate();
      return undefined;
    }
  };

  const authenticateWallet = (address: string, signature: string) => {
    authenticateUserWallet({ address, signature })
      .unwrap()
      .then((data: any) => {
        setIsConnectToMetamaskDisabled(false);
        if (data?.data.token) {
          const token = data?.data.token;
          const email = data?.data.email;
          const username = data?.data.username;
          const avatar = data?.data.avatar;

          if (data?.data.emailValidated === true) {
            localStorage.setItem("emailValidated", "yes");
          }
          setAccount(address, email, token, avatar, username);
          setIsConnectedToMetamask(true);
          localStorage.setItem("isMetamaskConnected", "true");
          if (!data?.data.emailValidated) {
            setShowVerifyEmailForMetamask(true);
          }
          if (isNewConnection && data?.data.emailValidated) {
            setIsNewConnection(false);
            navigate(-1);
          }
        }
      });
  };

  const handleAuthenticateUserWallet = (userWalletAccount: string) => {
    getSalt({ address: userWalletAccount })
      .unwrap()
      .then(async (res: any) => {
        const salt = res?.data.salt;
        const signature = await onSign(salt);
        if (signature) {
          authenticateWallet(userWalletAccount, signature);
        } else {
          handleDisconnect();
        }
      })
      .catch((er: any) => {
        registerUserWallet({ address: userWalletAccount })
          .unwrap()
          .then(async (res: any) => {
            const salt = res?.data.salt;
            const signature = await onSign(salt);
            if (signature) {
              authenticateWallet(userWalletAccount, signature);
            } else {
              setIsConnectToMetamaskDisabled(false);
              handleDisconnect();
            }
          })
          .catch((error: any) => {
            deactivate();
            setIsConnectedToMetamask(false);
            localStorage.removeItem("isMetamaskConnected");
            setIsConnectToMetamaskDisabled(false);
            setNotification({
              type: "error",
              message: "Something went wrong.",
            });
          });
      });
  };

  useEffect(() => {
    if (metamaskAccount) {
      setIsConnectedToMetamask(true);
      localStorage.setItem("isMetamaskConnected", "true");
      if (metamaskAccount !== address) {
        handleAuthenticateUserWallet(metamaskAccount);
      }
    }
  }, [metamaskAccount]);

  const handleSignIn = (values: SignInValues, callBack?: () => void) => {
    const { email, password } = values;
    authenticateUser({ email, password })
      .unwrap()
      .then((data: any) => {
        const token = data?.data.token;
        const account = data?.data.address;
        const email = data?.data.email;
        const avatar = data?.data.avatar;
        const username = data?.data.username;

        if (data?.data.emailValidated) {
          localStorage.setItem("emailValidated", "yes");
        }
        setAccount(account, email, token, avatar, username);
        callBack && callBack();
      })
      .catch((er: any) => {
        setSignInError(true);
      });
  };

  const handleCreateAccount = (
    values: CreateAccountValues,
    callBack?: () => void
  ) => {
    const { email, password, username } = values;
    registerUser({ email, password, username })
      .unwrap()
      .then((data: any) => {
        const token = data?.data?.token;
        const account = data?.data?.address;
        const avatar = data?.data?.avatar;
        const username = data?.data?.username;
        callBack && callBack();
        setAccount(account, email, token, avatar, username);
      })
      .catch((er: any) => {
        setShowUserExistsError(true);
      });
  };

  // ********************
  useEffect(() => {
    const accountAddress = localStorage.getItem("accountAddress");
    const isMetamaskConnected = localStorage.getItem("isMetamaskConnected");
    if (accountAddress) {
      setAddress(accountAddress);
      if (isMetamaskConnected) {
        setIsConnectedToMetamask(true);
      }
    }
  }, []);

  const setAccount = (
    acc: string | undefined,
    email?: string,
    token?: string,
    avatar?: string | null,
    username?: string | null
  ) => {
    if (acc) {
      localStorage.setItem("accountAddress", acc);
      if (email) {
        localStorage.setItem("email", email);
      }
      if (token) {
        localStorage.setItem("token", token);
      }
      if (avatar) {
        localStorage.setItem("avatar", avatar);
      }
      if (username) {
        localStorage.setItem("username", username);
      }
    } else {
      localStorage.removeItem("accountAddress");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("emailValidated");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
    }
    setAddress(acc);
  };

  const handleDisconnect = (callBack?: (() => void) | undefined) => {
    if (
      metamaskAccount ||
      isConnectToMetamaskDisabled ||
      isConnectedToMetamask
    ) {
      deactivate();
    }
    setIsConnectedToMetamask(false);
    localStorage.removeItem("isMetamaskConnected");
    setAccount(undefined);
    setEmailVerifying(false);
    setShowVerifyEmailForMetamask(false);
    setIsConnectToMetamaskDisabled(false);
    setUserMetamaskChainId(undefined);
    resetState();
    callBack && callBack();
  };

  const resetState = () => {
    setShowUserExistsError(false);
    setSignInError(false);
  };

  return (
    <AccountContext.Provider
      value={{
        account: address,
        setAccount,
        handleDisconnect,
        handleSignIn,
        handleCreateAccount,
        showUserExistsError,
        setShowUserExistsError: (val: boolean) => setShowUserExistsError(val),
        signInError,
        setSignInError: (val: boolean) => setSignInError(val),
        handleConnectToMetamask,
        resetState,
        onSign,
        isConnectToMetamaskDisabled,
        setIsConnectToMetamaskDisabled: (val: boolean) =>
          setIsConnectToMetamaskDisabled(val),
        showVerifyEmailForMetamask,
        setShowVerifyEmailForMetamask: (val: boolean) =>
          setShowVerifyEmailForMetamask(val),
        isEmailVerifying,
        setEmailVerifying: (val: boolean) => setEmailVerifying(val),
        userMetamaskChainId,
        setUserMetamaskChainId: (val: number | undefined) =>
          setUserMetamaskChainId(val),
        isConnectedToMetamask,
        setIsConnectedToMetamask: (val: boolean) =>
          setIsConnectedToMetamask(val),
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
