import { Avatar, Card, Grid, Stack, Typography } from "@mui/material";

import React, { useContext } from "react";
import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { WalletContext } from "../../shared/providers/wallet-provider";
import {
  basicTone700,
  mainLinearGradient,
} from "../../shared/styles/constants";
import { formatAddress } from "../../shared/utils";
import { BalanceSection } from "./BalanceSection/BalanceSection";
import AvatarDefault from "../../assets/Avatar.jpg";

export const WalletInfoCard = () => {
  const { walletInfo, usdcInfoByChain } = useContext(WalletContext);
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("username");
  const avatar = localStorage.getItem("avatar");

  return (
    <Stack gap={1}>
      <Card sx={{ padding: { xs: "24px", md: "40px" } }}>
        <Grid container gap={2} alignItems="center">
          <Avatar
            src={avatar !== "null" ? avatar || undefined : AvatarDefault}
            alt="userAvatar"
            sx={{ width: "64px", height: "64px" }}
            variant="rounded"
          />
          <Grid flexDirection={"column"}>
            {userName !== "null" ? (
              <Typography variant="h4" color="secondary">
                {userName}
              </Typography>
            ) : null}
            {email && email.length && email !== "null" ? (
              <Typography
                variant="body1"
                color="default"
                fontWeight={700}
                sx={{
                  background: mainLinearGradient,
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {email}
              </Typography>
            ) : null}
            <Typography
              variant="body1"
              color="default"
              fontWeight={700}
              sx={{
                background: mainLinearGradient,
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {formatAddress(account)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card
        sx={{
          padding: { xs: "24px", md: "40px" },
          backgroundColor: isLightMode ? undefined : basicTone700,
        }}
      >
        {walletInfo && usdcInfoByChain && (
          <BalanceSection
            walletInfo={walletInfo}
            usdcInfoByChain={usdcInfoByChain}
          />
        )}
        {isConnectedToMetamask && !usdcInfoByChain && (
          <Stack gap={1}>
            <Typography variant="h5" color="secondary">
              Unsupported chain!
            </Typography>
            <Typography>
              Please switch network to be able to see wallet info.
            </Typography>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

export default WalletInfoCard;
