import * as env from "env-var";

export const config = {
  BASE_URL: env.get("REACT_APP_BASE_URL").required().asString(),
  LANDING_PAGE_URL: env.get("REACT_APP_LANDING_URL").required().asString(),
  DEV_MODE:
    env.get("REACT_APP_SHOW_TESTNET").required().asString() === "true",
  ENV_URL: env.get("REACT_APP_ENV_URL").required().asString(),
  CLIENT_ID: env.get("REACT_APP_CLIENT_ID").required().asString(),
};
