import {
  Box,
  Button,
  Card,
  Dialog,
  Stack,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  basicTone0,
  basicTone50,
  basicTone500,
  basicTone600,
  basicTone800,
  dropShadowBG,
  dropShadowSmWhiteBg,
  mainLinearGradient,
  secondaryColor1,
} from "../../shared/styles/constants";
import Logo from "../../logo";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import Logo3Dimg from "../../assets/Logo3DimgSmall.png";

import { CheckedColorIconSVG } from "../../assets/icons/CheckedColorIconSVG";
import { useValidateEmailMutation } from "../../services/authenticationApi";

export const VerifyEmailPage = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [searchParams] = useSearchParams();

  const validateToken = searchParams.get("token");
  const userId = searchParams.get("userId");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(false);

  const [validateEmail] = useValidateEmailMutation();

  useEffect(() => {
    validateEmail({ userId, validateToken })
      .unwrap()
      .then((data: any) => {
        if (data.result === "success") {
          localStorage.setItem("emailValidated", "yes");

          setIsSuccess(true);
        } else setError(true);
      })
      .catch(() => {
        setError(true);
      });
  }, []);
  const { isLightMode } = useContext(ThemeModeContext);

  const handleClose = () => navigate("/");

  return isSuccess || isError ? (
    <Dialog
      open
      fullScreen
      onClose={handleClose}
      sx={{ overflow: isMobile ? "auto" : "hidden" }}
      PaperProps={{
        sx: { backgroundColor: isLightMode ? basicTone50 : basicTone800 },
      }}
    >
      <Grid
        justifyContent={"center"}
        display="flex"
        flexDirection={"column"}
        padding={2}
        sx={{
          margin: "0 auto",
          height: isMobile ? "auto" : "100%",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Logo width={isMobile ? 140 : undefined} />
          <IconButton
            edge="start"
            color="secondary"
            onClick={handleClose}
            aria-label="close"
          >
            <CancelRoundedIcon color="info" />
          </IconButton>
        </Grid>
        <Grid
          container
          paddingRight={{ xs: 0, md: 10 }}
          marginTop={3}
          marginBottom={2}
          paddingLeft={{ xs: 0, md: 10 }}
          gap={2}
        >
          <Card
            sx={{
              boxShadow: isLightMode ? dropShadowSmWhiteBg : dropShadowBG,
              backgroundColor: isLightMode ? basicTone0 : basicTone600,
              padding: "32px 48px",
              borderRadius: "20px",
              minHeight: "584px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              minWidth: isMobile ? "0" : "450px",
              maxWidth: isMobile ? "max-content" : "480px",
            }}
          >
            <Grid
              container
              alignItems="center"
              height="100%"
              justifyContent={"center"}
            >
              <Stack gap={3} alignItems="center">
                {isSuccess && <CheckedColorIconSVG />}
                <Typography variant="h2" textAlign={"center"} color="secondary">
                  {isSuccess
                    ? `Nice to meet you! `
                    : isError
                    ? "Sorry, something went wrong!"
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  textAlign={"center"}
                  color="secondary"
                >
                  {isSuccess
                    ? `Your account has been successfully created.`
                    : isError
                    ? `Your e-mail couldn't be validated`
                    : ""}
                </Typography>
              </Stack>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/")}
              >
                Go to dashboard
              </Button>
            </Grid>
          </Card>
          <Box
            sx={{ position: "relative", display: { xs: "none", md: "block" } }}
          >
            <Card
              sx={{
                width: "291px",
                height: "584px",
                background: mainLinearGradient,
                borderRadius: "20px",
              }}
            />
            <img
              src={Logo3Dimg}
              alt="logoimg"
              className="logo3D"
              style={{ position: "absolute", top: "50px", left: "15px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  ) : null;
};

export default VerifyEmailPage;
