import { useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { LoadingButton } from "@mui/lab";

import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useSetNotificationEmailMutation } from "../../services/authenticationApi";
import { useNavigate } from "react-router-dom";
import { basicTone450 } from "../../shared/styles/constants";
import { AccountContext } from "../../shared/providers/account-provider";
import { verifyEmailForMetamaskValidationSchema } from "./Authentication.utils";

type VerifyEmailValues = {
  email: string | undefined;
  confirmationEmail: string | undefined;
};

export const EmailVerifyMetamaskContent = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const navigate = useNavigate();
  const [setNotificationEmail] = useSetNotificationEmailMutation();
  const { setEmailVerifying } = useContext(AccountContext);

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  const handleSetValidationEmail = (values: VerifyEmailValues) => {
    const token = localStorage.getItem("token");
    setNotificationEmail({ token, notificationEmail: values.email })
      .unwrap()
      .then(() => {
        setEmailSent(true);
        setEmailVerifying(true);
      })
      .catch(() => {
        setEmailAlreadyExists(true);
      });
  };
  return (
    <Stack justifyContent={"space-between"} height="100%">
      <Stack gap={6}>
        <Stack gap={1}>
          <Typography variant="h2" color="secondary">
            Verify your e-mail
          </Typography>
          <Typography variant="body1">Let's be one step closer</Typography>
        </Stack>
        <Formik
          initialValues={{ email: undefined, confirmationEmail: undefined }}
          onSubmit={(values: VerifyEmailValues, { setSubmitting }) => {
            handleSetValidationEmail(values);
          }}
          validationSchema={verifyEmailForMetamaskValidationSchema}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            errors,
            dirty,
            handleBlur,
            touched,
            handleSubmit,
            setSubmitting,
          }) => {
            return (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    color="primary"
                    name="email"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setSubmitting(false);
                      setEmailAlreadyExists(false);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    required
                    placeholder="Enter your e-mail"
                    error={!!errors.email && !!touched.email}
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon
                          fontSize="small"
                          color={values.email?.length ? "secondary" : "info"}
                        />
                      </InputAdornment>
                    }
                  />
                  {errors?.email && touched?.email ? (
                    <FormHelperText error>
                      *Please enter a valid e-mail.
                    </FormHelperText>
                  ) : null}
                  {emailAlreadyExists ? (
                    <FormHelperText error>
                      *There is already an account with this e-mail.
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    color="primary"
                    name="confirmationEmail"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setSubmitting(false);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    required
                    placeholder="Confirm your e-mail"
                    error={
                      !!errors.confirmationEmail && !!touched.confirmationEmail
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon
                          fontSize="small"
                          color={values.email?.length ? "secondary" : "info"}
                        />
                      </InputAdornment>
                    }
                  />
                  {errors?.confirmationEmail && touched?.confirmationEmail ? (
                    <FormHelperText error>
                      *E-mails do not match.
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <LoadingButton
                  color="primary"
                  type={"submit"}
                  onClick={() => handleSubmit()}
                  disabled={
                    isSubmitting || !!Object.values(errors).length || emailSent
                  }
                  loading={emailSent}
                  variant="contained"
                  sx={{ height: "48px", marginTop: "32px" }}
                >
                  <span>Continue</span>
                </LoadingButton>
              </Form>
            );
          }}
        </Formik>
      </Stack>
      <Grid container justifyContent={"center"}>
        <Button
          sx={{
            border: `1px solid ${basicTone450}`,
            borderRadius: "200px",
            padding: "10px 30px",
          }}
          onClick={() => navigate("/")}
        >
          {"I'll do it later"}
        </Button>
      </Grid>
    </Stack>
  );
};

export default EmailVerifyMetamaskContent;
