import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { IFundingRound } from "../../../models/project.model";
import { useCall } from "@usedapp/core";
import { useContracts } from "../../../hooks";

export const TargetAchievement = (currentFundingRound: IFundingRound) => {
  const [stableCoinAmountRaised, setStableCoinAmountRaised] = useState(0);
  const { fundingContract } = useContracts({
    fundingContractAddress: currentFundingRound.fundingContractAddress,
  });

  const { value: stableCoinAmountRaisedValue } =
    useCall({
      contract: fundingContract,
      method: "stableCoinAmountRaised",
      args: [],
    }) ?? {};

  useEffect(() => {
    if (stableCoinAmountRaisedValue !== undefined) {
      setStableCoinAmountRaised(stableCoinAmountRaisedValue?.[0].toString());
    }
  }, [stableCoinAmountRaisedValue]);

  return (
    <Typography variant="h5" color={"secondary"} fontWeight={700}>
      {`$${stableCoinAmountRaised}/$${currentFundingRound.dollarTargetAmount}`}
    </Typography>
  );
};

export default TargetAchievement;
