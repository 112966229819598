import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import DepositTabContent from "./DepositTabContent";
import WithdrawTabContent from "./WithdrawTabContent";

type PartnerDepositTabsProps = {
  fundingContract: any;
  tokenForSaleAddress: string;
  fundingContractAddress: string;
};

export const PartnerDepositTabs = (props: PartnerDepositTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        variant="fullWidth"
        onChange={handleChangeTab}
        aria-label="DepositSection"
        value={selectedTab}
      >
        <Tab label={`Deposit`} />
        <Tab label={`Withdraw`} />
      </Tabs>

      {selectedTab === 0 && <DepositTabContent {...props} />}
      {selectedTab === 1 && <WithdrawTabContent {...props} />}
    </>
  );
};

export default PartnerDepositTabs;
