import React, { useContext, useEffect } from "react";
import { Select, Divider, MenuItem } from "@mui/material";
import BigNumber from "bignumber.js";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SupportedToken } from "../types";
import { CurrencyMenuItem } from "../WithdrawSteps/CurrencyMenuItem";
import { SelectChangeEvent } from "@mui/material/Select";

import { useContracts } from "../../../hooks";
import { useCall } from "@usedapp/core";
import { AccountContext } from "../../../shared/providers/account-provider";

export type SelectSymbolProps = {
  symbol: string;
  selectedToken: SupportedToken;
  supportedTokens: SupportedToken[];

  setMaxValue: (maxVal: number) => void;
  onChange: (ev: SelectChangeEvent<any>) => void;
  getLogoBySymbol: (currency: string) => string | undefined;
};

export const SelectSymbolSection = ({
  symbol,
  selectedToken,
  supportedTokens,
  onChange,
  setMaxValue,
  getLogoBySymbol,
}: SelectSymbolProps) => {
  const { account } = useContext(AccountContext);

  const { stableCoinContract } = useContracts({
    stableCoinAddress: selectedToken.address,
  });

  const { value: balanceOf } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOf) {
      if (symbol === "USDC") {
        const val = new BigNumber(balanceOf.toString())
          .dividedBy(1e6)
          .toString();

        setMaxValue(parseFloat(val));
      } else {
        const val = new BigNumber(balanceOf.toString())
          .dividedBy(1e18)
          .decimalPlaces(2, 1)
          .toString();

        setMaxValue(parseFloat(val));
      }
    }
  }, [balanceOf]);

  return (
    <Select
      autoWidth
      value={symbol}
      onChange={onChange}
      variant="standard"
      IconComponent={() => null}
      sx={{
        border: "none",
        "&::before": { borderBottom: "none" },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: "none",
        },
        "& .MuiSelect-select": {
          backgroundColor: "transparent",
          display: "flex",
        },
      }}
      renderValue={(selected) => {
        return (
          <>
            <ArrowDropDownIcon color="secondary" />
            <img
              src={getLogoBySymbol(selected)}
              alt="logourl"
              style={{
                height: "24px",
                width: "24px",
                borderRadius: "50%",
              }}
            />
          </>
        );
      }}
    >
      {symbol && (
        <MenuItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "250px",
            padding: "12px 20px",
          }}
        >
          <CurrencyMenuItem supportedToken={selectedToken} selected />
        </MenuItem>
      )}
      <Divider />
      {supportedTokens.map((supportedToken) => (
        <MenuItem
          value={supportedToken.symbol}
          key={supportedToken.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "240px",
            padding: "12px 20px",
          }}
        >
          <CurrencyMenuItem supportedToken={supportedToken} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectSymbolSection;
