import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {
  Button,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import BigNumber from "bignumber.js";
import LoadingButton from "@mui/lab/LoadingButton";

import { ethers } from "ethers";
import { useState, useEffect, useContext } from "react";

import { useCall, useContractFunction } from "@usedapp/core";

import { useContracts } from "../../../../hooks";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { useBuyTokensMutation } from "../../../../services/fundingFeaturedProjectsApi";
import { NotificationsContext } from "../../../../shared/providers/notifications-provider";
import { getDividedByChainId } from "../utils";

type WinnerCardProps = {
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  chainId: number;
};
export const WinnerCard = ({
  stableCoinAddress,
  maxAllocation = "0",
  fundingContractAddress,
  minAllocation = "0",
  chainId,
}: WinnerCardProps) => {
  const { stableCoinContract, fundingContract } = useContracts({
    stableCoinAddress,
    fundingContractAddress,
  });
  const { setNotification } = useContext(NotificationsContext);
  const [buyProxyTokens] = useBuyTokensMutation();
  const [isBuyDisabled, setIsBuyDisabled] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [allowanceValue, setAllowanceValue] = useState<BigNumber | undefined>(
    undefined
  );
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);

  const [balance, setBallance] = useState<string>("0");

  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, fundingContractAddress],
    }) ?? {};

  const { value: userBalances, error: userBalancesError } =
    useCall({
      contract: fundingContract,
      method: "userBalances",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());
      setAllowanceValue(val);
      // setAllowanceValue(!val.eq(new BigNumber(0)));
    }
  }, [allowance]);

  useEffect(() => {
    if (userBalances) {
      setTotalAmountPaid(userBalances.totalAmountPaid.toNumber());
    }
  }, [userBalances]);

  useEffect(() => {
    if (balanceOf) {
      const divideBy = getDividedByChainId(chainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(divideBy)
        .decimalPlaces(5)
        .toString();

      setBallance(val);
    }
  }, [balanceOf]);

  // APPROVE
  const { state: approveState, send: approve } = useContractFunction(
    stableCoinContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );
  // BUY TOKENS
  const { state: buyTokensState, send: buyTokens } = useContractFunction(
    fundingContract,
    "buyTokens",
    {
      transactionName: "Buy Tokens",
    }
  );

  useEffect(() => {
    if (approveState.status === "Success") {
      setNotification({
        message: "Successfully approved!",
        type: "success",
      });
    } else if (
      approveState.status === "Fail" ||
      approveState.status === "Exception"
    ) {
      setNotification({
        message: "Approve failed!",
        type: "error",
      });
    }
  }, [approveState]);

  useEffect(() => {
    if (buyTokensState.status === "Success") {
      setNotification({
        message: "You bought tokens successfully!",
        type: "success",
      });
      setStakeAmount("");
    } else if (
      buyTokensState.status === "Fail" ||
      buyTokensState.status === "Exception"
    ) {
      setNotification({
        message: "Buying tokens failed!",
        type: "error",
      });
    }
  }, [buyTokensState]);

  const handleApprove = () => {
    approve(fundingContractAddress, ethers.constants.MaxUint256);
  };

  const handleBuyTokens = () => {
    const amount = stakeAmount ? parseInt(stakeAmount) : 0;
    const maxLimit = parseInt(maxAllocation);
    const minLimit = parseInt(minAllocation);

    if (
      amount &&
      amount > 0 &&
      amount + totalAmountPaid <= maxLimit &&
      amount + totalAmountPaid >= minLimit
    ) {
      if (isConnectedToMetamask) {
        buyTokens(amount);
      } else {
        const token = localStorage.getItem("token");
        setIsBuyDisabled(true);
        setStakeAmount("");
        buyProxyTokens({
          contractAddress: fundingContractAddress,
          stableCoinAddress,
          amount: stakeAmount,
          token,
        })
          .unwrap()
          .then((data: any) => {
            if (data?.data.receipt.status === 1) {
              setNotification({
                message: "You bought tokens successfully!",
                type: "success",
              });
            } else {
              setNotification({
                message: "Buying tokens failed!",
                type: "error",
              });
            }
            setIsBuyDisabled(false);
          })
          .catch((err: any) => {
            setNotification({
              message: "Buying tokens failed!",
              type: "error",
            });
            setIsBuyDisabled(false);
          });
      }
    }
  };

  const showBuy = () => {
    const isStakeAmountApproved = allowanceValue
      ?.dividedBy(getDividedByChainId(chainId))
      .isGreaterThanOrEqualTo(stakeAmount);
    return (
      !isConnectedToMetamask ||
      isStakeAmountApproved ||
      (allowanceValue && !allowanceValue.eq(new BigNumber(0)))
    );
  };

  return (
    <Grid container justifyContent={"center"} padding={4} gap={1}>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" color="secondary">
            {`Bought amount: ${totalAmountPaid}`}
          </Typography>
          <Typography variant="body2" color="secondary">
            {`Stake amount (min: ${minAllocation}, max: ${maxAllocation})`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="secondary">
            {` Balance: ${balance || 0}`}
          </Typography>
        </Grid>
      </Grid>
      <FormControl variant="outlined" fullWidth>
        <FilledInput
          id="outlined-adornment-weight"
          color="secondary"
          // type=""
          placeholder="0.0"
          value={stakeAmount}
          onChange={(ev: any) =>
            (ev.target.value === "" || !isNaN(parseInt(ev.target.value))) &&
            setStakeAmount(ev.target.value)
          }
          disabled={isBuyDisabled || buyTokensState.status === "Mining"}
          endAdornment={
            <InputAdornment position="start">
              <Button
                color="primary"
                onClick={() => setStakeAmount(maxAllocation)}
                variant="contained"
                disabled={isBuyDisabled || buyTokensState.status === "Mining"}
                sx={{
                  filter: "none",
                  padding: "3px 15px",
                  borderRadius: "10px",
                  width: "auto",
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                MAX
              </Button>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "balance",

            style: { padding: "10px" },
          }}
        />
      </FormControl>
      <Grid item sx={{ padding: "32px 0", width: "100%" }}>
        {showBuy() ? (
          <>
            <LoadingButton
              fullWidth
              variant="contained"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={() => handleBuyTokens()}
              sx={{ height: "48px" }}
              loading={isBuyDisabled || buyTokensState.status === "Mining"}
              loadingPosition="start"
              disabled={totalAmountPaid === parseFloat(maxAllocation)}
            >
              <span>BUY</span>
            </LoadingButton>
            {totalAmountPaid === parseFloat(maxAllocation) ? (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ marginTop: "8px" }}
                textAlign={"center"}
              >
                *You have reached the maximum amount allocated.
              </Typography>
            ) : null}
          </>
        ) : (
          <LoadingButton
            fullWidth
            variant="contained"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => handleApprove()}
            sx={{ height: "48px" }}
            loading={approveState.status === "Mining"}
            loadingPosition="start"
          >
            APPROVE
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
