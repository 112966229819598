import {
  Card,
  Divider,
  Grid,
  Theme,
  useMediaQuery,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import {
  basicTone0,
  basicTone400,
  basicTone450,
  basicTone700,
  dropShadowBG,
  dropShadowSmWhiteBg,
} from "../../shared/styles/constants";
import CreateAccount from "./CreateAccount";
import SignIn from "./SignIn";

import ConnectToMetamask from "./ConnectToMetamak";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { MailColorIconSVG } from "../../assets/icons/MailColorIconSVG";
import { AccountContext } from "../../shared/providers/account-provider";
import AlreadySignedIn from "./AlreadySignedIn";
import { useSendValidationEmailMutation } from "../../services/authenticationApi";
import EmailVerifyMetamaskContent from "./VerifyEmailForMetamaskAccounts";
import { useNavigate } from "react-router-dom";
import { config } from "../../config/config";

type RegisterCardProps = {
  isNewUser: boolean;
  handleClose: () => void;
  onCreateAccount?: () => void;
};

const FormContent = ({ isNewUser, onCreateAccount }: RegisterCardProps) => {

  const renderRegistrationContent = () => (
    isNewUser ? (
        <CreateAccount onCreateAccount={onCreateAccount} />
      ) : (
        <SignIn />
      )
  )
  return (
    <>
    {!config.DEV_MODE ? (  
      <Typography
        variant="h2"
        color="secondary"
        textAlign={"center"}
        sx={{ paddingBottom: "40px" }}
      >
        Connect your wallet
      </Typography>): null}

      {config.DEV_MODE ? renderRegistrationContent() : null}


      <Grid>

        {config.DEV_MODE ? (<Divider
          sx={{
            color: basicTone400,
            fontSize: "12px",
            "&:after": {
              borderTop: `thin solid ${basicTone450}`,
            },
            "&:before": {
              borderTop: `thin solid ${basicTone450}`,
            },
          }}
        >
          OR
        </Divider>) : null}

        <ConnectToMetamask />
      </Grid>
    </>
  );
};

const EmailVerifyContent = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { isConnectedToMetamask } = useContext(AccountContext);
  const [sendValidationEmail] = useSendValidationEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  const handleReSendValidationEmail = () => {
    const token = localStorage.getItem("token");
    sendValidationEmail({ token })
      .unwrap()
      .then(() => {
        setEmailSent(true);
      });
  };
  return (
    <>
      <Grid container alignItems="center" height="100%">
        <Stack gap={3} alignItems="center">
          <MailColorIconSVG />
          <Typography
            variant="h2"
            textAlign={"center"}
            color="secondary"
          >{`We’ve sent a confirmation link to your email. Please check your inbox.`}</Typography>
        </Stack>
      </Grid>
      <Grid container justifyContent={"center"}>
        {isConnectedToMetamask ? (
          <Button
            sx={{
              border: `1px solid ${basicTone450}`,
              borderRadius: "200px",
              padding: "10px 30px",
            }}
            onClick={() => navigate("/")}
          >
            {"I'll do it later"}
          </Button>
        ) : (
          <Button onClick={handleReSendValidationEmail} disabled={emailSent}>
            {emailSent
              ? `Try again in 60 seconds...`
              : `I haven't received an e-mail`}
          </Button>
        )}
      </Grid>
    </>
  );
};

export const RegisterCard = (props: RegisterCardProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const {
    account,
    showVerifyEmailForMetamask,
    isEmailVerifying,
    setEmailVerifying,
  } = useContext(AccountContext);

  const { isLightMode } = useContext(ThemeModeContext);

  return account && !isEmailVerifying && !showVerifyEmailForMetamask ? (
    <AlreadySignedIn />
  ) : (
    <Card
      sx={{
        boxShadow: isLightMode ? dropShadowSmWhiteBg : dropShadowBG,
        background: isLightMode ? basicTone0 : basicTone700,
        padding: "32px 48px",
        borderRadius: "20px",
        minHeight: "584px",
        display: "flex",
        justifyContent:
          isEmailVerifying || showVerifyEmailForMetamask || config.DEV_MODE
            ? "space-between"
            : "center", // keep only "space-between" when registering is available
        flexDirection: "column",
        minWidth: isMobile ? "0" : "450px",
        maxWidth: isMobile ? "max-content" : "480px",
      }}
    >
      {isEmailVerifying ? (
        <EmailVerifyContent />
      ) : showVerifyEmailForMetamask ? (
        <EmailVerifyMetamaskContent />
      ) : (
        <FormContent
          {...props}
          onCreateAccount={() => setEmailVerifying(true)}
        />
      )}
    </Card>
  );
};

export default RegisterCard;
