import { useContext } from "react";
import {
  Card,
  Grid,
  Stack,
  Typography,
  Link as MaterialLink,
  useMediaQuery,
  Theme,
  keyframes,
} from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/X";
import {
  basicTone0,
  basicTone200,
  basicTone700,
  footerLinearGradient,
} from "../../shared/styles/constants";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import Logo from "../../logo";
import { DiscordSVG } from "../../assets/DiscordSVG";
import { communityUrls } from "../AppHeaderBar/header-bar-utils.utils";
import { MediumIconSVG } from "../../assets/icons/MediumIconSVG";
import { Link } from "react-router-dom";

const footerGradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
`;

export const Footer = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Grid sx={{ position: "relative" }}>
      <Card
        sx={{
          background: footerLinearGradient,
          width: { xs: "95%", md: "90%" },
          margin: "0 auto",
          borderTopLeftRadius: "40px",
          borderTopRightRadius: "40px",
          padding: { xs: "29px 8px 0", md: "38px 50px 0" },
          overflow: "unset",
          borderWidth: 0,
          backgroundSize: "800% 800%",
          animation: `${footerGradientAnimation} 20s ease infinite`,
        }}
      >
        <Card
          sx={{
            background: isLightMode ? basicTone0 : basicTone700, //isLightMode ? lightLinearGradient : basicTone700,
            borderTopLeftRadius: "45px",
            borderTopRightRadius: "45px",
            padding: { xs: "30px 20px 50px", md: "60px 80px 40px" },
            position: "relative",
            overflow: "unset",
            borderWidth: 0,
            boxShadow: isLightMode
              ? "-6px 4px 40px rgb(102 98 156 / 30%)"
              : "-6px 4px 40px rgb(0 0 0 / 60%)",
          }}
        >
          <div
            style={{
              background: isLightMode ? basicTone0 : basicTone700, //isLightMode ? lightLinearGradient : basicTone700,
              clipPath: "polygon(50% 0,100% 100%,0 100%)",
              height: "calc(100% - 35px)",
              left: isMobile ? "-16px" : "-98px",
              position: "absolute",
              top: "35px",
              width: isMobile ? "39px" : "200px",
              display: "flex",
              boxShadow: isLightMode
                ? "0 4px 10px rgb(0 0 0 / 40%)"
                : "0 4px 10px rgb(0 0 0 / 40%)",
            }}
          />
          <div
            style={{
              background: isLightMode ? basicTone0 : basicTone700, //isLightMode ? lightLinearGradient : basicTone700,
              clipPath: "polygon(50% 0,100% 100%,0 100%)",
              height: "calc(100% - 35px)",
              left: "auto",
              right: isMobile ? "-16px" : "-99px",
              position: "absolute",
              top: "35px",
              width: isMobile ? "39px" : "200px",
              display: "flex",
              boxShadow: isLightMode
                ? "0 4px 10px rgb(0 0 0 / 40%)"
                : "0 4px 10px rgb(0 0 0 / 40%)",
            }}
          />
          <Grid>
            <Logo />
          </Grid>

          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            flexWrap={{ sm: "nowrap" }}
            gap={{ xs: 4, md: 0 }}
          >
            {/* left side */}
            <Grid item xs={12} sm={7}>
              <Typography variant={"h6"} color="secondary">
                We are a diverse team based in Europe, working together to make
                global investments easy for everyone. We offer both off-chain
                and on-chain options and are committed to a vision of a
                worldwide financial market.
              </Typography>
            </Grid>
            {/* right side */}
            <Grid
              item
              container
              xs={12}
              sm={5}
              justifyContent={{
                xs: "space-between",
                sm: "flex-end",
                md: "flex-end",
              }}
            >
              {/* <Stack gap={2}>
                <MaterialLink
                  href={"#"}
                  key={"resources"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px 0 0",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  Resources
                </MaterialLink>
                <MaterialLink
                  href={"#"}
                  key={"aboutUs"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px 0 0",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  About us
                </MaterialLink>
                <MaterialLink
                  href={"#"}
                  key={"additional"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px 0 0",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  Additional
                </MaterialLink>
                <MaterialLink
                  href={"#"}
                  key={"contact"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px 0 0",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  Contact
                </MaterialLink>
              </Stack> */}
              <Stack gap={2}>
                <MaterialLink
                  href={communityUrls.mediumURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={"medium"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  <MediumIconSVG
                    width={"24px"}
                    color={isLightMode ? basicTone700 : basicTone0}
                  />
                  Medium
                </MaterialLink>
                <MaterialLink
                  href={communityUrls.telegramURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={"telegram"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  <TelegramIcon />
                  Telegram
                </MaterialLink>
                <MaterialLink
                  href={communityUrls.discordURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={"discord"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  <DiscordSVG color={isLightMode ? basicTone700 : basicTone0} />
                  Discord
                </MaterialLink>
                <MaterialLink
                  href={communityUrls.twitterURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={"twitter"}
                  color="secondary"
                  underline="none"
                  fontWeight={700}
                  sx={{
                    padding: "0 18px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: isLightMode ? basicTone700 : basicTone0,
                  }}
                >
                  <TwitterIcon color="secondary" />
                  Twitter
                </MaterialLink>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            flexWrap={{ sm: "nowrap" }}
            paddingTop={9}
          >
            <Grid
              item
              xs={12}
              sm={6}
              container
              justifyContent={{ xs: "space-between", sm: "flex-start" }}
            >
              <Link
                to={`terms-and-conditions`}
                key="termsAndConditions"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  fontWeight={700}
                  sx={{
                    padding: { xs: "0 4px 0 0", sm: "0 18px" },
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Link>

              <Link
                to={`privacy-policy`}
                key={"privacyPolicy"}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  fontWeight={700}
                  sx={{
                    padding: { xs: "0 4px 0 0", sm: "0 18px" },
                  }}
                >
                  Privacy policy
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              justifyContent={{ xs: "center", sm: "flex-end" }}
            >
              <Typography
                variant="body2"
                color={basicTone200}
                sx={{ fontVariant: "small-caps" }}
              >
                Copyright © Collective Hub - 2024. All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </Grid>
  );
};
export default Footer;
