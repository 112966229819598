import { Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useCall } from "@usedapp/core";

import { useContracts } from "../../../../hooks";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { NotificationsContext } from "../../../../shared/providers/notifications-provider";
import { IChain } from "../../../../models/project.model";
import { WinnerCard } from "./WinnerCard";

type FundingCardProps = {
  projectChain: IChain;
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
};

export const FundingCard = ({
  maxAllocation,
  stableCoinAddress,
  fundingContractAddress,
  minAllocation,
  projectChain,
}: FundingCardProps) => {
  const { fundingContract } = useContracts({
    fundingContractAddress,
  });
  const [loadingIsUserWinner, setLoadingIsUserWinner] = useState(true);
  const [isWinner, setIsWinner] = useState<boolean>();
  const { setNotification } = useContext(NotificationsContext);

  const { account } = useContext(AccountContext);

  // const checkIfUserIsWinner = () => {
  //   const { value: isUserWinner, error: isUserWinnerError } =
  //     useCall({
  //       contract: fundingContract,
  //       method: "winnerAddresses",
  //       args: [account],
  //     }) ?? {};

  //   if (isUserWinnerError) {
  //     console.log("isUserWinnerError", isUserWinnerError);
  //   }
  //   if (isUserWinner !== undefined) {
  //     setIsWinner(isUserWinner[0]);
  //     setLoadingIsUserWinner(false);
  //     // **** Use this to mock your user for being a winner ***
  //     // setIsWinner(!isUserWinner[0]); // TO DO  remove !
  //   }
  // };
  // useEffect(() => {
  //   checkIfUserIsWinner();
  // }, []);

  const { value: isUserWinner, error: isUserWinnerError } =
    useCall({
      contract: fundingContract,
      method: "winnerAddresses",
      args: [account],
    }) ?? {};

  // **** Use this to mock your user for being a winner ***
  useEffect(() => {
    if (isUserWinner !== undefined) {
      setIsWinner(isUserWinner[0]);
      setLoadingIsUserWinner(false);
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinner]);

  useEffect(() => {
    if (isUserWinnerError !== undefined && loadingIsUserWinner) {
      setIsWinner(false);
      setLoadingIsUserWinner(false);
      setNotification({
        message: "An error occured. Please refresh the page!",
        type: "error",
      });
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinnerError]);

  return isWinner ? (
    <Card>
      <WinnerCard
        maxAllocation={maxAllocation}
        stableCoinAddress={stableCoinAddress}
        fundingContractAddress={fundingContractAddress}
        minAllocation={minAllocation}
        chainId={projectChain.chainId}
      />
    </Card>
  ) : (
    <Card>
      <Grid container justifyContent={"center"} padding={3}>
        {loadingIsUserWinner ? (
          <CircularProgress color="secondary" />
        ) : (
          <Typography variant="body1">
            You were not selected for this round.
          </Typography>
        )}
      </Grid>
    </Card>
  );
};

export default FundingCard;
