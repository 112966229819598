import {
  Button,
  List,
  ListItem,
  Grid,
  Stack,
  Theme,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BigNumber from "bignumber.js";

import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../../../shared/providers/account-provider";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import {
  basicTone100,
  basicTone200,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainBorderRadius,
  whiteTone,
} from "../../../shared/styles/constants";
import { TCoin } from "../types";
import { getChainNameById } from "../../../shared/utils";

export const LoggedInTable = ({ tokensList }: { tokensList: TCoin[] }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { account } = useContext(AccountContext);
  return (
    <Stack justifyContent={"space-between"}>
      <List>
        <ListItem sx={{ display: { xs: "none", sm: "flex" } }}>
          <Grid container>
            <Grid item sm={2}>
              <Typography variant="body2" color={basicTone200}>
                Symbol:
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="body2" color={basicTone200}>
                Name:
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="body2" color={basicTone200}>
                Your balance:
              </Typography>
            </Grid>

            <Grid item sm={2}>
              <Typography variant="body2" color={basicTone200}>
                Chain:
              </Typography>
            </Grid>
            <Grid item sm={4} justifyContent="flex-end" display={"flex"}>
              {/* <Typography variant="body2" color={basicTone200}>
                Actions:
              </Typography> */}
            </Grid>
          </Grid>
        </ListItem>
        <Stack gap={1}>
          {tokensList
            .sort(
              (a, b) =>
                parseInt(b.availableTokensForSale) -
                parseInt(a.availableTokensForSale)
            )
            .map((coin) => (
              <Link
                key={coin.internalId}
                to={`/marketplace/${coin.tokenAddress}/${coin.fundingTermsAddress}`}
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  sx={{
                    border: `1px solid ${
                      isLightMode ? basicTone100 : basicTone500
                    }`,
                    cursor: "pointer",
                    filter: isLightMode
                      ? "none"
                      : `drop-shadow(${dropShadowSM})`,
                    borderRadius: mainBorderRadius,
                    padding: "12px",
                    background: isLightMode ? whiteTone : basicTone600,
                  }}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item sm={2} gap={1} container alignItems={"center"}>
                      <Avatar
                        src={coin.logoUrl}
                        variant="circular"
                        sx={{ width: "32px", height: "32px" }}
                      />
                      <Typography variant="h6" color="secondary">
                        {coin.symbol}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="h6" color={basicTone200}>
                        {/* should be name here??? */}
                        {coin.symbol}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="h6" color="secondary">
                        {`$${coin.availableTokensForSale}`}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="h6" color={basicTone200}>
                        {getChainNameById(coin.chainId)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      justifyContent="flex-end"
                      display={"flex"}
                    >
                      {/* {account ? (
                        <Link to={`sell/${coin.internalId}`}>
                          <Button variant="text">Sell</Button>
                        </Link>
                      ) : null}
                      <Link to={`buy/${coin.internalId}`}>
                        <Button variant="text">Buy</Button>
                      </Link> */}
                    </Grid>
                  </Grid>
                </ListItem>
              </Link>
            ))}
        </Stack>
      </List>
    </Stack>
  );
};

export default LoggedInTable;
