import React, { useContext } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputBase, Paper } from "@mui/material";

import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone450,
  basicTone500,
  mainBorderRadius,
} from "../../../../shared/styles/constants";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";

type SearchProjectProps = {
  searchValue: string | undefined;
  onChange: (newVal: string) => void;
};

export const SearchProjects = ({
  searchValue,
  onChange,
}: SearchProjectProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <Grid item xs={12} sm={5} display="flex" justifyContent={"flex-end"}>
      <Paper
        component="form"
        sx={{
          padding: "3px 8px 3px 16px",
          display: "flex",
          alignItems: "center",
          width: { xs: "100%", md: 380 },
          height: "40px",
          borderRadius: mainBorderRadius,
          border: `1px solid ${isLightMode ? basicTone100 : basicTone500}`,
          boxShadow: "none",
        }}
      >
        <IconButton
          type="submit"
          sx={{ p: "0px" }}
          aria-label="search"
          color="primary"
        >
          <SearchIcon htmlColor={isLightMode ? basicTone100 : basicTone200} />
        </IconButton>

        <InputBase
          sx={{ ml: 1, flex: 1 }}
          color="primary"
          value={searchValue}
          onChange={(ev) => onChange(ev.target.value)}
          placeholder="Search"
          inputProps={{
            "aria-label": "search for project",
            sx: { color: isLightMode ? basicTone450 : basicTone0 },
          }}
          endAdornment={
            <IconButton sx={{ p: "0px" }} onClick={() => onChange("")}>
              <ClearIcon color="info" />
            </IconButton>
          }
        />
      </Paper>
    </Grid>
  );
};

export default SearchProjects;
