import { Button, Card, Grid, Stack, Typography, Link } from "@mui/material";
import React, { useContext, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate, useParams } from "react-router-dom";
import ProjectInfoTabs from "./sections/ProjectInfoTabs";
import ProjectProgress from "./sections/ProjectProgress";
import {
  useGetFeaturedProjectByIdQuery,
  useGetFeaturedProjectByUrlNameQuery,
  useGetUserIsSignedUpToCurrentRoundMutation,
} from "../../services/featuredProjectsApi";
import { IFeaturedProject } from "../../models/project.model";
import SignUp from "./sections/SignUp";

import {
  defaultPhases,
  mapPhasesStatuses,
  TRoundPhase,
} from "./sections/utils";
import FundingCard from "./sections/funding-card/FundingCard";
import {
  basicTone100,
  basicTone500,
  mainColor2,
} from "../../shared/styles/constants";
import { useCountdown } from "../../shared/hooks/useCountdown";
import { AccountContext } from "../../shared/providers/account-provider";
import TeamCard from "./sections/TeamCard";
import SocialMediaCard from "./sections/SocialMediaCard";
import DistributingCard from "./sections/DistributingCard";
import RoundSmallCard from "./sections/RoundSmallCard";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import SignUpEnded from "./sections/SignUpEnded";
import DifferentChainCard from "./sections/funding-card/DifferentChainCard";

const states = {
  funding: "funding",
  fundingReady: "fundingReady",
  signUp: "signUp",
  signUpEnded: "signUpEnded",
  waiting: "waiting",
  awaiting: "awaiting", //remove
  distributing: "distributing",
  closed: "closed",
};

const renderCardDependingOnProjectPhase = (
  account: string | undefined,
  isSignedUpToCurrentFundingRound: boolean,
  userMetamaskChainId: number | undefined,
  projectData?: IFeaturedProject
) => {
  const STABLE_COIN_ADDRESS =
    projectData?.currentFundingRound.stableCoinAddress;
  const FUNDING_CONTRACT_ADDRESS =
    projectData?.currentFundingRound.fundingContractAddress;
  const TOKEN_FOR_SALE_ADDRESS =
    projectData?.currentFundingRound.tokenForSaleAddress;
  const VESTING_CONTRACT_ADDRESS =
    projectData?.currentFundingRound.vestingContractAddress;

  switch (projectData?.currentFundingRound.status) {
    case states.funding: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.fundingChain.chainId;
      if (isDifferentChain) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.fundingChain}
          />
        );
      }
      return account ? (
        <FundingCard
          stableCoinAddress={STABLE_COIN_ADDRESS}
          fundingContractAddress={FUNDING_CONTRACT_ADDRESS}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.fundingChain}
        />
      ) : null;
    }
    case states.fundingReady: {
      return <SignUpEnded />;
    }
    case states.signUp: {
      return (
        <SignUp
          isAlreadySignedUp={isSignedUpToCurrentFundingRound}
          fundingRoundId={projectData?.currentFundingRoundId?.toString()}
          projectTitle={projectData?.name}
          socialMedia={{
            discordUrl: projectData?.discordUrl,
            twitterUrl: projectData?.twitterUrl,
            telegramUrl: projectData?.telegramURl,
          }}
        />
      );
    }
    case states.signUpEnded: {
      return <SignUpEnded />;
    }
    case states.distributing: {
      return account ? (
        <DistributingCard
          tokenForSaleAddress={TOKEN_FOR_SALE_ADDRESS}
          vestingContractAddress={VESTING_CONTRACT_ADDRESS}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.vestingChain}
        />
      ) : null;
    }
    default:
      return null;
  }
};

export const FeaturedProjectPage = () => {
  const navigate = useNavigate();
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, handleDisconnect, userMetamaskChainId } =
    useContext(AccountContext);
  const { projectUrl = "" } = useParams();
  const [projectData, setProjectData] = React.useState<IFeaturedProject>();
  const [roundPhases, setRoundPhases] =
    React.useState<TRoundPhase[]>(defaultPhases);
  const [isSignedUpToCurrentFundingRound, setIsSignedUpToCurrentFundingRound] =
    React.useState(false);

  const [getIsUserSignedUp] = useGetUserIsSignedUpToCurrentRoundMutation();

  const { data, isSuccess, error } =
    useGetFeaturedProjectByUrlNameQuery(projectUrl);

  useEffect(() => {
    if (isSuccess && data.data) {
      setProjectData(data.data as IFeaturedProject);
      setRoundPhases(mapPhasesStatuses(data.data.currentFundingRound.status));
    }
    if (error) {
      navigate("/404");
    }
  }, [isSuccess, error]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (
      account &&
      projectData?.currentFundingRoundId &&
      !isSignedUpToCurrentFundingRound &&
      !!token
    ) {
      getIsUserSignedUp({
        currentFundingRoundId: projectData?.currentFundingRoundId,
        token,
      })
        .unwrap()
        .then((data: any) => {
          if (data?.data.isSignedUp) {
            setIsSignedUpToCurrentFundingRound(true);
          }
        })
        .catch((err: any) => {
          if (err?.status === 403 && account) {
            handleDisconnect();
            navigate("/register");
          }
        });
    }
  }, [
    account,
    isSignedUpToCurrentFundingRound,
    token,
    projectData?.currentFundingRoundId,
    getIsUserSignedUp,
  ]);

  return (
    data && (
      <div className="Content">
        <Grid
          container
          paddingTop={1}
          paddingBottom={2}
          gap={1.5}
          flexDirection={"column"}
        >
          <Card
            sx={{
              padding: { xs: "24px", md: "20px 16px 20px 90px" },
            }}
          >
            <Grid
              container
              gap={2}
              justifyContent="space-between"
              flexWrap={{ md: "nowrap" }}
            >
              <Grid item xs={12} md={6} container alignItems={"center"} gap={7}>
                <Grid container alignItems={"flex-end"}>
                  {/* <img
                  src={projectData?.logoUrl}
                  alt="logourl"
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "8px",
                  }}
                /> */}
                  <HomeIcon color="secondary" />
                  <Typography
                    variant="body1"
                    lineHeight={1.1}
                    sx={{ marginLeft: "8px" }}
                  >
                    <Link
                      color="secondary"
                      href={projectData?.websiteUrl}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="body1"
                    >
                      {projectData?.websiteUrl}
                    </Link>
                  </Typography>
                </Grid>
                <Stack>
                  <Grid
                    item
                    display="flex"
                    alignItems={"flex-end"}
                    gap={1}
                    sx={{ marginBottom: "8px" }}
                  >
                    <Typography variant="h1" color="secondary">
                      {projectData?.name}
                    </Typography>
                    <Typography variant="h6" color={mainColor2}>
                      {projectData?.coinSymbol}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Typography
                      variant="h6"
                      color={isLightMode ? basicTone500 : basicTone100}
                    >
                      {projectData?.currentFundingRound.motto}
                    </Typography>
                  </Grid>
                </Stack>
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  justifyContent="flex-end"
                  display={{ xs: "flex", md: "none" }}
                >
                  <img
                    src={projectData?.coverUrl}
                    alt="banner"
                    style={{
                      width: "100%",
                      maxHeight: "318px",
                      objectFit: "cover",
                      borderRadius: "16px",
                    }}
                  />
                </Grid>
                <Grid item container gap={1}>
                  <Button
                    component="a"
                    href={projectData?.currentFundingRound.whitePaperUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                  >
                    Whitepaper
                  </Button>
                  <Button
                    component="a"
                    href={projectData?.currentFundingRound.onePagerUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="contained"
                    color="secondary"
                    sx={{ padding: "16px 24px", boxShadow: "none" }}
                  >
                    Gitbook
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                justifyContent="flex-end"
                display={{ xs: "none", md: "flex" }}
              >
                <img
                  src={projectData?.coverUrl}
                  alt="banner"
                  style={{
                    width: "100%",
                    maxHeight: "318px",
                    objectFit: "cover",
                    borderRadius: "16px",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          {projectData && <RoundSmallCard {...projectData} />}

          <Grid
            item
            container
            xs={12}
            gap={1.5}
            justifyContent={{ md: "flex-start" }}
            flexDirection={{ md: "row-reverse" }}
            flexWrap={{ md: "nowrap" }}
          >
            {/* right side cards */}
            <Grid item container xs={12} md={4} gap={2} flexDirection="column">
              <ProjectProgress phases={roundPhases} />
              {renderCardDependingOnProjectPhase(
                account,
                isSignedUpToCurrentFundingRound,
                userMetamaskChainId,
                projectData
              )}
              <TeamCard teamMembers={projectData?.teamMembers} />
              <SocialMediaCard
                socialMedia={{
                  twitterUrl: projectData?.twitterUrl,
                  discordUrl: projectData?.discordUrl,
                  telegramUrl: projectData?.telegramURl,
                  youtubeUrl: projectData?.youtubeUrl,
                  mediumUrl: projectData?.mediumUrl,
                }}
              />
            </Grid>
            {/* left side cards */}
            <Grid item xs={12} md={8}>
              {projectData && <ProjectInfoTabs {...projectData} />}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default FeaturedProjectPage;
