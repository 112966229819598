import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useContracts } from "../../hooks";

import PartnerDepositCard from "./PartnerDepositCard";

export const PartnerDepositPage = () => {
  const { contractAddress } = useParams();

  const { fundingContract } = useContracts({
    fundingContractAddress: contractAddress,
  });

  return (
    <div className="Content">
      <Grid container justifyContent={"center"}>
        {fundingContract && contractAddress ? (
          <PartnerDepositCard
            fundingContractAddress={contractAddress}
            fundingContract={fundingContract}
          />
        ) : null}
      </Grid>
    </div>
  );
};

export default PartnerDepositPage;
