import { Stack, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "../../shared/providers/account-provider";
import MarketplaceList from "./MarketplaceList";
import { useContracts } from "../../hooks";
import {
  MARKETPLACE_ADDRESS,
  MARKETPLACE_BSCTESTNET_ADDRESS,
  MARKETPLACE_MATIC_ADDRESS,
} from "../../config/MarketPlaceAbi";
import { useCalls } from "@usedapp/core";
import { TCoin } from "./types";
import {
  getChainIdByContractIndex,
  mapAvailableTokensForSaleList,
  mapSupportedTokensList,
} from "./Marketplace.utils";

export const MarketplacePage = () => {
  const [coinsList, setCoinsList] = useState<TCoin[]>([]);

  const { account, isConnectedToMetamask } = useContext(AccountContext);
  const navigate = useNavigate();

  const { marketplaceContract: marketplaceMaticContract } = useContracts({
    marketplaceContractAddress: MARKETPLACE_MATIC_ADDRESS, // MARKETPLACE_ADDRESS,
  });
  const { marketplaceContract: marketplaceBinanceTestNetContract } =
    useContracts({
      marketplaceContractAddress: MARKETPLACE_BSCTESTNET_ADDRESS,
    });
  // const { marketplaceContract: marketplaceBinanceMainNetContract } = useContracts({
  //   marketplaceContractAddress: MARKETPLACE_BSC_ADDRESS,
  // }); // to production

  const contracts = [
    marketplaceMaticContract,
    marketplaceBinanceTestNetContract,
  ]; // add this to production: marketplaceBinanceMainNetContract

  const listSupportedTokensCalls =
    contracts.map((contract) => ({
      contract,
      method: "listSupportedTokens",
      args: [],
    })) ?? [];

  const listAvailableTokensForSaleCalls =
    contracts.map((contract) => ({
      contract,
      method: "listAvailableTokensForSale",
      args: [account],
    })) ?? [];

  const listSupportedTokensResults = useCalls(listSupportedTokensCalls) ?? [];
  const listAvailableTokensForSaleResults =
    useCalls(listAvailableTokensForSaleCalls) ?? [];

  const setSupportedTokensResults = () => {
    if (listSupportedTokensResults) {
      let updatedCoinsList: TCoin[] = [];
      listSupportedTokensResults.forEach((result, index) => {
        if (result?.value) {
          updatedCoinsList = [
            ...updatedCoinsList,
            ...mapSupportedTokensList(
              result.value[0],
              getChainIdByContractIndex(index)
            ),
          ];
        }
      });

      setCoinsList(updatedCoinsList);
    }
  };

  const setAvailableTokensforSaleResults = () => {
    if (listAvailableTokensForSaleResults) {
      let updatedCoinsList: TCoin[] = [];
      listAvailableTokensForSaleResults.forEach((result, index) => {
        if (result?.value) {
          updatedCoinsList = [
            ...updatedCoinsList,
            ...mapAvailableTokensForSaleList(
              result.value[0],
              getChainIdByContractIndex(index)
            ),
          ];
        }
      });

      setCoinsList(updatedCoinsList);
    }
  };

  useEffect(() => {
    if (isConnectedToMetamask) {
      setAvailableTokensforSaleResults();
    } else {
      setSupportedTokensResults();
    }
  }, [
    isConnectedToMetamask,
    listAvailableTokensForSaleResults,
    listSupportedTokensResults,
  ]);

  return (
    <Stack
      gap={7}
      paddingTop={3}
      paddingBottom={8}
      paddingRight={1}
      paddingLeft={1}
    >
      <Stack gap={3}>
        <Typography variant="h1" color="secondary">
          Marketplace
        </Typography>
        <Typography variant="body1" color="secondary">
          Some info about marketplace.Some info about marketplace.Some info
          about marketplace.Some info about marketplace.Some info about
          marketplace.Some info about marketplace
        </Typography>
      </Stack>
      <MarketplaceList tokensList={coinsList} />
    </Stack>
  );
};

export default MarketplacePage;
