import React, { useState, useContext } from "react";
import { Card, Tabs, Tab } from "@mui/material";

import { basicTone700 } from "../../shared/styles/constants";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import DepositContent from "./DepositContent";
import WithdrawContent from "./WithdrawContent";
import WithdrawConfirmationCode from "./WithdrawSteps/WithdrawConfirmationCode";
import WithdrawSuccessfulSection from "./WithdrawSteps/WithdrawSuccessfulSection";
import ManageTabContent from "./ManageContent/ManageTabContent";
import { WalletContext } from "../../shared/providers/wallet-provider";
import { AccountContext } from "../../shared/providers/account-provider";

export const WalletActionsCard = () => {
  const { showConfirmationCodeSection, walletInfo, withdrawSuccessful } =
    useContext(WalletContext);
  const { isConnectedToMetamask } = useContext(AccountContext);
  const { isLightMode } = useContext(ThemeModeContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setSelectedTab(index);
  };
  return walletInfo ? (
    <Card
      sx={{
        padding: { xs: "24px", md: "40px", height: "100%" },
        background: isLightMode ? undefined : basicTone700,
        minHeight: "560px",
      }}
    >
      {isConnectedToMetamask ? (
        <>
          <Tabs
            onChange={handleChangeTab}
            aria-label="WalletActionsTabs"
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ marginTop: "16px" }}
            textColor="secondary"
          >
            <Tab label="Manage" />
          </Tabs>

          {selectedTab === 0 && <ManageTabContent />}
        </>
      ) : (
        <>
          <Tabs
            onChange={handleChangeTab}
            aria-label="WalletActionsTabs"
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ marginTop: "16px" }}
            textColor="secondary"
          >
            <Tab label={`Deposit`} />
            <Tab label={`Withdraw`} />
          </Tabs>

          {selectedTab === 0 && <DepositContent />}

          {selectedTab === 1 && (
            <>
              {!showConfirmationCodeSection ? (
                withdrawSuccessful ? (
                  <WithdrawSuccessfulSection />
                ) : (
                  <WithdrawContent />
                )
              ) : (
                <WithdrawConfirmationCode />
              )}
            </>
          )}
        </>
      )}
    </Card>
  ) : null;
};
export default WalletActionsCard;
