import React, { useState, useContext } from "react";
import { Grid, Typography, Stack, Box } from "@mui/material";
import StepLabel from "../../../components/CustomVerticalStepper/CustomVerticalStepLabel";
import StepContent from "../../../components/CustomVerticalStepper/CustomVerticalStepContent";
import {
  basicTone200,
  basicTone50,
  basicTone500,
  basicTone700,
  basicTone800,
  mainBorderRadius,
  mainColor1,
  whiteTone,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";

export const WalletSteps = () => {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <>
      <StepLabel label="Wallet address" />
      <StepContent>
        <Box
          style={{
            border: isLightMode
              ? `1px solid ${basicTone50}`
              : `1px solid ${basicTone500}`,
            padding: "24px 30px",
            background: isLightMode ? whiteTone : basicTone800,
            borderRadius: mainBorderRadius,
          }}
        >
          <Typography
            variant="h6"
            color="secondary"
            sx={{ wordWrap: "break-word" }}
          >
            {localStorage.getItem("accountAddress")}
          </Typography>
        </Box>
        <Typography variant="body2" color={basicTone200}>
          <span style={{ color: mainColor1, fontSize: "14px" }}>* </span>Deposit
          your tokens on the Matic blockchain using the provided address. Be
          careful to copy the address exactly. Deposits made to other addresses
          or blockchains will not be recognized and might be lost.
        </Typography>
      </StepContent>
    </>
  );
};

export default WalletSteps;
