import {
  Button,
  Card,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import {
  BSC,
  BSCTestnet,
  Polygon,
  useCall,
  useContractFunction,
  useEthers,
} from "@usedapp/core";
import React, { useState, useContext, useEffect } from "react";
import { useContracts } from "../../../hooks";
import { AccountContext } from "../../../shared/providers/account-provider";
import BigNumber from "bignumber.js";
import { useRedeemTokensMutation } from "../../../services/fundingFeaturedProjectsApi";
import { LoadingButton } from "@mui/lab";
import { NotificationsContext } from "../../../shared/providers/notifications-provider";
import { IChain } from "../../../models/project.model";

type WinnerCardProps = {
  tokenForSaleAddress?: string;
  vestingContractAddress?: string;
  tokenRedeemedAmount: string;
  fullVestedAmount: string;
};
export const WinnerCard = ({
  tokenForSaleAddress,
  vestingContractAddress,
  fullVestedAmount,
  tokenRedeemedAmount,
}: WinnerCardProps) => {
  const { stableCoinContract, vestingContract } = useContracts({
    stableCoinAddress: tokenForSaleAddress,
    vestingContractAddress,
  });
  const { setNotification } = useContext(NotificationsContext);
  const [redeemProxyTokens] = useRedeemTokensMutation();
  const [isRedeemDisabled, setIsRedeemDisabled] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState<string>("");
  const [availableRedeemedAmount, setAvailableRedeemedAmount] =
    useState<string>("0");

  const [balance, setBallance] = useState<string>("0");

  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: availableBalance, error: availableBalanceError } =
    useCall({
      contract: vestingContract,
      method: "getAvailableBalance",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOf) {
      const val = new BigNumber(balanceOf.toString())
        .dividedBy(1e18)
        .decimalPlaces(2, 1)
        .toString();
      setBallance(val);
    }
  }, [balanceOf]);

  useEffect(() => {
    if (availableBalance) {
      const val = new BigNumber(availableBalance.toString()).dividedBy(1e18);

      let formattedVal;
      if (val.isInteger()) {
        formattedVal = val.toFixed(0);
      } else {
        formattedVal = val.toFixed(4, BigNumber.ROUND_DOWN);
      }

      setAvailableRedeemedAmount(formattedVal);
    }
  }, [availableBalance]);

  // Redeem
  const { state: redeemState, send: redeem } = useContractFunction(
    vestingContract,
    "redeem",
    {
      transactionName: "Redeem ",
    }
  );

  useEffect(() => {
    if (redeemState.status === "Success") {
      setNotification({ message: "Redeem successful!", type: "success" });
      setRedeemAmount("");
    } else if (
      redeemState.status === "Fail" ||
      redeemState.status === "Exception"
    ) {
      setNotification({
        message: "Redeem failed!",
        type: "error",
      });
    }
  }, [redeemState]);

  const handleRedeem = () => {
    const amount = redeemAmount ? parseFloat(redeemAmount) : 0;
    const maxLimit = parseFloat(availableRedeemedAmount);
    const minLimit = 0;
    if (amount && amount > 0 && amount <= maxLimit && amount >= minLimit) {
      const acceptedRedeemAmount = new BigNumber(redeemAmount)
        .multipliedBy(1e18)
        .toFixed();
      if (isConnectedToMetamask) {
        redeem(acceptedRedeemAmount);
      } else {
        const token = localStorage.getItem("token");
        setIsRedeemDisabled(true);
        setRedeemAmount("");
        redeemProxyTokens({
          token,
          amount: acceptedRedeemAmount,
          contractAddress: vestingContractAddress,
        })
          .unwrap()
          .then((data: any) => {
            if (data?.data.receipt.status === 1) {
              setNotification({
                message: "Redeem successfully!",
                type: "success",
              });
            } else {
              setNotification({
                message: "Redeem failed!",
                type: "error",
              });
            }
            setIsRedeemDisabled(false);
          })
          .catch((err: any) => {
            setNotification({
              message: "Redeem failed!",
              type: "error",
            });
            setIsRedeemDisabled(false);
          });
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} padding={4} gap={1}>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" color="secondary">
            {`Full vested amount: ${fullVestedAmount}`}
          </Typography>
          <Typography variant="body2" color="secondary">
            {`Token redeemed amount:  ${tokenRedeemedAmount}`}
          </Typography>
          <Typography variant="body2" color="secondary">
            {`Available amount:  ${availableRedeemedAmount}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="secondary">
            {`Wallet Balance: ${balance || 0}`}
          </Typography>
        </Grid>
      </Grid>
      <FormControl variant="outlined" fullWidth>
        <FilledInput
          id="outlined-adornment-weight"
          color="secondary"
          placeholder="0.0"
          value={redeemAmount}
          onChange={(ev) =>
            (ev.target.value === "" || !isNaN(parseFloat(ev.target.value))) &&
            setRedeemAmount(ev.target.value)
          }
          endAdornment={
            <InputAdornment position="start">
              <Button
                color="primary"
                onClick={() =>
                  setRedeemAmount(availableRedeemedAmount.toString())
                }
                variant="contained"
                sx={{
                  filter: "none",
                  padding: "3px 15px",
                  borderRadius: "10px",
                  width: "auto",
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                MAX
              </Button>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "balance",

            style: { padding: "10px" },
          }}
        />
      </FormControl>
      <Grid item sx={{ padding: "32px 0", width: "100%" }}>
        <LoadingButton
          color="primary"
          fullWidth
          onClick={() => handleRedeem()}
          loading={isRedeemDisabled || redeemState.status === "Mining"}
          loadingPosition="start"
          startIcon={<AccountBalanceWalletIcon />}
          variant="contained"
          sx={{ height: "48px" }}
        >
          <span>REDEEM</span>
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

type DistributingCardProps = {
  maxAllocation?: string;
  minAllocation?: string;
  tokenForSaleAddress?: string;
  vestingContractAddress?: string;
  projectChain: IChain;
};
export const DistributingCard = ({
  maxAllocation,
  tokenForSaleAddress,
  vestingContractAddress,
  minAllocation,
  projectChain,
}: DistributingCardProps) => {
  const { vestingContract } = useContracts({
    stableCoinAddress: tokenForSaleAddress,
    vestingContractAddress,
  });
  const { switchNetwork } = useEthers();

  const [loadingIsUserWinner, setLoadingIsUserWinner] = useState(true);
  const [isSwitchNetworkDisabled, setIsSwitchNetworkDisabled] = useState(false);
  const [fullVestedAmount, setFullVestedAmount] = useState<string>("0");
  const [tokenRedeemedAmount, setTokenRedeemedAmount] = useState<string>("0");

  const [isWinner, setIsWinner] = useState<boolean>();
  const {
    account,
    setUserMetamaskChainId,
    userMetamaskChainId,
    isConnectedToMetamask,
  } = useContext(AccountContext);

  const isDifferentChain = userMetamaskChainId !== projectChain.chainId;

  const { value: userBalances, error: userBalancesError } =
    useCall({
      contract: vestingContract,
      method: "userBalances",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (userBalances) {
      const tokenBalance = new BigNumber(
        userBalances.tokenBalance.toString()
      ).dividedBy(1e18);

      setIsWinner(!tokenBalance.isZero());
      setLoadingIsUserWinner(false);

      const tokenRelease = new BigNumber(
        userBalances.tokenReleased.toString()
      ).dividedBy(1e18);

      let formattedTokenRelease;
      if (tokenRelease.isInteger()) {
        formattedTokenRelease = tokenRelease.toFixed(0);
      } else {
        formattedTokenRelease = tokenRelease.toFixed(4, BigNumber.ROUND_DOWN);
      }

      setFullVestedAmount(tokenBalance.toFixed(4, BigNumber.ROUND_DOWN));
      setTokenRedeemedAmount(formattedTokenRelease);
    }
  }, [userBalances]);

  const renderCardContent = isWinner ? (
    <Card>
      <WinnerCard
        tokenForSaleAddress={tokenForSaleAddress}
        vestingContractAddress={vestingContractAddress}
        fullVestedAmount={fullVestedAmount}
        tokenRedeemedAmount={tokenRedeemedAmount}
      />
    </Card>
  ) : (
    <Card>
      <Grid container justifyContent={"center"} padding={3}>
        {loadingIsUserWinner ? (
          <CircularProgress color="secondary" />
        ) : (
          <Typography variant="body1">
            You were not selected for this round.
          </Typography>
        )}
      </Grid>
    </Card>
  );

  const handleSwitchNetwork = async () => {
    setIsSwitchNetworkDisabled(true);
    let toUpdateChainId = projectChain.chainId;
    if (projectChain.chainId && isConnectedToMetamask) {
      switch (projectChain.chainId) {
        case BSCTestnet.chainId: {
          toUpdateChainId = BSCTestnet.chainId;
          break;
        }
        case Polygon.chainId: {
          toUpdateChainId = Polygon.chainId;
          break;
        }
        case BSC.chainId: {
          toUpdateChainId = BSC.chainId;
          break;
        }
        default: {
          break;
        }
      }
      if (toUpdateChainId) {
        await switchNetwork(toUpdateChainId)
          .then(() => {
            setUserMetamaskChainId(toUpdateChainId);
          })
          .catch((err) => console.log(err));
        setIsSwitchNetworkDisabled(false);
      }
    }
  };

  return isDifferentChain ? (
    <Card sx={{ padding: "24px" }}>
      <Grid container justifyContent={"center"} padding={3}>
        <Typography variant="body1" textAlign={"center"} fontWeight={500}>
          {`This project is on a different chain. Please switch the chain if you want to see if you're a winner for this round.`}
        </Typography>
      </Grid>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleSwitchNetwork}
        sx={{ height: "48px" }}
        disabled={isSwitchNetworkDisabled}
      >
        SWITCH TO {projectChain.label.toUpperCase()}
      </Button>
    </Card>
  ) : (
    renderCardContent
  );
};
export default DistributingCard;
