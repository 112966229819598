import * as Yup from "yup";

export type CreateAccountValues = {
  email: string | undefined;
  password: string | undefined;
  repeatedPassword: string | undefined;
  username?: string;
};
export const registerInitialValues: CreateAccountValues = {
  username: "",
  email: undefined,
  password: undefined,
  repeatedPassword: undefined,
};

export type SignInValues = {
  email: string | undefined;
  password: string | undefined;
};
export const signInInitialValues: SignInValues = {
  email: undefined,
  password: undefined,
};

export const registerValidationSchema = Yup.object().shape({
  username: Yup.string(),
  email: Yup.string().email().required(""),
  password: Yup.string().min(6, "Password is too short.").required(""),
  repeatedPassword: Yup.string()
    .required("")
    .oneOf([Yup.ref("password"), null], "*THE PASSWORDS DOES NOT MATCH!"),
});
