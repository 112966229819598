export const FingerPrintSVG = ({ width = "91", height = "91" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1611_4351)">
        <path
          d="M45.3778 53.3948L47.709 49.3571C48.6281 47.7651 48.0807 45.7222 46.4887 44.803C44.8967 43.8839 42.8537 44.4313 41.9346 46.0233L39.8299 49.6688C37.2935 54.0619 33.24 57.3452 28.4158 58.9138L21.7644 61.0767C21.2055 61.2584 20.8999 61.8587 21.0815 62.4177C21.2632 62.9764 21.8635 63.2821 22.4224 63.1004L29.0739 60.9376C34.3988 59.206 38.8731 55.5819 41.6728 50.7328L43.7775 47.0873C44.1099 46.5115 44.8489 46.3135 45.4247 46.6459C46.0005 46.9784 46.1985 47.7173 45.8661 48.2932L43.5349 52.3308C40.4137 57.737 35.4201 61.7609 29.4739 63.6616L23.0157 65.726C22.456 65.9048 22.1472 66.5038 22.3262 67.0635C22.41 67.326 22.5862 67.533 22.8073 67.6607C23.058 67.8054 23.3663 67.848 23.6636 67.7529L30.1217 65.6885C36.5733 63.6263 41.9914 59.2603 45.3778 53.3948Z"
          fill="#0D0D0E"
        />
        <path
          d="M45.3778 53.3948L47.709 49.3571C48.6281 47.7651 48.0807 45.7222 46.4887 44.803C44.8967 43.8839 42.8537 44.4313 41.9346 46.0233L39.8299 49.6688C37.2935 54.0619 33.24 57.3452 28.4158 58.9138L21.7644 61.0767C21.2055 61.2584 20.8999 61.8587 21.0815 62.4177C21.2632 62.9764 21.8635 63.2821 22.4224 63.1004L29.0739 60.9376C34.3988 59.206 38.8731 55.5819 41.6728 50.7328L43.7775 47.0873C44.1099 46.5115 44.8489 46.3135 45.4247 46.6459C46.0005 46.9784 46.1985 47.7173 45.8661 48.2932L43.5349 52.3308C40.4137 57.737 35.4201 61.7609 29.4739 63.6616L23.0157 65.726C22.456 65.9048 22.1472 66.5038 22.3262 67.0635C22.41 67.326 22.5862 67.533 22.8073 67.6607C23.058 67.8054 23.3663 67.848 23.6636 67.7529L30.1217 65.6885C36.5733 63.6263 41.9914 59.2603 45.3778 53.3948Z"
          fill="url(#paint0_linear_1611_4351)"
        />
        <path
          d="M51.6405 51.627C53.8113 47.8671 52.5185 43.0423 48.7586 40.8715C44.9987 38.7008 40.1739 39.9936 38.0031 43.7534L35.8984 47.3989C33.9237 50.8192 30.7679 53.3754 27.0121 54.5966L22.2834 56.1343C21.7246 56.316 21.4189 56.9163 21.6006 57.4753C21.7823 58.0341 22.3827 58.3395 22.9415 58.1581L27.6702 56.6204C31.9267 55.2363 35.5034 52.3393 37.7414 48.463L39.8462 44.8175C41.4302 42.0738 44.951 41.1304 47.6947 42.7145C50.4384 44.2985 51.3818 47.8194 49.7978 50.563L47.4666 54.6007C43.7796 60.9868 37.8807 65.7405 30.8564 67.9858L25.4072 69.7277C24.8475 69.9065 24.5387 70.5055 24.7176 71.0652C24.8015 71.3277 24.9776 71.5347 25.1988 71.6624C25.4494 71.8071 25.7577 71.8496 26.055 71.7546L31.5043 70.0127C39.0337 67.6058 45.357 62.5103 49.3094 55.6646L51.6405 51.627Z"
          fill="#0D0D0E"
        />
        <path
          d="M51.6405 51.627C53.8113 47.8671 52.5185 43.0423 48.7586 40.8715C44.9987 38.7008 40.1739 39.9936 38.0031 43.7534L35.8984 47.3989C33.9237 50.8192 30.7679 53.3754 27.0121 54.5966L22.2834 56.1343C21.7246 56.316 21.4189 56.9163 21.6006 57.4753C21.7823 58.0341 22.3827 58.3395 22.9415 58.1581L27.6702 56.6204C31.9267 55.2363 35.5034 52.3393 37.7414 48.463L39.8462 44.8175C41.4302 42.0738 44.951 41.1304 47.6947 42.7145C50.4384 44.2985 51.3818 47.8194 49.7978 50.563L47.4666 54.6007C43.7796 60.9868 37.8807 65.7405 30.8564 67.9858L25.4072 69.7277C24.8475 69.9065 24.5387 70.5055 24.7176 71.0652C24.8015 71.3277 24.9776 71.5347 25.1988 71.6624C25.4494 71.8071 25.7577 71.8496 26.055 71.7546L31.5043 70.0127C39.0337 67.6058 45.357 62.5103 49.3094 55.6646L51.6405 51.627Z"
          fill="url(#paint1_linear_1611_4351)"
        />
        <path
          d="M55.5721 53.8969C58.9945 47.9692 56.9563 40.3625 51.0286 36.9401C45.1009 33.5177 37.4941 35.5559 34.0718 41.4836L31.9671 45.1291C30.5541 47.5765 28.2958 49.4056 25.6084 50.2794L21.3506 51.6639C20.7918 51.8456 20.4861 52.446 20.6678 53.0049C20.8494 53.5637 21.45 53.8692 22.0087 53.6877L26.2664 52.3032C29.4547 51.2665 32.1337 49.0965 33.8099 46.1931L35.9147 42.5476C38.7503 37.6361 45.053 35.9473 49.9646 38.783C54.8761 41.6187 56.5649 47.9214 53.7293 52.8329L51.3981 56.8705C47.1451 64.2369 40.3408 69.7201 32.2386 72.3099L30.7305 72.7919C30.1708 72.9709 29.8621 73.5698 30.041 74.1294C30.1249 74.3918 30.3011 74.5991 30.5222 74.7267C30.7728 74.8714 31.0811 74.9139 31.3784 74.8189L32.8864 74.3367C41.4939 71.5853 48.7226 65.7604 53.2409 57.9345L55.572 53.8968L55.5721 53.8969Z"
          fill="#0D0D0E"
        />
        <path
          d="M55.5721 53.8969C58.9945 47.9692 56.9563 40.3625 51.0286 36.9401C45.1009 33.5177 37.4941 35.5559 34.0718 41.4836L31.9671 45.1291C30.5541 47.5765 28.2958 49.4056 25.6084 50.2794L21.3506 51.6639C20.7918 51.8456 20.4861 52.446 20.6678 53.0049C20.8494 53.5637 21.45 53.8692 22.0087 53.6877L26.2664 52.3032C29.4547 51.2665 32.1337 49.0965 33.8099 46.1931L35.9147 42.5476C38.7503 37.6361 45.053 35.9473 49.9646 38.783C54.8761 41.6187 56.5649 47.9214 53.7293 52.8329L51.3981 56.8705C47.1451 64.2369 40.3408 69.7201 32.2386 72.3099L30.7305 72.7919C30.1708 72.9709 29.8621 73.5698 30.041 74.1294C30.1249 74.3918 30.3011 74.5991 30.5222 74.7267C30.7728 74.8714 31.0811 74.9139 31.3784 74.8189L32.8864 74.3367C41.4939 71.5853 48.7226 65.7604 53.2409 57.9345L55.572 53.8968L55.5721 53.8969Z"
          fill="url(#paint2_linear_1611_4351)"
        />
        <path
          d="M30.1401 39.2138C29.8462 39.7228 30.0206 40.3734 30.5295 40.6672C31.0384 40.961 31.6891 40.7868 31.983 40.2778C33.7744 37.175 36.6314 34.8281 40.0278 33.6694C43.3927 32.5214 47.0492 32.6114 50.3239 33.9228C50.8694 34.1413 51.4887 33.876 51.7071 33.3306C51.9256 32.7851 51.6597 32.1661 51.115 31.9474C47.3682 30.447 43.1867 30.3432 39.3406 31.6555C35.4567 32.9804 32.1891 35.6647 30.1401 39.2138Z"
          fill="#0D0D0E"
        />
        <path
          d="M30.1401 39.2138C29.8462 39.7228 30.0206 40.3734 30.5295 40.6672C31.0384 40.961 31.6891 40.7868 31.983 40.2778C33.7744 37.175 36.6314 34.8281 40.0278 33.6694C43.3927 32.5214 47.0492 32.6114 50.3239 33.9228C50.8694 34.1413 51.4887 33.876 51.7071 33.3306C51.9256 32.7851 51.6597 32.1661 51.115 31.9474C47.3682 30.447 43.1867 30.3432 39.3406 31.6555C35.4567 32.9804 32.1891 35.6647 30.1401 39.2138Z"
          fill="url(#paint3_linear_1611_4351)"
        />
        <path
          d="M29.8785 43.9233C30.1723 43.4143 29.9979 42.7636 29.489 42.4698C28.9801 42.176 28.3294 42.3503 28.0356 42.8593C27.1842 44.3338 25.8239 45.4357 24.2048 45.9622L20.4175 47.1938C19.8587 47.3754 19.5531 47.9758 19.7347 48.5347C19.8194 48.7949 19.9946 49.0001 20.2143 49.127C20.4664 49.2725 20.7771 49.3146 21.0756 49.2175L24.8629 47.9859C26.9826 47.2966 28.7638 45.8539 29.8785 43.9233Z"
          fill="#0D0D0E"
        />
        <path
          d="M29.8785 43.9233C30.1723 43.4143 29.9979 42.7636 29.489 42.4698C28.9801 42.176 28.3294 42.3503 28.0356 42.8593C27.1842 44.3338 25.8239 45.4357 24.2048 45.9622L20.4175 47.1938C19.8587 47.3754 19.5531 47.9758 19.7347 48.5347C19.8194 48.7949 19.9946 49.0001 20.2143 49.127C20.4664 49.2725 20.7771 49.3146 21.0756 49.2175L24.8629 47.9859C26.9826 47.2966 28.7638 45.8539 29.8785 43.9233Z"
          fill="url(#paint4_linear_1611_4351)"
        />
        <path
          d="M50.8412 66.9935C50.4227 66.5809 49.7496 66.5846 49.3364 67.0023C48.2458 68.1056 47.0767 69.1534 45.8611 70.1163C45.4005 70.4811 45.3229 71.1504 45.6879 71.6109C45.7738 71.7194 45.8765 71.8065 45.9895 71.8718C46.3563 72.0835 46.8304 72.0632 47.1826 71.7843C48.4654 70.768 49.6992 69.6625 50.85 68.4982C51.263 68.0803 51.259 67.4065 50.8412 66.9935Z"
          fill="#0D0D0E"
        />
        <path
          d="M50.8412 66.9935C50.4227 66.5809 49.7496 66.5846 49.3364 67.0023C48.2458 68.1056 47.0767 69.1534 45.8611 70.1163C45.4005 70.4811 45.3229 71.1504 45.6879 71.6109C45.7738 71.7194 45.8765 71.8065 45.9895 71.8718C46.3563 72.0835 46.8304 72.0632 47.1826 71.7843C48.4654 70.768 49.6992 69.6625 50.85 68.4982C51.263 68.0803 51.259 67.4065 50.8412 66.9935Z"
          fill="url(#paint5_linear_1611_4351)"
        />
        <path
          d="M60.9316 42.4334C60.7496 41.8744 60.149 41.5693 59.5904 41.7513C59.0317 41.9333 58.7263 42.5337 58.9083 43.0924C60.2119 47.0938 59.7572 51.4712 57.6606 55.1026L55.3295 59.1402C54.4447 60.6727 53.4475 62.1559 52.3654 63.5488C52.0048 64.0129 52.0888 64.6812 52.5528 65.0417C52.592 65.0722 52.6328 65.0995 52.6747 65.1237C53.1278 65.3853 53.7158 65.2789 54.0458 64.8541C55.1873 63.3848 56.2393 61.8203 57.1724 60.2041L59.5035 56.1666C61.9015 52.0134 62.4219 47.0078 60.9316 42.4334Z"
          fill="#0D0D0E"
        />
        <path
          d="M60.9316 42.4334C60.7496 41.8744 60.149 41.5693 59.5904 41.7513C59.0317 41.9333 58.7263 42.5337 58.9083 43.0924C60.2119 47.0938 59.7572 51.4712 57.6606 55.1026L55.3295 59.1402C54.4447 60.6727 53.4475 62.1559 52.3654 63.5488C52.0048 64.0129 52.0888 64.6812 52.5528 65.0417C52.592 65.0722 52.6328 65.0995 52.6747 65.1237C53.1278 65.3853 53.7158 65.2789 54.0458 64.8541C55.1873 63.3848 56.2393 61.8203 57.1724 60.2041L59.5035 56.1666C61.9015 52.0134 62.4219 47.0078 60.9316 42.4334Z"
          fill="url(#paint6_linear_1611_4351)"
        />
        <path
          d="M57.018 39.2639C57.1107 39.3986 57.2289 39.5052 57.3617 39.5819C57.7061 39.7807 58.1485 39.7775 58.4978 39.537C58.9818 39.204 59.1041 38.5413 58.771 38.0574C57.8083 36.6588 56.6435 35.4177 55.3089 34.3685C54.8459 34.0054 54.178 34.0855 53.8149 34.5473C53.4518 35.0091 53.5317 35.6781 53.9937 36.0413C55.1595 36.958 56.177 38.0421 57.018 39.2639Z"
          fill="#0D0D0E"
        />
        <path
          d="M57.018 39.2639C57.1107 39.3986 57.2289 39.5052 57.3617 39.5819C57.7061 39.7807 58.1485 39.7775 58.4978 39.537C58.9818 39.204 59.1041 38.5413 58.771 38.0574C57.8083 36.6588 56.6435 35.4177 55.3089 34.3685C54.8459 34.0054 54.178 34.0855 53.8149 34.5473C53.4518 35.0091 53.5317 35.6781 53.9937 36.0413C55.1595 36.958 56.177 38.0421 57.018 39.2639Z"
          fill="url(#paint7_linear_1611_4351)"
        />
        <path
          d="M55.5682 29.077C55.2235 28.878 54.8693 28.6863 54.5152 28.5071C53.9909 28.2417 53.3507 28.4517 53.0854 28.9759C52.8202 29.5003 53.0284 30.1422 53.5544 30.4056C53.8737 30.5672 54.1932 30.7402 54.5042 30.9197C63.7513 36.2586 66.931 48.1251 61.5921 57.3723L59.261 61.41C58.9671 61.919 59.1416 62.5696 59.6505 62.8634C60.1593 63.1572 60.81 62.983 61.1039 62.474L63.435 58.4365C69.3606 48.1732 65.8315 35.0025 55.5682 29.077Z"
          fill="#0D0D0E"
        />
        <path
          d="M55.5682 29.077C55.2235 28.878 54.8693 28.6863 54.5152 28.5071C53.9909 28.2417 53.3507 28.4517 53.0854 28.9759C52.8202 29.5003 53.0284 30.1422 53.5544 30.4056C53.8737 30.5672 54.1932 30.7402 54.5042 30.9197C63.7513 36.2586 66.931 48.1251 61.5921 57.3723L59.261 61.41C58.9671 61.919 59.1416 62.5696 59.6505 62.8634C60.1593 63.1572 60.81 62.983 61.1039 62.474L63.435 58.4365C69.3606 48.1732 65.8315 35.0025 55.5682 29.077Z"
          fill="url(#paint8_linear_1611_4351)"
        />
        <path
          d="M42.6069 28.2598C42.7883 28.3646 43.0027 28.4169 43.2273 28.398C45.3759 28.2171 47.519 28.3933 49.5978 28.9213C50.1674 29.066 50.7465 28.7216 50.8911 28.1521C51.0358 27.5827 50.6909 27.0039 50.1219 26.8588C47.8133 26.2723 45.4337 26.0767 43.049 26.2774C42.4634 26.3267 42.0286 26.8414 42.078 27.4269C42.1082 27.788 42.3153 28.0915 42.6069 28.2598Z"
          fill="#0D0D0E"
        />
        <path
          d="M42.6069 28.2598C42.7883 28.3646 43.0027 28.4169 43.2273 28.398C45.3759 28.2171 47.519 28.3933 49.5978 28.9213C50.1674 29.066 50.7465 28.7216 50.8911 28.1521C51.0358 27.5827 50.6909 27.0039 50.1219 26.8588C47.8133 26.2723 45.4337 26.0767 43.049 26.2774C42.4634 26.3267 42.0286 26.8414 42.078 27.4269C42.1082 27.788 42.3153 28.0915 42.6069 28.2598Z"
          fill="url(#paint9_linear_1611_4351)"
        />
        <path
          d="M25.9469 41.6533L28.0516 38.0079C30.5265 33.7213 34.5068 30.5762 39.2595 29.1516C39.8223 28.9828 40.1419 28.3899 39.9732 27.8269C39.8044 27.2639 39.2112 26.9446 38.6485 27.1132C33.3739 28.6942 28.956 32.1855 26.2088 36.9439L24.104 40.5894C23.8144 41.091 23.3517 41.4658 22.8011 41.6448L20.9361 42.2512C20.3772 42.4329 20.0716 43.0332 20.2533 43.5921C20.3378 43.8523 20.5132 44.0576 20.7328 44.1844C20.9849 44.33 21.2954 44.3721 21.5941 44.2751L23.4591 43.6687C24.5106 43.3266 25.3941 42.6109 25.9469 41.6533Z"
          fill="#0D0D0E"
        />
        <path
          d="M25.9469 41.6533L28.0516 38.0079C30.5265 33.7213 34.5068 30.5762 39.2595 29.1516C39.8223 28.9828 40.1419 28.3899 39.9732 27.8269C39.8044 27.2639 39.2112 26.9446 38.6485 27.1132C33.3739 28.6942 28.956 32.1855 26.2088 36.9439L24.104 40.5894C23.8144 41.091 23.3517 41.4658 22.8011 41.6448L20.9361 42.2512C20.3772 42.4329 20.0716 43.0332 20.2533 43.5921C20.3378 43.8523 20.5132 44.0576 20.7328 44.1844C20.9849 44.33 21.2954 44.3721 21.5941 44.2751L23.4591 43.6687C24.5106 43.3266 25.3941 42.6109 25.9469 41.6533Z"
          fill="url(#paint10_linear_1611_4351)"
        />
        <path
          d="M70.3385 52.8563C71.0619 49.2694 71.0223 45.6287 70.2208 42.0354C70.0928 41.4619 69.5243 41.1005 68.9507 41.2286C68.3772 41.3566 68.016 41.9252 68.1438 42.4988C68.88 45.7994 68.9167 49.1427 68.2526 52.4356C68.1599 52.8952 68.3787 53.3454 68.7633 53.5675C68.8608 53.6237 68.9689 53.6654 69.0853 53.6888C69.6612 53.8049 70.2224 53.4322 70.3385 52.8563Z"
          fill="#0D0D0E"
        />
        <path
          d="M70.3385 52.8563C71.0619 49.2694 71.0223 45.6287 70.2208 42.0354C70.0928 41.4619 69.5243 41.1005 68.9507 41.2286C68.3772 41.3566 68.016 41.9252 68.1438 42.4988C68.88 45.7994 68.9167 49.1427 68.2526 52.4356C68.1599 52.8952 68.3787 53.3454 68.7633 53.5675C68.8608 53.6237 68.9689 53.6654 69.0853 53.6888C69.6612 53.8049 70.2224 53.4322 70.3385 52.8563Z"
          fill="url(#paint11_linear_1611_4351)"
        />
        <path
          d="M64.4234 30.5565C64.0372 30.1139 63.3649 30.0679 62.9221 30.4544C62.4794 30.8408 62.4337 31.5129 62.8201 31.9555C64.4506 33.8239 65.7767 35.9325 66.7615 38.223C66.8557 38.4421 67.0147 38.6131 67.2063 38.7238C67.4872 38.8859 67.8384 38.9182 68.1594 38.7801C68.6993 38.5481 68.9487 37.9222 68.7166 37.3824C67.6441 34.8885 66.1997 32.5918 64.4234 30.5565Z"
          fill="#0D0D0E"
        />
        <path
          d="M64.4234 30.5565C64.0372 30.1139 63.3649 30.0679 62.9221 30.4544C62.4794 30.8408 62.4337 31.5129 62.8201 31.9555C64.4506 33.8239 65.7767 35.9325 66.7615 38.223C66.8557 38.4421 67.0147 38.6131 67.2063 38.7238C67.4872 38.8859 67.8384 38.9182 68.1594 38.7801C68.6993 38.5481 68.9487 37.9222 68.7166 37.3824C67.6441 34.8885 66.1997 32.5918 64.4234 30.5565Z"
          fill="url(#paint12_linear_1611_4351)"
        />
        <path
          d="M39.1294 24.4855C45.1511 23.007 51.4174 23.8959 56.774 26.9885C57.7686 27.5628 58.7275 28.2118 59.6239 28.9174C60.0856 29.282 60.7546 29.2013 61.1181 28.7396C61.4817 28.2779 61.4021 27.6091 60.9404 27.2456C59.9641 26.4771 58.9203 25.7705 57.838 25.1456C52.0037 21.7772 45.1793 20.8088 38.622 22.4189C32.085 24.0241 26.4984 28.0174 22.8914 33.6634C22.5751 34.1586 22.72 34.8166 23.2152 35.1328C23.2289 35.1417 23.2427 35.15 23.2567 35.1581C23.7466 35.4409 24.3771 35.2906 24.6847 34.8091C27.9959 29.6258 33.1258 25.9595 39.1294 24.4855Z"
          fill="#0D0D0E"
        />
        <path
          d="M39.1294 24.4855C45.1511 23.007 51.4174 23.8959 56.774 26.9885C57.7686 27.5628 58.7275 28.2118 59.6239 28.9174C60.0856 29.282 60.7546 29.2013 61.1181 28.7396C61.4817 28.2779 61.4021 27.6091 60.9404 27.2456C59.9641 26.4771 58.9203 25.7705 57.838 25.1456C52.0037 21.7772 45.1793 20.8088 38.622 22.4189C32.085 24.0241 26.4984 28.0174 22.8914 33.6634C22.5751 34.1586 22.72 34.8166 23.2152 35.1328C23.2289 35.1417 23.2427 35.15 23.2567 35.1581C23.7466 35.4409 24.3771 35.2906 24.6847 34.8091C27.9959 29.6258 33.1258 25.9595 39.1294 24.4855Z"
          fill="url(#paint13_linear_1611_4351)"
        />
        <path
          d="M60.1079 21.214C58.0473 20.0244 55.856 19.0777 53.5951 18.4004C53.0322 18.2317 52.4392 18.5514 52.2706 19.1142C52.102 19.6771 52.4217 20.2702 52.9846 20.4388C55.0874 21.069 57.1261 21.9497 59.0439 23.0569C65.8007 26.958 70.5535 33.2913 72.427 40.8901C72.4992 41.1829 72.686 41.4172 72.9282 41.557C73.1578 41.6896 73.437 41.7371 73.7147 41.6686C74.2853 41.5279 74.6339 40.9514 74.4931 40.3809C73.5169 36.4217 71.7998 32.759 69.3894 29.4952C66.8928 26.1145 63.7699 23.3283 60.1079 21.214Z"
          fill="#0D0D0E"
        />
        <path
          d="M60.1079 21.214C58.0473 20.0244 55.856 19.0777 53.5951 18.4004C53.0322 18.2317 52.4392 18.5514 52.2706 19.1142C52.102 19.6771 52.4217 20.2702 52.9846 20.4388C55.0874 21.069 57.1261 21.9497 59.0439 23.0569C65.8007 26.958 70.5535 33.2913 72.427 40.8901C72.4992 41.1829 72.686 41.4172 72.9282 41.557C73.1578 41.6896 73.437 41.7371 73.7147 41.6686C74.2853 41.5279 74.6339 40.9514 74.4931 40.3809C73.5169 36.4217 71.7998 32.759 69.3894 29.4952C66.8928 26.1145 63.7699 23.3283 60.1079 21.214Z"
          fill="url(#paint14_linear_1611_4351)"
        />
        <path
          d="M32.7846 20.7396C32.877 20.9689 33.0402 21.1485 33.239 21.2632C33.5126 21.4212 33.8534 21.4563 34.1691 21.3291C38.7318 19.491 43.7081 18.856 48.56 19.4925C49.1426 19.5689 49.677 19.1586 49.7534 18.576C49.8298 17.9934 49.4194 17.4586 48.8369 17.3826C43.6227 16.6985 38.2757 17.3806 33.3741 19.3551C32.8289 19.5748 32.5651 20.1947 32.7846 20.7396Z"
          fill="#0D0D0E"
        />
        <path
          d="M32.7846 20.7396C32.877 20.9689 33.0402 21.1485 33.239 21.2632C33.5126 21.4212 33.8534 21.4563 34.1691 21.3291C38.7318 19.491 43.7081 18.856 48.56 19.4925C49.1426 19.5689 49.677 19.1586 49.7534 18.576C49.8298 17.9934 49.4194 17.4586 48.8369 17.3826C43.6227 16.6985 38.2757 17.3806 33.3741 19.3551C32.8289 19.5748 32.5651 20.1947 32.7846 20.7396Z"
          fill="url(#paint15_linear_1611_4351)"
        />
        <path
          d="M46.8544 14.6637C46.9888 14.7413 47.1423 14.7907 47.3073 14.8027C52.2685 15.1694 56.9809 16.6238 61.3137 19.1254C63.5716 20.429 65.6671 21.993 67.5416 23.7737C67.9676 24.1779 68.641 24.1611 69.0457 23.7351C69.4504 23.3091 69.4332 22.6355 69.0071 22.2308C67.0112 20.3348 64.7807 18.6699 62.3777 17.2825C57.7651 14.6194 52.7476 13.071 47.4643 12.6806C46.8783 12.6373 46.3681 13.0772 46.3248 13.6633C46.2936 14.0841 46.5118 14.466 46.8544 14.6637Z"
          fill="#0D0D0E"
        />
        <path
          d="M46.8544 14.6637C46.9888 14.7413 47.1423 14.7907 47.3073 14.8027C52.2685 15.1694 56.9809 16.6238 61.3137 19.1254C63.5716 20.429 65.6671 21.993 67.5416 23.7737C67.9676 24.1779 68.641 24.1611 69.0457 23.7351C69.4504 23.3091 69.4332 22.6355 69.0071 22.2308C67.0112 20.3348 64.7807 18.6699 62.3777 17.2825C57.7651 14.6194 52.7476 13.071 47.4643 12.6806C46.8783 12.6373 46.3681 13.0772 46.3248 13.6633C46.2936 14.0841 46.5118 14.466 46.8544 14.6637Z"
          fill="url(#paint16_linear_1611_4351)"
        />
        <path
          d="M70.6866 27.2206C71.4181 28.1473 72.1073 29.1251 72.7349 30.1265C72.8303 30.2789 72.958 30.3987 73.1035 30.4826C73.4337 30.6733 73.8558 30.6796 74.2014 30.4629C74.6993 30.1508 74.85 29.4943 74.5379 28.9963C73.8696 27.9302 73.1358 26.8891 72.3568 25.9022C71.9926 25.4408 71.3236 25.3622 70.8624 25.7261C70.4012 26.0902 70.3225 26.7594 70.6866 27.2206Z"
          fill="#0D0D0E"
        />
        <path
          d="M70.6866 27.2206C71.4181 28.1473 72.1073 29.1251 72.7349 30.1265C72.8303 30.2789 72.958 30.3987 73.1035 30.4826C73.4337 30.6733 73.8558 30.6796 74.2014 30.4629C74.6993 30.1508 74.85 29.4943 74.5379 28.9963C73.8696 27.9302 73.1358 26.8891 72.3568 25.9022C71.9926 25.4408 71.3236 25.3622 70.8624 25.7261C70.4012 26.0902 70.3225 26.7594 70.6866 27.2206Z"
          fill="url(#paint17_linear_1611_4351)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1611_4351"
          x1="34.7556"
          y1="38.0289"
          x2="49.903"
          y2="47.3741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1611_4351"
          x1="35.2747"
          y1="33.0865"
          x2="56.2524"
          y2="46.1496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1611_4351"
          x1="34.9092"
          y1="27.6335"
          x2="62.5595"
          y2="45.0878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1611_4351"
          x1="38.5356"
          y1="24.6722"
          x2="52.4859"
          y2="33.6497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1611_4351"
          x1="24.4944"
          y1="39.5862"
          x2="30.5968"
          y2="43.408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1611_4351"
          x1="48.4106"
          y1="65.5502"
          x2="51.6591"
          y2="67.5563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1611_4351"
          x1="59.5305"
          y1="41.3094"
          x2="66.2268"
          y2="45.3274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1611_4351"
          x1="54.2617"
          y1="33.7515"
          x2="59.9217"
          y2="37.8561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1611_4351"
          x1="53.6609"
          y1="27.9758"
          x2="74.6612"
          y2="41.4719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1611_4351"
          x1="44.1899"
          y1="23.3878"
          x2="51.2567"
          y2="28.3605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1611_4351"
          x1="31.8496"
          y1="22.8021"
          x2="40.7541"
          y2="28.2133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1611_4351"
          x1="68.793"
          y1="40.8136"
          x2="75.4141"
          y2="44.9672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1611_4351"
          x1="63.2322"
          y1="29.8022"
          x2="70.9623"
          y2="35.1922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1611_4351"
          x1="35.3154"
          y1="12.1422"
          x2="62.16"
          y2="30.0431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1611_4351"
          x1="53.2957"
          y1="17.281"
          x2="77.9984"
          y2="36.8522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1611_4351"
          x1="37.9387"
          y1="10.9963"
          x2="50.2521"
          y2="19.279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1611_4351"
          x1="48.679"
          y1="9.37354"
          x2="67.01"
          y2="27.0145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1611_4351"
          x1="71.1321"
          y1="25.1075"
          x2="75.8535"
          y2="28.3713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF377F" />
          <stop offset="1" stopColor="#FF3743" />
        </linearGradient>
        <clipPath id="clip0_1611_4351">
          <rect
            width="65.6838"
            height="65.6838"
            fill="white"
            transform="translate(33.386 0.544067) rotate(30)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
