import React, { useState, useContext } from "react";
import { Card, Typography, Tabs, Tab, SvgIcon } from "@mui/material";

import ApplicationsList from "./ApplicationsList";
import { basicTone0, basicTone700 } from "../../shared/styles/constants";
import { WidgetsColorIconSVG } from "../../assets/icons/WidgetsColorIconSVG";
import { TropheyColorIconSVG } from "../../assets/icons/TropheyColorIconSVG";
import { ClockColorIconSVG } from "../../assets/icons/ClockColorIconSVG";
import { DistributingColorIconSVG } from "../../assets/icons/DistributingColorIconSVG";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { DashboardContext } from "../../shared/providers/dashboard-provider";

export const UserApplicationsCard = () => {
  const { applications } = useContext(DashboardContext);
  const {
    signUpFeaturedProjects,
    fundingFeaturedProjects,
    waitingFeaturedProjects,
    distributingFeaturedProjects,
  } = applications;
  const { isLightMode } = useContext(ThemeModeContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Card
      sx={{
        padding: { xs: "24px", md: "40px", height: "100%" },
        background: isLightMode ? undefined : basicTone700,
      }}
    >
      <Typography variant="h5" fontSize={"20px"} color="secondary">
        Your applications:
      </Typography>
      <Tabs
        onChange={handleChangeTab}
        aria-label="ProjectInfoTabs"
        value={selectedTab}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          marginTop: "16px",
          "& .MuiTabs-scrollButtons": {
            color: isLightMode ? basicTone700 : basicTone0,
          },
        }}
        textColor="primary"
      >
        <Tab
          icon={
            <SvgIcon>
              <WidgetsColorIconSVG />
            </SvgIcon>
          }
          iconPosition="start"
          label={`Applied`}
        />
        <Tab
          icon={
            <SvgIcon>
              <TropheyColorIconSVG />
            </SvgIcon>
          }
          iconPosition="start"
          label={`Won`}
        />
        <Tab
          icon={
            <SvgIcon>
              <ClockColorIconSVG />
            </SvgIcon>
          }
          iconPosition="start"
          label={`Waiting`}
        />
        <Tab
          icon={
            <SvgIcon>
              <DistributingColorIconSVG />
            </SvgIcon>
          }
          iconPosition="start"
          label={`Distributing`}
        />
      </Tabs>
      {selectedTab === 0 && <ApplicationsList apps={signUpFeaturedProjects} />}
      {selectedTab === 1 && <ApplicationsList apps={fundingFeaturedProjects} />}
      {selectedTab === 2 && <ApplicationsList apps={waitingFeaturedProjects} />}
      {selectedTab === 3 && (
        <ApplicationsList apps={distributingFeaturedProjects} />
      )}
    </Card>
  );
};
export default UserApplicationsCard;
