import { Typography, useMediaQuery, Theme } from "@mui/material";
import React, { CSSProperties, useContext } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {
  basicTone200,
  basicTone400,
  basicTone50,
  basicTone500,
  mainLinearGradient,
  secondaryColor1,
  whiteTone,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { StepperRound } from "./utils";

interface RoundsStepperProps {
  rounds: StepperRound[];
}

export const RoundsStepper = ({ rounds }: RoundsStepperProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const getActiveRounds = () =>
    rounds.filter((r) => r.isActive || r.isCompleted).length;

  const WrapperStyles = {
    width: isMobile ? "100%" : "40%",
  };
  const BorderContainerStyles = {
    padding: "1px",
    backgroundColor: isLightMode ? whiteTone : basicTone500,
    borderRadius: 20,
    border: isLightMode ? `1px solid ${basicTone50}` : "none",
  };
  const ProgressBarStyles = {
    height: 5,
    borderRadius: 20,
    margin: 4,
  };

  const CheckedRoundsStyles = {
    height: "100%",
    width: `${(100 / rounds.length) * getActiveRounds()}%`,
    background: mainLinearGradient,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
  };

  const CheckIconStyles = {
    color: secondaryColor1,
    width: `${100 / getActiveRounds()}%`,
    display: "flex",
    justifyContent: "center",
  };

  const RoundTitleStyles: CSSProperties = {
    color: basicTone400,
    width: `${100 / rounds.length}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const RoundsContainer = {
    display: "flex",
    marginTop: "8px",
  };

  const titleColor = (round: StepperRound) => {
    switch (isLightMode) {
      case true:
        return round.isActive ? "secondary" : basicTone200;
      case false:
        return round.isActive ? "secondary" : "info";
    }
  };

  return (
    <div style={WrapperStyles}>
      <div style={BorderContainerStyles}>
        <div style={ProgressBarStyles}>
          <div style={CheckedRoundsStyles}>
            {rounds.map(
              (round) =>
                (round.isActive || round.isCompleted) && (
                  <div key={round.id} style={CheckIconStyles}></div>
                )
            )}
          </div>
        </div>
      </div>
      <div style={RoundsContainer}>
        {rounds.map((round) => (
          <div key={round.id} style={RoundTitleStyles}>
            {round.isCompleted && !round.isActive && (
              <CheckRoundedIcon
                sx={{ width: "14px", marginBottom: "2px" }}
                htmlColor={secondaryColor1}
              />
            )}
            <Typography
              variant="body2"
              fontWeight={800}
              color={titleColor(round)}
            >
              {round.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoundsStepper;
