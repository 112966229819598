import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IShowOffFeaturedProject } from "../../../../models/project.model";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone450,
  basicTone600,
  basicTone700,
  dropShadowBG,
  grayWhtTone,
  mainBorderRadius,
  mainColor2,
  whiteTone,
} from "../../../../shared/styles/constants";
import FeaturedProjectStatus from "./FeaturedProjectStatus";

export const ShowOffProjectCard = ({
  coverUrl,
  logoUrl,
  showOffUrl,
  name,
  id,
}: IShowOffFeaturedProject) => {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Link
      to={`${showOffUrl}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "none",
      }}
    >
      <Card
        sx={{
          padding: "12px",
          height: "100%",
          minWidth: "274px",
          position: "relative",
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          "&:hover": {
            backgroundColor: isLightMode ? whiteTone : basicTone600,
            border: `1px solid ${isLightMode ? grayWhtTone : basicTone450}`,
            boxShadow: isLightMode ? "none" : dropShadowBG,
          },
        }}
      >
        <CardMedia
          component={"img"}
          image={coverUrl!}
          alt={`featured-project-${id}`}
          sx={{
            borderTopLeftRadius: mainBorderRadius,
            borderTopRightRadius: mainBorderRadius,
            height: "192px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))",
          }}
        />

        <CardContent
          sx={{
            height: "calc(100% - 224px)",
            padding: "0",
            gap: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FeaturedProjectStatus status={"INVESTED"} />

          <Grid
            container
            gap={1}
            alignItems="space-between"
            height={"100%"}
            sx={{ padding: "16px 8px 0px" }}
          >
            <Grid container gap={1}>
              <Grid container gap={2} flexWrap={"nowrap"} alignItems={"center"}>
                <img
                  src={logoUrl!}
                  alt={`logo-project-${id}`}
                  style={{ width: "24px", height: "24px" }}
                />
                <Typography variant="h4" color={mainColor2}>
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ShowOffProjectCard;
