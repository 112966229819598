import {
  Typography,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined'

import React, { useContext } from 'react'
import { basicTone100 } from '../../shared/styles/constants'
import { ThemeModeContext } from '../../shared/providers/theme-mode-provider'

export const PrivacyPolicyPage = () => {
  const { isLightMode } = useContext(ThemeModeContext)

  return (
    <div className='Content'>
      <Stack
        gap={4}
        paddingTop={3}
        paddingBottom={8}
        margin={'0 auto'}
        paddingRight={{ xs: 2, sm: 6, md: 8 }}
        paddingLeft={{ xs: 2, sm: 6, md: 8 }}
      >
        <Stack gap={1}>
          <Typography variant='h1' color='secondary'>
            Privacy Policy of CollectiveHub{' '}
          </Typography>
          {/* <Typography
          fontWeight={700}
          fontSize={"16px"}
          lineHeight={"100%"}
          letterSpacing={"-0.02em"}
        >
          Updated 22.06.2023
        </Typography> */}
        </Stack>
        <Divider />

        <Stack gap={1}>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            This is the Privacy Policy of CollectiveHub, the owner and operator
            of CollectiveHub.com. This policy outlines how we handle personal
            information of end users, which we collect as a result of your use
            of CollectiveHub.io, our websites at https://collectivehub.io, and
            any pages or websites under the "CollectiveHub" brand, including any
            tablet or mobile phone applications that we own and/or operate, or
            any other interactions with you. Our commitment is to protect your
            personal information and ensure that we use, store, and disclose it
            in compliance with the Privacy Act and this Privacy Policy.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Website Use
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            By using this website, you acknowledge and agree to the terms
            outlined in this Privacy Policy. CollectiveHub.io reserves the right
            to review and modify this policy at any time, and changes will
            become effective immediately upon being posted on the website. Your
            continued use of the website following any modifications to this
            policy will constitute your acceptance of the revised terms.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Please note that this Privacy Policy only applies to our website and
            not to any linked websites that are outside of CollectiveHub.io's
            control.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Personal Information
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The kinds of Personal Information that CollectiveHub.io may collect
            from you is:
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontStyle={'italic'}
          >
            Name
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontStyle={'italic'}
          >
            Email address
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontStyle={'italic'}
          >
            Personally submitted preferences
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontStyle={'italic'}
          >
            Location data
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontStyle={'italic'}
          >
            IP address
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Collection Purposes
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub.io is committed to collecting your Personal
            Information in a fair and lawful manner, and in a way that respects
            your privacy. We collect this information in order to provide you
            with our services, and for the following purposes:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To keep you informed about the technical progress of
                CollectiveHub, and to provide you with regular updates about our
                products and services.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To identify you as a user of CollectiveHub for security
                purposes, and to comply with our legal obligations.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To maintain your user account, and to enable us to provide you
                with tailored information, services, or products that meet your
                needs.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To create aggregate data about our users through demographic
                profiling and statistical analysis, in order to provide
                potential and existing CollectiveHub users with more efficient
                service.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To comply with CollectiveHub.io's legal and statutory
                obligations, and to establish, exercise or defend any legal
                claims.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <ArrowRightOutlinedIcon color='secondary' />
              </ListItemIcon>
              <Typography
                fontSize={'16px'}
                textAlign={'justify'}
                color={isLightMode ? 'secondary' : basicTone100}
              >
                To manage job applications.
              </Typography>
            </ListItem>
          </List>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            We may communicate with you about our products or services that we
            think may be of interest to you by email, SMS or telephone. We may
            also use your Personal Information to conduct market research or
            promotions, and to optimize your user experience on our website or
            over the telephone.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            You have the option to deal with us anonymously or to use a
            pseudonym, unless it is impracticable for us to deal with
            unidentified individuals, or if we are required by law to deal only
            with identified individuals. If you choose not to provide
            CollectiveHub.io with the Personal Information we request, we may be
            unable to process your request to become a user, or provide you with
            our services.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Examples of Personal Information that we may collect include your
            name, email address, telephone numbers, date of birth, and other
            verification information such as your Service usage activities.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            To better understand the needs of our users and to optimize their
            experience on our website, we use Hotjar. Hotjar is a technology
            service that helps us understand how our users interact with our
            website. It collects data on our users' behavior and devices,
            including IP address, device screen size, browser information,
            geographic location (country only), and preferred language. Hotjar
            stores this information on our behalf in a pseudonymized user
            profile, and is contractually prohibited from selling any of the
            data collected. For more information about Hotjar, please see the
            "About Hotjar" section of their support site.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Direct Marketing and Opting Out
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            At times, we may use your Personal Information to keep you informed
            about CollectiveHub.io's products or services or any promotional
            activities that we believe may interest or benefit you. We may
            communicate with you via email, SMS, telephone, or mail. If you no
            longer wish to receive any marketing or promotional material from
            us, or if you wish to limit how we contact you, you may contact us
            at support@collectivepad.com at any time to make such a request. We
            will comply with your request as soon as reasonably practicable.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            We may also contact you from time to time in relation to the
            management and administration of your CollectiveHub.io account. We
            may use any of the contact methods you provided when registering as
            a User to communicate with you. However, such communication does not
            affect your opt-in or opt-out status for receiving direct marketing
            communications.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Management of your Personal Information
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub.io is committed to maintaining the accuracy and
            security of the Personal Information it collects, uses, and
            discloses. We will take reasonable measures to ensure that your
            Personal Information is accurate, complete, and up-to-date. We will
            also ensure that your Personal Information is stored in a secure
            environment that is accessed only by authorized personnel.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            When we no longer require your Personal Information for any purpose
            permitted under applicable privacy laws, including our legal or
            operational obligations, we will securely dispose of or permanently
            de-identify the information we hold.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            We believe in safeguarding the privacy of your Personal Information,
            and we will continue to review and update our practices to ensure we
            maintain the highest standards of privacy protection.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Security of Personal Information
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            It is acknowledged that no data transmission over the internet can
            be completely secure. Thus, CollectiveHub.io cannot guarantee the
            security of any information you transmit to the website.
            Nevertheless, CollectiveHub.io will take reasonable measures to
            protect your Personal Information from unauthorized access,
            modification, misuse, and loss, including through the use of
            password-protected systems and databases and Secure Socket Layer
            (SSL) technology, upon receipt of the information. CollectiveHub.io
            obliges its employees, agents, and contractors to maintain the
            confidentiality of Users’ Personal Information and trading behavior.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Please note that information posted on bulletin boards or
            communicated within a social media environment becomes public
            information, and as such, CollectiveHub.io cannot guarantee its
            security. Furthermore, we take the responsibility of excluding
            children from our services seriously and do not accept their
            information for accessing or using the Service. However, it is
            ultimately the responsibility of parents or guardians to monitor
            their children’s internet activities, including the use of internet
            screening software.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            We advise you to always log out of your account when finished using
            the website, particularly if you share a computer with another
            person. You are solely responsible for the security and access to
            your own computer, mobile device, or any other handset used to
            access the website.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Ultimately, the secrecy of your username, password, and account
            information is your sole responsibility. Therefore, please exercise
            caution when using the internet and our website.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Access to Personal Information
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If you would like to access your Personal Information that we have
            collected, please send us a request at support@collectivepad.com. We
            will try to provide you with access to your Personal Information in
            the manner that you have requested within a reasonable time frame.
            If we are unable to provide access to your Personal Information in
            the manner you requested, we will explore alternative methods to
            meet your requirements. However, there may be circumstances where we
            are not required to provide you access to your Personal Information
            under the APPs, such as when it would unreasonably impact other
            individuals' privacy. If we are unable to provide you access due to
            such reasons, we will notify you in writing, providing the reasons
            for our refusal and information about the complaint mechanisms
            available to you.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Use of Website
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            By accessing and using this website, you acknowledge and consent to
            the collection and processing of your Personal Information by
            CollectiveHub.io in accordance with the terms and conditions set
            forth in this Privacy Policy.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Contact Details
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If you have any questions, requests for access or correction, or
            complaints regarding the handling of your personal information,
            please do not hesitate to contact us via email.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            support@collectivepad.com
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Updates to this Privacy Policy
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub.io reserves the right to revise and update this
            Privacy Policy from time to time in order to comply with legal
            obligations and reflect current practices. The most current version
            of the Privacy Policy will be available on our website at
            www.CollectiveHub.io, and any changes will be effective upon
            posting. You are advised to regularly review the Privacy Policy to
            remain informed of any updates or changes.
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}

export default PrivacyPolicyPage
