import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { AccountContext } from "../../shared/providers/account-provider";
import { useNavigate } from "react-router-dom";
import { signInInitialValues, SignInValues } from "./register-utils";
import { basicTone200 } from "../../shared/styles/constants";

export const SignIn = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { handleSignIn, signInError, setSignInError } =
    useContext(AccountContext);
  const navigate = useNavigate();

  return (
    <Stack gap={2}>
      <Typography variant="h2" color="secondary">
        Sign in
      </Typography>
      <Typography variant="body1">Access your account</Typography>

      <Formik
        initialValues={signInInitialValues}
        onSubmit={(values: SignInValues, { setSubmitting }) => {
          handleSignIn(values, () => navigate("/"));
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          dirty,
          touched,
          handleSubmit,
        }) => {
          return (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="email"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setSignInError(false);
                    handleChange(e);
                  }}
                  required
                  placeholder="E-mail"
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonRoundedIcon
                        fontSize="small"
                        color={values.email?.length ? "secondary" : "info"}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  color="primary"
                  name="password"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setSignInError(false);
                    handleChange(e);
                  }}
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  startAdornment={
                    <InputAdornment position="start">
                      <LockRoundedIcon
                        fontSize="small"
                        color={values.password?.length ? "secondary" : "info"}
                      />
                    </InputAdornment>
                  }
                  
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffRoundedIcon
                            fontSize="small"
                            color={
                              values.password?.length ? "secondary" : "info"
                            }
                          />
                        ) : (
                          <VisibilityRoundedIcon
                            fontSize="small"
                            color={
                              values.password?.length ? "secondary" : "info"
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {signInError ? (
                <FormHelperText error>
                  *Wrong e-mail or password.
                </FormHelperText>
              ) : null}

              <Button
                variant="contained"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !!Object.values(errors).length}
                sx={{ height: "48px", marginTop: "8px" }}
              >
                Log in
              </Button>
              
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
export default SignIn;
