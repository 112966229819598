import React, { useContext } from "react";
import { Card, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import {
  basicTone0,
  basicTone100,
  basicTone400,
  basicTone50,
  basicTone700,
  darkLinearGradient,
  secondaryBorderRadius,
  secondaryColor1,
} from "../../../shared/styles/constants";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { DiscordSVG } from "../../../assets/DiscordSVG";
import { MediumIconSVG } from "../../../assets/icons/MediumIconSVG";

type SocialMediaCardProps = {
  socialMedia: {
    twitterUrl?: string;
    discordUrl?: string;
    youtubeUrl?: string;
    telegramUrl?: string;
    mediumUrl?: string;
  };
};

export const SocialMediaCard = ({ socialMedia }: SocialMediaCardProps) => {
  const { twitterUrl, discordUrl, youtubeUrl, telegramUrl, mediumUrl } =
    socialMedia;
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Card sx={{ padding: "24px 36px 48px" }}>
      <Grid container gap={2}>
        <Grid item container alignItems="center" gap={2}>
          <StopRoundedIcon htmlColor={secondaryColor1} fontSize="small" />
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={700}
            fontSize="20px"
          >
            Follow
          </Typography>
        </Grid>

        <Grid container gap={3}>
          {twitterUrl && (
            <Tooltip title="Twitter" placement="top">
              <IconButton
                component="a"
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "70px",
                  background: isLightMode ? basicTone50 : darkLinearGradient,
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone400
                  }`,
                  borderRadius: secondaryBorderRadius,
                }}
                color="secondary"
              >
                <TwitterIcon />
              </IconButton>
            </Tooltip>
          )}
          {discordUrl && (
            <Tooltip title="Discord" placement="top">
              <IconButton
                component="a"
                href={discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "70px",
                  background: isLightMode ? basicTone50 : darkLinearGradient,
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone400
                  }`,
                  borderRadius: secondaryBorderRadius,
                }}
                color="secondary"
              >
                <DiscordSVG color={isLightMode ? basicTone700 : basicTone0} />
              </IconButton>
            </Tooltip>
          )}
          {telegramUrl && (
            <Tooltip title="Telegram" placement="top">
              <IconButton
                component="a"
                href={telegramUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "70px",
                  background: isLightMode ? basicTone50 : darkLinearGradient,
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone400
                  }`,
                  borderRadius: secondaryBorderRadius,
                }}
                color="secondary"
              >
                <TelegramIcon />
              </IconButton>
            </Tooltip>
          )}
          {mediumUrl && (
            <Tooltip title="Medium" placement="top">
              <IconButton
                component="a"
                href={mediumUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "70px",
                  background: isLightMode ? basicTone50 : darkLinearGradient,
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone400
                  }`,
                  borderRadius: secondaryBorderRadius,
                }}
                color="secondary"
              >
                <MediumIconSVG
                  color={isLightMode ? basicTone700 : basicTone0}
                />
              </IconButton>
            </Tooltip>
          )}
          {youtubeUrl && (
            <Tooltip title="YouTube" placement="top">
              <IconButton
                component="a"
                href={youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "70px",
                  background: isLightMode ? basicTone50 : darkLinearGradient,
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone400
                  }`,
                  borderRadius: secondaryBorderRadius,
                }}
                color="secondary"
              >
                <YouTubeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default SocialMediaCard;
