import React, { useState } from "react";
import { Grid, Typography, Stack } from "@mui/material";

import CreditCardSteps from "./DepositSteps/CreditCardSteps";
import DepositWayStep from "./DepositSteps/DepositWayStep";
import WalletSteps from "./DepositSteps/WalletSteps";

export const DepositContent = () => {
  const [depositWay, setDepositWay] = useState<"wallet" | "card" | undefined>(
    undefined
  );
  return (
    <Grid paddingTop={4}>
      <Stack gap={2}>
        <Typography variant="h4" color="secondary">
          Deposit
        </Typography>
        <Stack gap={2}>
          <DepositWayStep
            depositWay={depositWay}
            setDepositWay={(depWay: "wallet" | "card" | undefined) => {
              setDepositWay(depWay);
            }}
          />
          {depositWay === "card" && <CreditCardSteps />}
          {depositWay === "wallet" && <WalletSteps />}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default DepositContent;
