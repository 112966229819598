import React, { useContext, useState } from "react";
import { Grid, Stack, Typography, Button, OutlinedInput } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import { MailColorIconSVG } from "../../../assets/icons/MailColorIconSVG";
import { LoadingButton } from "@mui/lab";
import {
  basicTone100,
  basicTone200,
  basicTone500,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { WalletContext } from "../../../shared/providers/wallet-provider";

export const WithdrawConfirmationCode = () => {
  const {
    withdrawData,
    isSendEmailLoading,
    withdrawLoading,
    handleSubmitWithdraw,
    handleSendEmailCodeWithdraw,
  } = useContext(WalletContext);

  const { isLightMode } = useContext(ThemeModeContext);
  const [codeSplited, setCodeSplited] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const onPaste = (ev: any) => {
    ev.preventDefault();
    const pasted = ev.clipboardData.getData("text/plain");

    setCodeSplited(pasted.split("".slice(0, codeSplited.length)));
  };

  const handleSubmit = () => {
    const authCode = codeSplited.join("");
    if (authCode.length === 6) {
      handleSubmitWithdraw(parseInt(authCode));
    }
  };

  const resendEmail = () => {
    withdrawData && handleSendEmailCodeWithdraw(withdrawData);
  };
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        sx={{ padding: { xs: "70px 0px 0", sm: "70px 60px 0" } }}
      >
        <Stack gap={3} alignItems="center">
          <MailColorIconSVG />
          <Typography
            variant="h2"
            textAlign={"center"}
            color="secondary"
          >{`We’ve just send the confirmation code to your e-mail.`}</Typography>
          <Grid container justifyContent={"center"} gap={{ xs: 0.5, sm: 1 }}>
            {codeSplited.map((inputPlace, key) => (
              <OutlinedInput
                key={key}
                value={inputPlace}
                onPaste={onPaste}
                color="primary"
                placeholder="0"
                sx={{
                  width: "50px",
                  height: "65px",
                  backgroundColor: isLightMode ? basicTone100 : basicTone500,
                  fontSize: "20px",
                }}
                onChange={(ev) =>
                  setCodeSplited(
                    codeSplited.map((e, i) =>
                      i === key
                        ? ev.target.value.length
                          ? ev.target.value[ev.target.value.length - 1]
                          : ""
                        : e
                    )
                  )
                }
              />
            ))}
          </Grid>
          <LoadingButton
            color="primary"
            onClick={handleSubmit}
            loadingPosition="start"
            loading={withdrawLoading}
            startIcon={<AccountBalanceWalletIcon />}
            variant="contained"
            sx={{ height: "48px" }} // sx=
          >
            <span>Authorize payment</span>
          </LoadingButton>
        </Stack>
      </Grid>
      <Grid container justifyContent={"center"} sx={{ marginTop: "106px" }}>
        <Button
          color="secondary"
          variant="contained"
          // loadingPosition="start"
          // loading={isSendEmailLoading}
          disabled={isSendEmailLoading}
          onClick={resendEmail}
          sx={{
            "&.Mui-disabled": {
              color: isLightMode ? basicTone200 : basicTone100,
            },
          }}
        >
          {isSendEmailLoading
            ? "Try again in 60 seconds..."
            : `I haven't received an e-mail`}
        </Button>
      </Grid>
    </>
  );
};

export default WithdrawConfirmationCode;
