import {
  Grid,
  Stack,
  Typography,
  Divider,
  Card,
  Skeleton,
} from "@mui/material";
import { useCall } from "@usedapp/core";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone200,
  basicTone50,
  linearBlackBG,
  mainColor2,
  secondaryBorderRadius,
} from "../../shared/styles/constants";
import BidOrdersList from "./BidOrdersList";
import AskOrdersList from "./AskOrdersList";
import AccountInfoCard from "./AccountInfoCard";
import { AccountContext } from "../../shared/providers/account-provider";
import { useContracts } from "../../hooks";
import MyOrdersList from "./MyOrdersList";
import Activity from "./Activity";

export const MarketTokenPage = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { isConnectedToMetamask } = useContext(AccountContext);
  const { tokenAddress } = useParams();

  const { stableCoinContract } = useContracts({
    stableCoinAddress: tokenAddress,
  });
  const { value: symbol, error: symbolError } =
    useCall({
      contract: stableCoinContract,
      method: "symbol",
      args: [],
    }) ?? {};

  return (
    <Stack
      gap={7}
      paddingTop={4}
      paddingBottom={8}
      paddingRight={1}
      paddingLeft={1}
    >
      <Stack gap={1}>
        {symbol ? (
          <Card
            sx={{
              padding: { xs: "24px", md: "20px 16px 20px 90px" },
              marginBottom: "16px",
            }}
          >
            <Stack gap={3}>
              <Typography variant="h1" color={mainColor2}>
                {symbol}
              </Typography>
              <Stack direction={"row"} gap={6}>
                <Stack>
                  <Typography variant="body2" color={basicTone200}>
                    Sellers:
                  </Typography>
                  <Typography variant="h5" color={"secondary"} fontWeight={700}>
                    560
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={basicTone200}>
                    Volume:
                  </Typography>
                  <Typography variant="h5" color={"secondary"} fontWeight={700}>
                    236k
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={basicTone200}>
                    Dollar volume:
                  </Typography>
                  <Typography variant="h5" color={"secondary"} fontWeight={700}>
                    2M
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={basicTone200}>
                    Daily volume:
                  </Typography>
                  <Typography variant="h5" color={"secondary"} fontWeight={700}>
                    23k
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        ) : (
          <Skeleton
            variant="rounded"
            width={"100%"}
            height="163px"
            sx={{
              background: isLightMode ? basicTone50 : linearBlackBG,
              borderRadius: secondaryBorderRadius,
            }}
          />
        )}
        {/* MY ACTIONS */}
        {isConnectedToMetamask && <AccountInfoCard />}
      </Stack>
      <Divider />
      <Activity />
      <Divider />
      {tokenAddress ? (
        <Grid container spacing={2}>
          {/* OTHERS WANT TO BUY */}
          <BidOrdersList tokenAddress={tokenAddress} />
          {/* OTHERS SELL */}
          <AskOrdersList tokenAddress={tokenAddress} />
        </Grid>
      ) : null}
      <Divider />
      <MyOrdersList />
    </Stack>
  );
};

export default MarketTokenPage;
