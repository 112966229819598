import { useEffect, useContext, useState } from "react";
import { Grid, Stack, Typography, ListItem } from "@mui/material";
import { MARKETPLACE_MATIC_ADDRESS } from "../../config/MarketPlaceAbi";
import { useContracts } from "../../hooks";
import { useLogs } from "@usedapp/core";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainBorderRadius,
} from "../../shared/styles/constants";
import {
  mapOrdersListResponse,
  shortFormatAddress,
} from "./ordersModals/ordersModals.utils";
import { TOrder } from "./types";

export const Activity = () => {
  const { tokenAddress } = useParams();

  const { isLightMode } = useContext(ThemeModeContext);
  const [activityList, setActivityList] = useState<TOrder[]>([]);
  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: MARKETPLACE_MATIC_ADDRESS,
  });

  const logs = useLogs(
    {
      contract: marketplaceContract,
      event: "OrderAccepted",
      args: [],
    },
    { fromBlock: 0, toBlock: "latest" }
  );

  useEffect(() => {
    if (logs?.value) {
      const result = logs.value.map((item) => item?.data?.askOrder);
      setActivityList(mapOrdersListResponse(result));
    }
    if (logs?.error) {
      console.log(logs.error);
    }
  }, [logs]);

  return (
    <Stack gap={2}>
      <Typography variant="h3" color="secondary">
        Activity
      </Typography>

      <Stack gap={0.5}>
        <ListItem sx={{ padding: "8px 12px 0" }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={2} container alignItems={"center"}>
              <Typography variant="h6">{`Type`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Quantity`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Price per token`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Full price`}</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="h6">{`Seller`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`Buyer`}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {activityList.map((item) => (
          <ListItem
            key={`${item.id.toString()}${item.orderType}`}
            sx={{
              border: `1px solid ${isLightMode ? basicTone100 : basicTone500}`,
              filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,
              borderRadius: mainBorderRadius,
              padding: "2px 12px",
              background: isLightMode ? basicTone0 : basicTone600,
              "&:hover": {
                background: isLightMode ? basicTone50 : basicTone500,
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={2} container alignItems={"center"}>
                <Typography variant="body1" fontWeight={700} color="secondary">
                  {`${item.orderType}`}
                </Typography>
              </Grid>
              <Grid item xs={2} container alignItems={"center"}>
                <Typography variant="body1" fontWeight={700} color="secondary">
                  {`${item.quantity}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {`$${item.pricePerToken}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {`$${item.fullPrice}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {shortFormatAddress(item.seller)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"secondary"}
                >
                  {shortFormatAddress(item.buyer)}
                </Typography>
                {/* <Button
                  variant="outlined"
                  disabled={item.sold}
                  onClick={() => setOpenCancelDialog(true)}
                >
                  Cancel
                </Button>
                 <CancelOrderConfirmationDialog
                  open={openCancelConfirmationDialog}
                  handleClose={() => setOpenCancelDialog(false)}
                  orderType={item.orderType}
                  loadingCancel={loadingCancelOrder}
                  handleCancelOrder={() =>
                    handleCancelOrder(
                      item.orderType,
                      item.id,
                      item.fundingTermsAddress
                    )
                  }
                />  */}
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};

export default Activity;
