import { Button, Card, Grid, Typography } from "@mui/material";

import { useEffect, useState, useContext } from "react";
import { useUserRoundSignUpMutation } from "../../../services/featuredProjectsApi";
import { AccountContext } from "../../../shared/providers/account-provider";
import IncreaseChancesOnSignUpModal, {
  SocialMediaCounter,
} from "./IncreaseChancesOnSignUpModal";

export type SignUpProps = {
  projectTitle: string;
  isAlreadySignedUp: boolean;
  fundingRoundId?: string;
  socialMedia: {
    twitterUrl: string | undefined;
    discordUrl: string | undefined;
    telegramUrl: string | undefined;
  };
};

export const SignUp = ({
  fundingRoundId,
  isAlreadySignedUp,
  projectTitle,
  socialMedia,
}: SignUpProps) => {
  const { account } = useContext(AccountContext);
  const [signUp] = useUserRoundSignUpMutation();
  const [isSignedUp, setIsSignedUp] = useState(isAlreadySignedUp);

  const emailIsValidated = !!localStorage.getItem("emailValidated");
  const [increaseChancesModalOpen, setIncreaseChancesModalOpen] =
    useState(false);

  useEffect(() => {
    setIsSignedUp(isAlreadySignedUp);
  }, [isAlreadySignedUp]);

  const handleSignUp = (
    socialMediaCounter: SocialMediaCounter,
    telegramUser: string,
    handleDiscord: string,
    handleTwitter: string
  ) => {
    const token = localStorage.getItem("token");
    if (token && fundingRoundId) {
      signUp({
        fundingRound: { fundingRoundId },
        token,
        socialMediaCounter,
        telegramUser,
        handleDiscord,
        handleTwitter,
      })
        .unwrap()
        .then((data: any) => {
          if (data.result === "success" || isAlreadySignedUp) {
            setIsSignedUp(true);
          }
        });
    }
  };

  const renderCardContent = emailIsValidated ? (
    <Typography variant="body1" textAlign={"center"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  ) : (
    <Typography variant="body1" textAlign={"center"} fontWeight={500}>
      {`You can't sign up to this project because you haven't validated your e-mail yet. Please go to your account and validate your e-mail.`}
    </Typography>
  );

  return (
    <Card
      sx={{
        padding: "36px",
      }}
    >
      {
        <Grid container justifyContent={"center"} gap={2}>
          {account ? (
            isSignedUp ? (
              <Typography variant="body1">
                You already signed up to this round.
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h5"
                  color="secondary"
                  fontWeight={800}
                  fontSize="20px"
                  textAlign={"center"}
                >
                  Interested in supporting {projectTitle} project?
                </Typography>

                {renderCardContent}

                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setIncreaseChancesModalOpen(true)} //{handleSignUp}
                  sx={{ height: "48px" }}
                  disabled={!emailIsValidated}
                >
                  SIGN UP
                </Button>
                <IncreaseChancesOnSignUpModal
                  open={increaseChancesModalOpen}
                  handleClose={() => setIncreaseChancesModalOpen(false)}
                  socialMedia={socialMedia}
                  projectTitle={projectTitle}
                  handleSignUp={handleSignUp}
                />
              </>
            )
          ) : (
            <Typography variant="body1">
              You need to be authenticated before signing up.
            </Typography>
          )}
        </Grid>
      }
    </Card>
  );
};

export default SignUp;
