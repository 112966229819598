export const LogoutIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2145 16.7727V12.6364H10.3885C10.218 12.6364 10.0545 12.5693 9.93392 12.45C9.81336 12.3306 9.74563 12.1688 9.74563 12C9.74563 11.8312 9.81336 11.6694 9.93392 11.55C10.0545 11.4307 10.218 11.3636 10.3885 11.3636H16.2145V7.22727C16.2139 6.63676 15.9766 6.07061 15.5548 5.65305C15.1329 5.23549 14.561 5.00063 13.9645 5H6.25004C5.65349 5.00063 5.08155 5.23549 4.65973 5.65305C4.2379 6.07061 4.00064 6.63676 4 7.22727V16.7727C4.00064 17.3632 4.2379 17.9294 4.65973 18.3469C5.08155 18.7645 5.65349 18.9994 6.25004 19H13.9645C14.561 18.9994 15.1329 18.7645 15.5548 18.3469C15.9766 17.9294 16.2139 17.3632 16.2145 16.7727ZM19.8053 12.6364L17.6887 14.732C17.5732 14.8523 17.5098 15.0125 17.5119 15.1784C17.5141 15.3444 17.5816 15.5029 17.7002 15.6203C17.8187 15.7376 17.9789 15.8045 18.1465 15.8066C18.3141 15.8087 18.476 15.746 18.5975 15.6316L21.8119 12.4498C21.9323 12.3305 22 12.1687 22 12C22 11.8313 21.9323 11.6695 21.8119 11.5502L18.5975 8.36835C18.476 8.25405 18.3141 8.19127 18.1465 8.19339C17.9789 8.19552 17.8187 8.26238 17.7002 8.37973C17.5816 8.49707 17.5141 8.65562 17.5119 8.82156C17.5098 8.98749 17.5732 9.1477 17.6887 9.26801L19.8053 11.3636H16.2145V12.6364H19.8053Z"
      fill="#515154"
    />
  </svg>
);
