import React from "react";
import { Grid, Typography, Card, Stack, StepContent } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { basicTone450 } from "../../shared/styles/constants";

export const CustomVerticalStepContent = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <Grid
      sx={{
        paddingLeft: "30px",
        borderLeft: `1px solid ${basicTone450}`,
        marginLeft: "6px",
      }}
    >
      {props.children}
    </Grid>
  );
};

export default CustomVerticalStepContent;
