import * as Yup from "yup";
import {
  TBidOrder,
  TBidOrderResponseItem,
  TAskOrder,
  TAskOrderResponseItem,
  TOrder,
  TOrderResponseItem,
} from "../types";
import BigNumber from "bignumber.js";

export const createBidOrderValidationSchema = Yup.object().shape({
  quantity: Yup.number().required("Please enter tokens quantity."),
  fullPrice: Yup.number().required("Please add the price!"),
  pricePerToken: Yup.number().required("Please add the price!"),
  payToken: Yup.string(),
});

export const createAskOrderValidationSchema = Yup.object().shape({
  quantity: Yup.number().required("Please enter tokens quantity."),
  fullPrice: Yup.number().required("Please add the price!"),
  pricePerToken: Yup.number().required("Please add the price!"),
  payToken: Yup.string(),
});

export const mapBidOrdersListData = (
  list: TBidOrderResponseItem[]
): TBidOrder[] =>
  list.map((item) => ({
    ...item,
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(1e6)
      .toFixed(),
    id: item.id.toString(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(1e6)
      .toFixed(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
  }));

export const mapAskOrdersListData = (
  list: TAskOrderResponseItem[]
): TAskOrder[] =>
  list.map((item) => ({
    ...item,
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(1e6)
      .toFixed(),
    id: item.id.toString(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(1e6)
      .toFixed(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
  }));

export const mapOrdersListResponse = (list: TOrderResponseItem[]): TOrder[] =>
  list.map((item) => ({
    id: item.id.toString(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(1e6)
      .toFixed(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(1e6)
      .toFixed(),
    sold: item.sold,
    seller: item.seller,
    buyer: item.buyer,
    fundingTermsAddress: item.fundingTermsAddress,
    createdAt: item.createdAt.toString(),
    fulfilledAt: item.fulfilledAt.toString(),
    orderType: item.orderType,
  }));

export const shortFormatAddress = (address: string) => address.slice(-6);
