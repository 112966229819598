import { Avatar, Card, Grid, IconButton, Typography } from "@mui/material";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  basicTone500,
  mainColor2,
  secondaryColor1,
} from "../../../shared/styles/constants";
import AvatarIcon from "../../../assets/Avatar.jpg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { TeamMember } from "./utils";

const PersonaDetails = ({
  name,
  position,
  linkedInURL,
  avatarPhoto,
}: {
  name: string;
  position: string;
  linkedInURL?: string;
  avatarPhoto?: string;
}) => (
  <Grid
    container
    gap={1}
    justifyContent="space-between"
    flexWrap={"nowrap"}
    alignItems="center"
  >
    <Grid container item alignItems={"center"} gap={2}>
      <Grid item>
        <Avatar
          variant="rounded"
          alt="person"
          src={avatarPhoto || AvatarIcon}
          sx={{ width: "48px", height: "48px" }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h5" color="secondary">
          {name}
        </Typography>
        <Typography variant="body1" color={mainColor2}>
          {position}
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      <IconButton
        component="a"
        href={linkedInURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon fontSize="large" htmlColor={basicTone500} />
      </IconButton>
    </Grid>
  </Grid>
);

type TeamCardProps = {
  teamMembers?: TeamMember[];
};

export const TeamCard = ({ teamMembers }: TeamCardProps) => {
  return (
    <Card sx={{ padding: "24px 36px 48px" }}>
      <Grid container gap={2}>
        <Grid item container alignItems="center" gap={2}>
          <StopRoundedIcon htmlColor={secondaryColor1} fontSize="small" />
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={700}
            fontSize="20px"
          >
            Team:
          </Typography>
        </Grid>

        <Grid container gap={4}>
          {teamMembers?.map((member) => (
            <PersonaDetails
              key={member.id}
              name={member.name}
              position={member.position}
              linkedInURL={member.linkedInUrl}
              avatarPhoto={member.photoUrl}
            />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default TeamCard;
