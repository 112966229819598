import { Card, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { IFeaturedProject } from "../../../models/project.model";

import { ProjectOverviewContent } from "./tabs-content/ProjectOverview";
import { TokenomicsContent } from "./tabs-content/Tokenomics";
import { Highlights } from "./tabs-content/Highlights";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const ProjectInfoTabs = (projectData: IFeaturedProject) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Card
      sx={{
        padding: { xs: "24px", md: "26px 90px" },
      }}
    >
      <Tabs
        onChange={handleChangeTab}
        aria-label="ProjectInfoTabs"
        value={selectedTab}
        variant="scrollable"
        scrollButtons="auto"
        textColor="secondary"
      >
        <Tab label={`Highlights`} {...a11yProps(0)} />
        <Tab label={`Overview`} {...a11yProps(1)} />
        <Tab label={`Tokenomics`} {...a11yProps(2)} />
      </Tabs>

      {selectedTab === 0 && <Highlights {...projectData} />}
      {selectedTab === 1 && <ProjectOverviewContent {...projectData} />}
      {selectedTab === 2 && <TokenomicsContent {...projectData} />}
    </Card>
  );
};

export default ProjectInfoTabs;
