import React, { useContext } from "react";
import { Grid, Typography, Card, Stack } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  basicTone400,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone600,
  basicTone800,
  dropShadowSM,
  lightShadow,
  whiteTone,
} from "../../../shared/styles/constants";
import { CreditCardGradientIconSVG } from "../../../assets/icons/CreditCardGradientIconSVG";
import { WalletGradientIconSVG } from "../../../assets/icons/WalletGradientIconSVG";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { DepositWayStepProps } from "../types";
import StepLabel from "../../../components/CustomVerticalStepper/CustomVerticalStepLabel";
import StepContent from "../../../components/CustomVerticalStepper/CustomVerticalStepContent";

export const DepositWayStep = ({
  depositWay,
  setDepositWay,
}: DepositWayStepProps) => {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Stack gap={2}>
      <StepLabel label="Choose deposit way" />
      <StepContent>
        <Grid container gap={1.5}>
          <Card
            onClick={() => setDepositWay("wallet")}
            sx={{
              cursor: "pointer",
              display: "flex",
              padding: "36px 46px",
              border: `1px solid ${isLightMode ? basicTone50 : basicTone500}`,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              boxShadow: isLightMode ? lightShadow : dropShadowSM,
              background:
                depositWay !== "wallet"
                  ? "transparent"
                  : isLightMode
                  ? whiteTone
                  : basicTone800,
              "&:hover": {
                background: isLightMode ? whiteTone : basicTone600,
              },
            }}
          >
            <WalletGradientIconSVG />
            <Typography variant="body2" color="secondary" fontWeight={700}>
              Wallet
            </Typography>
          </Card>
          <Card
            onClick={() => setDepositWay("card")}
            sx={{
              cursor: "pointer",
              display: "flex",
              padding: "36px 46px",
              border: `1px solid ${isLightMode ? basicTone50 : basicTone500}`,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              boxShadow: isLightMode ? lightShadow : dropShadowSM,
              background:
                depositWay !== "card"
                  ? "transparent"
                  : isLightMode
                  ? whiteTone
                  : basicTone800,
              "&:hover": {
                background: isLightMode ? whiteTone : basicTone600,
              },
            }}
          >
            <CreditCardGradientIconSVG />
            <Typography variant="body2" color="secondary" fontWeight={700}>
              Card
            </Typography>
          </Card>
        </Grid>
      </StepContent>
    </Stack>
  );
};
export default DepositWayStep;
