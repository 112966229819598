export const ClockColorIconSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 4C11.4494 4 10.4091 4.20693 9.43853 4.60896C8.46793 5.011 7.58601 5.60028 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C7.58601 18.3997 8.46793 18.989 9.43853 19.391C10.4091 19.7931 11.4494 20 12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 10.9494 20.2931 9.90914 19.891 8.93853C19.489 7.96793 18.8997 7.08601 18.1569 6.34315C17.414 5.60028 16.5321 5.011 15.5615 4.60896C14.5909 4.20693 13.5506 4 12.5 4ZM15.86 15.36L11.7 12.8V8H12.9V12.16L16.5 14.32L15.86 15.36Z"
      fill="#FFC248"
    />
    <path d="M10.5 8H13V13H10.5V8Z" fill="black" />
    <path
      d="M17.0636 14.2894L15.5 16L10.5 13L13 12L17.0636 14.2894Z"
      fill="black"
    />
  </svg>
);
