import React, { useContext } from "react";
import {
  Card,
  Divider,
  Stack,
  Box,
  Grid,
  Avatar,
  Badge,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import {
  basicTone0,
  basicTone200,
  basicTone700,
  mainLinearGradient,
} from "../../shared/styles/constants";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { DashboardContext } from "../../shared/providers/dashboard-provider";
import FormLabel from "@mui/material/FormLabel";
import { LoadingButton } from "@mui/lab";
import AvatarDefault from "../../assets/Avatar.jpg";

export const EditUserProfile = () => {
  const {
    setUserNameValue,
    userNameValue,
    handleEditUserProfile,
    updateUserProfileLoading,
    avatarUrl,
    handleUploadPhoto,
  } = useContext(DashboardContext);

  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Card
      sx={{
        padding: { xs: "24px", md: "40px", height: "100%" },
        background: isLightMode ? undefined : basicTone700,
      }}
    >
      <Stack gap={4}>
        <Grid sx={{ paddingBottom: "16px" }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Box
                sx={{
                  background: mainLinearGradient,
                  border: `5px solid ${basicTone700}`,
                  borderRadius: "10px",
                  marginLeft: "16px",
                  marginTop: "16px",
                }}
              >
                <IconButton aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleUploadPhoto}
                  />
                  <AddAPhotoIcon htmlColor={basicTone0} fontSize="small" />
                </IconButton>
              </Box>
            }
          >
            <Avatar
              src={
                avatarUrl && avatarUrl !== "null"
                  ? avatarUrl || undefined
                  : AvatarDefault
              }
              alt={"avatar1"}
              sx={{ width: "117px", height: "117px" }}
              variant="rounded"
            />
          </Badge>
        </Grid>
        <Divider />
        <Stack gap={1}>
          <FormLabel
            sx={{ color: basicTone200, fontSize: "14px", paddingLeft: "2px" }}
          >
            Username:
          </FormLabel>
          <OutlinedInput
            value={userNameValue !== "null" ? userNameValue : undefined}
            onChange={(e: React.ChangeEvent<any>) =>
              setUserNameValue(e.target.value)
            }
          />
        </Stack>
        <Grid container justifyContent={"center"}>
          <LoadingButton
            color="primary"
            onClick={() => handleEditUserProfile()}
            loading={updateUserProfileLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ height: "48px" }}
          >
            <span>Save changes</span>
          </LoadingButton>
        </Grid>
      </Stack>
    </Card>
  );
};
export default EditUserProfile;
