import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import StepLabel from "../../components/CustomVerticalStepper/CustomVerticalStepLabel";
import StepContent from "../../components/CustomVerticalStepper/CustomVerticalStepContent";
import { SupportedToken } from "./types";

import { SelectChangeEvent } from "@mui/material/Select";
import usdcLogo from "../../assets/icons/USDCLogo.png";
import { LoadingButton } from "@mui/lab";
import { WalletContext } from "../../shared/providers/wallet-provider";

import SelectSymbolSection from "./WithdrawSteps/SelectSymbolSection";

export const WithdrawContent = () => {
  const {
    handleSendEmailCodeWithdraw,
    isSendEmailLoading,
    allSupportedTokens: supportedTokens,
    isWalletAddressValid,
    setIsWalletAddressValid,
  } = useContext(WalletContext);
  const [filteredTokens, setFilteredTokens] = useState<SupportedToken[]>(
    [...supportedTokens].slice(1)
  );
  const [symbol, setSymbol] = useState<string | undefined>(
    supportedTokens[0]?.symbol
  );

  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [walletAddress, setWalletAddress] = useState<string | undefined>(
    undefined
  );

  const [maxValue, setMaxValue] = useState<number>(0);

  const getTokenBySymbol = (currency: string) =>
    supportedTokens.filter((el) => el.symbol === currency)[0];

  const getLogoBySymbol = (currency: string) => {
    if (currency === "USDC") {
      return usdcLogo;
    }
    return supportedTokens.filter((el) => el.symbol === currency)[0]?.logoUrl;
  };

  const onSendEmail = () => {
    const tokenAddress = supportedTokens.filter((el) => el.symbol === symbol)[0]
      ?.address;
    handleSendEmailCodeWithdraw({
      amount: amount?.toString(),
      symbol,
      destinationAddress: walletAddress,
      tokenAddress,
    });
  };
  return (
    <Grid paddingTop={4}>
      <Stack gap={2} justifyContent="center">
        <Typography variant="h4" color="secondary">
          Withdraw
        </Typography>
        <Stack gap={2}>
          <StepLabel label="Choose currency" />
          <StepContent>
            <OutlinedInput
              fullWidth
              type="number"
              color="primary"
              name="amount"
              onChange={(e: React.ChangeEvent<any>) => {
                setAmount(e.target.value);
              }}
              error={!!(amount && (amount > maxValue || amount <= 0))}
              required
              placeholder="0.00"
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              startAdornment={
                symbol && (
                  <SelectSymbolSection
                    symbol={symbol}
                    selectedToken={getTokenBySymbol(symbol)}
                    supportedTokens={filteredTokens}
                    setMaxValue={(maxVal: number) => setMaxValue(maxVal)}
                    getLogoBySymbol={getLogoBySymbol}
                    onChange={(ev: SelectChangeEvent<any>) => {
                      setSymbol(ev.target.value);
                      setFilteredTokens(
                        supportedTokens.filter(
                          (el) => el.symbol !== ev.target.value
                        )
                      );
                    }}
                  />
                )
              }
            />
            {amount && amount > maxValue && (
              <FormHelperText error>
                This value is higher than max available amount.
              </FormHelperText>
            )}
            {amount && amount <= 0 && (
              <FormHelperText error>
                This value has to be a positive number
              </FormHelperText>
            )}
          </StepContent>
          <StepLabel label="Wallet address" />
          <StepContent>
            <OutlinedInput
              fullWidth
              multiline
              color="primary"
              name="walletAddress"
              onChange={(e: React.ChangeEvent<any>) => {
                setIsWalletAddressValid();
                setWalletAddress(e.target.value);
              }}
              required
              placeholder="0x00000000000000000000000000000000000"
              error={!isWalletAddressValid}
            />
            {!isWalletAddressValid && (
              <FormHelperText error id="component-error-text">
                Please type a valid address.
              </FormHelperText>
            )}
          </StepContent>
        </Stack>
        <Grid container justifyContent={"center"} sx={{ marginTop: "70px" }}>
          <LoadingButton
            color="primary"
            onClick={() => onSendEmail()}
            loading={isSendEmailLoading}
            loadingPosition="start"
            startIcon={<AccountBalanceWalletIcon />}
            variant="contained"
            sx={{ height: "48px" }}
            disabled={!!(amount && (amount > maxValue || amount <= 0))}
          >
            <span>Submit</span>
          </LoadingButton>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default WithdrawContent;
