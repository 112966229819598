import { Stack, Switch } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import React from "react";

import { ThemeModeContext } from "../shared/providers/theme-mode-provider";

export const DarkLightModeSwitcher = () => {
  const { isLightMode, changeTheme } = React.useContext(ThemeModeContext);

  return (
    <Stack direction="row" alignItems="center">
      <DarkModeIcon color={isLightMode ? "info" : "secondary"} />
      <Switch
        checked={isLightMode}
        onChange={(_, checked) => changeTheme(checked)}
        inputProps={{ "aria-label": "controlled" }}
        color="secondary"
      />
      <LightModeIcon color={!isLightMode ? "info" : "primary"} />
    </Stack>
  );
};

export default DarkLightModeSwitcher;
