export const DistributingColorIconSVG = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2242_10081)">
      <path
        d="M7.90287 11.7672C7.90308 11.8906 7.87894 12.0128 7.83182 12.1268C7.7847 12.2409 7.71553 12.3445 7.62828 12.4317C7.54104 12.519 7.43742 12.5881 7.32339 12.6353C7.20935 12.6824 7.08713 12.7065 6.96374 12.7063L3.42821 12.7063C3.17964 12.7047 2.94171 12.6052 2.76594 12.4294C2.59017 12.2537 2.49071 12.0157 2.48908 11.7672L2.48908 8.23164C2.48908 7.98257 2.58803 7.7437 2.76415 7.56758C2.94027 7.39146 3.17914 7.29252 3.42821 7.29251C3.67728 7.29252 3.91615 7.39146 4.09227 7.56758C4.26839 7.7437 4.36734 7.98257 4.36734 8.23164V9.50222L11.6041 2.26543C11.7799 2.08961 12.0184 1.99084 12.267 1.99084C12.5157 1.99084 12.7541 2.08961 12.93 2.26543C13.1058 2.44124 13.2045 2.6797 13.2045 2.92834C13.2045 3.17698 13.1058 3.41544 12.93 3.59125L5.69316 10.828L6.96374 10.828C7.08713 10.8278 7.20935 10.852 7.32339 10.8991C7.43742 10.9462 7.54104 11.0154 7.62828 11.1026C7.71553 11.1899 7.7847 11.2935 7.83182 11.4075C7.87894 11.5216 7.90308 11.6438 7.90287 11.7672ZM18.5095 11.7672V8.23164C18.5097 8.10825 18.4855 7.98604 18.4384 7.872C18.3913 7.75796 18.3221 7.65435 18.2349 7.5671C18.1476 7.47985 18.044 7.41068 17.93 7.36357C17.816 7.31645 17.6937 7.2923 17.5703 7.29251H14.0348C13.7857 7.29251 13.5469 7.39146 13.3707 7.56758C13.1946 7.7437 13.0957 7.98257 13.0957 8.23164C13.0957 8.48071 13.1946 8.71958 13.3707 8.8957C13.5469 9.07182 13.7857 9.17077 14.0348 9.17077H15.3054L8.0686 16.4076C7.89278 16.5834 7.79401 16.8218 7.79401 17.0705C7.79401 17.3191 7.89278 17.5576 8.0686 17.7334C8.24441 17.9092 8.48287 18.008 8.73151 18.008C8.98015 18.008 9.21861 17.9092 9.39442 17.7334L16.6312 10.4966V11.7672C16.6328 12.0157 16.7323 12.2537 16.9081 12.4294C17.0838 12.6052 17.3218 12.7047 17.5703 12.7063C17.6937 12.7065 17.816 12.6824 17.93 12.6352C18.044 12.5881 18.1476 12.519 18.2349 12.4317C18.3221 12.3445 18.3913 12.2409 18.4384 12.1268C18.4855 12.0128 18.5097 11.8906 18.5095 11.7672Z"
        fill="#0D0D0E"
      />
      <path
        d="M7.90287 11.7672C7.90308 11.8906 7.87894 12.0128 7.83182 12.1268C7.7847 12.2409 7.71553 12.3445 7.62828 12.4317C7.54104 12.519 7.43742 12.5881 7.32339 12.6353C7.20935 12.6824 7.08713 12.7065 6.96374 12.7063L3.42821 12.7063C3.17964 12.7047 2.94171 12.6052 2.76594 12.4294C2.59017 12.2537 2.49071 12.0157 2.48908 11.7672L2.48908 8.23164C2.48908 7.98257 2.58803 7.7437 2.76415 7.56758C2.94027 7.39146 3.17914 7.29252 3.42821 7.29251C3.67728 7.29252 3.91615 7.39146 4.09227 7.56758C4.26839 7.7437 4.36734 7.98257 4.36734 8.23164V9.50222L11.6041 2.26543C11.7799 2.08961 12.0184 1.99084 12.267 1.99084C12.5157 1.99084 12.7541 2.08961 12.93 2.26543C13.1058 2.44124 13.2045 2.6797 13.2045 2.92834C13.2045 3.17698 13.1058 3.41544 12.93 3.59125L5.69316 10.828L6.96374 10.828C7.08713 10.8278 7.20935 10.852 7.32339 10.8991C7.43742 10.9462 7.54104 11.0154 7.62828 11.1026C7.71553 11.1899 7.7847 11.2935 7.83182 11.4075C7.87894 11.5216 7.90308 11.6438 7.90287 11.7672ZM18.5095 11.7672V8.23164C18.5097 8.10825 18.4855 7.98604 18.4384 7.872C18.3913 7.75796 18.3221 7.65435 18.2349 7.5671C18.1476 7.47985 18.044 7.41068 17.93 7.36357C17.816 7.31645 17.6937 7.2923 17.5703 7.29251H14.0348C13.7857 7.29251 13.5469 7.39146 13.3707 7.56758C13.1946 7.7437 13.0957 7.98257 13.0957 8.23164C13.0957 8.48071 13.1946 8.71958 13.3707 8.8957C13.5469 9.07182 13.7857 9.17077 14.0348 9.17077H15.3054L8.0686 16.4076C7.89278 16.5834 7.79401 16.8218 7.79401 17.0705C7.79401 17.3191 7.89278 17.5576 8.0686 17.7334C8.24441 17.9092 8.48287 18.008 8.73151 18.008C8.98015 18.008 9.21861 17.9092 9.39442 17.7334L16.6312 10.4966V11.7672C16.6328 12.0157 16.7323 12.2537 16.9081 12.4294C17.0838 12.6052 17.3218 12.7047 17.5703 12.7063C17.6937 12.7065 17.816 12.6824 17.93 12.6352C18.044 12.5881 18.1476 12.519 18.2349 12.4317C18.3221 12.3445 18.3913 12.2409 18.4384 12.1268C18.4855 12.0128 18.5097 11.8906 18.5095 11.7672Z"
        fill="url(#paint0_linear_2242_10081)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2242_10081"
        x1="10.4998"
        y1="-0.16803"
        x2="20.6064"
        y2="11.1088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <clipPath id="clip0_2242_10081">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
