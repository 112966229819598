import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";

//Set a return type for result from backend(object)
interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const walletApi: any = createApi({
  reducerPath: "walletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    getUserWalletData: builder.query<ResultFromBackend, string>({
      query: (token) => ({
        url: `/web/list-user-wallet-data`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    sendWithdrawEmail: builder.mutation<
      void,
      {
        token: string;
        amount: string;
        symbol: string;
      }
    >({
      query: ({ token, ...body }) => ({
        url: "/accounts/send-withdraw-email",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    withdraw: builder.mutation<
      void,
      {
        token: string;
        amount: string;
        destinationAddress: string;
        tokenAddress: string;
        authCode: number;
      }
    >({
      query: ({ token, ...body }) => ({
        url: "/proxy/withdraw",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetUserWalletDataQuery,
  useSendWithdrawEmailMutation,
  useWithdrawMutation,
} = walletApi;
