import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";
import { ICreateProject, IFeaturedProject } from "../models/project.model";

//Set a return type for result from backend(object)
interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const fundingFeaturedProjectsApi: any = createApi({
  reducerPath: "fundingFeaturedProjectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    buyTokens: builder.mutation<
      void,
      {
        token: string;
        contractAddress: string;
        stableCoinAddress: string;
        amount: string;
      }
    >({
      query: ({ token, ...body }) => ({
        url: "/proxy/buy-tokens",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    redeemTokens: builder.mutation<
      void,
      {
        token: string;
        contractAddress: string;
        amount: string;
      }
    >({
      query: ({ token, ...body }) => ({
        url: "/proxy/redeem-tokens",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useBuyTokensMutation, useRedeemTokensMutation } =
  fundingFeaturedProjectsApi;
