import { Button, Grid, Typography } from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";

import React, { useEffect,useState } from "react";
import { TBlogPost} from "../../BlogPage/types";
import { useGetBlogPostsQuery } from "../../../services/blogApi";
import BlogPostCard from "../../BlogPage/BlogPostCard";
import { useNavigate } from "react-router-dom";

export const LatestNews = () => {
    const navigate = useNavigate();

    const [blogPosts, setBlogPosts] = useState<TBlogPost[]>([]);
    const { data, isSuccess } = useGetBlogPostsQuery();
  
    useEffect(() => {
      if (isSuccess && data) {
        setBlogPosts(filterBlogs(data.data.mostNoticeable));
      }
    }, [isSuccess, data]);

    const filterBlogs = (blogs: TBlogPost[]) => blogs.filter(blog => blog.isFeatured).slice(0,3)

    const handleSeeAll = () => {
        navigate('/blog')
    }

  return blogPosts.length ? (
    <Grid container gap={2}>  
        <Grid
            item
            xs={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            gap={{ xs: 2, sm: 1, md: 0 }}
            flexDirection={{ xs: "column-reverse", sm: "row" }}
            flexWrap="nowrap"
        >
            <Grid container gap={1} alignItems="center">
              <TextsmsIcon color="info" />
              <Typography variant="h3" color="secondary" fontSize={"20px"}>
                Latest news
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end">
            <Button onClick={handleSeeAll} variant="outlined" color="secondary" sx={{height: '32px'}}>See all</Button></Grid>
        </Grid>
        
        <Grid item container xs={12} spacing={{ xs: 2, sm: 3, md: 10}} 
          sx={{ 
            flexWrap: "nowrap", 
            overflowX: 'auto',
            "&::-webkit-scrollbar": {
              display: "none"
            }}}>
            {blogPosts.map((post) => (
                <Grid key={post.id} item xs={12} sm={6} md={4} lg={4} flex="0 0 auto">
                  <BlogPostCard post={post} />
                </Grid>
            ))}
        </Grid>
    
    </Grid>
  ) : null;
};

export default LatestNews;
