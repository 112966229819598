import { CssBaseline } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as dotenv from "dotenv";
import {
  Config,
  DAppProvider,
  Polygon,
  BSCTestnet,
  BSC,
  Mainnet,
} from "@usedapp/core";
import { getDefaultProvider } from "ethers";
import { Network } from "@ethersproject/networks";

import { store } from "./app/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeModeProvider } from "./shared/providers/theme-mode-provider";
import AppWrapper from "./AppWrapper";

export const matic: Network = {
  name: "matic",
  chainId: 137,
  _defaultProvider: (providers: any) =>
    new providers.JsonRpcProvider(
      "https://polygon-mainnet.g.alchemy.com/v2/0Eijd1zkFBSX5TiU6i-_0W11HZRZ6I9b"
    ),
};

const config: Config = {
  networks: [Polygon, BSCTestnet, BSC, Mainnet], // ADD this in production env:  BSC
  readOnlyChainId: Polygon.chainId,
  readOnlyUrls: {
    [Polygon.chainId]: getDefaultProvider(matic),
    [BSCTestnet.chainId]: "https://data-seed-prebsc-2-s1.binance.org:8545", //"https://data-seed-prebsc-1-s1.binance.org:8545",
    [BSC.chainId]: "https://bsc-dataseed1.binance.org",
    [Mainnet.chainId]:
      "https://eth-mainnet.g.alchemy.com/v2/VxT8WWlF47wk528CpaG1aQtp4DWF6QTj", // getDefaultProvider("mainnet"),
  },
  notifications: {
    expirationPeriod: 1000,
    checkInterval: 5000,
  },
  // multicallVersion: 2 as const,
};

// const MMSDKOptions = {
//   dappMetadata: { name: "CollectivePad", url: "" },
//   checkInstallationImmediately: true,
//   preferDesktop: false,
//   injectProvider: true,
// };

// const MMSDK = new MetaMaskSDK(MMSDKOptions);
// export const ethereum = MMSDK.getProvider();

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <Provider store={store}>
        <ThemeModeProvider>
          <CssBaseline />
          <AppWrapper />
        </ThemeModeProvider>
      </Provider>
    </DAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
