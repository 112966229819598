import { Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RoundsStepper from "./RoundsStepper";
import { basicTone200 } from "../../../shared/styles/constants";
import { mapRoundsList } from "./utils";
import { IFeaturedProject } from "../../../models/project.model";
import { useCall } from "@usedapp/core";
import { useContracts } from "../../../hooks";
import TargetAchievement from "./TargetAchievement";

export const RoundSmallCard = (projectData: IFeaturedProject) => {
  // const [stableCoinAmountRaised, setStableCoinAmountRaised] = useState(0);
  // const { fundingContract } = useContracts({
  //   fundingContractAddress: projectData.currentFundingRound.contractAddress,
  // });

  // const { value: stableCoinAmountRaisedValue } =
  //   useCall({
  //     contract: fundingContract,
  //     method: "stableCoinAmountRaised",
  //     args: [],
  //   }) ?? {};

  // useEffect(() => {
  //   if (stableCoinAmountRaisedValue !== undefined) {
  //     setStableCoinAmountRaised(stableCoinAmountRaisedValue?.[0].toString());
  //   }
  // }, [stableCoinAmountRaisedValue]);

  return (
    <Card
      sx={{
        padding: { xs: "24px", md: "0px 16px 0px 90px" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid
        container
        justifyContent={{ md: "space-between" }}
        gap={{ xs: 4, md: 0 }}
      >
        <Grid
          xs={12}
          md={6}
          gap={4}
          item
          container
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "flex-start", md: "flex-start" }}
        >
          <Stack>
            <Typography variant="body2" color={basicTone200}>
              Target achievement
            </Typography>
            {projectData.currentFundingRound.fundingContractAddress ? (
              <TargetAchievement {...projectData.currentFundingRound} />
            ) : (
              <Typography variant="h5" color={"secondary"} fontWeight={700}>
                {`$${0}/$${projectData.currentFundingRound.dollarTargetAmount}`}
              </Typography>
            )}
          </Stack>

          <Stack>
            <Typography variant="body2" color={basicTone200}>
              Round:
            </Typography>
            <Typography variant="h5" color={"secondary"} fontWeight={700}>
              {projectData.currentFundingRound.name}
            </Typography>
          </Stack>

          <Stack>
            <Typography variant="body2" color={basicTone200}>
              Participants:
            </Typography>
            <Typography variant="h5" color={"secondary"} fontWeight={700}>
              {projectData?.numberOfParticipants}
            </Typography>
          </Stack>
        </Grid>

        <RoundsStepper
          rounds={mapRoundsList(
            projectData.currentFundingRoundId,
            projectData.roundsList
          )}
        />
      </Grid>
    </Card>
  );
};

export default RoundSmallCard;
