import React, { useEffect } from "react";
import { Grid, Stack, Typography, Button } from "@mui/material";
import { CheckedColorIconSVG } from "../../../assets/icons/CheckedColorIconSVG";
import { useNavigate } from "react-router-dom";
import { basicTone200 } from "../../../shared/styles/constants";

export const WithdrawSuccessfulSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        sx={{ padding: "70px 60px 0" }}
      >
        <Stack gap={3} alignItems="center">
          <CheckedColorIconSVG />
          <Typography
            variant="h2"
            textAlign={"center"}
            color="secondary"
          >{`Well done!`}</Typography>
          <Typography
            variant="h6"
            textAlign={"center"}
            color={basicTone200}
          >{`Your wallet has been succesfully funded `}</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems="center"
        sx={{ marginTop: "106px" }}
        gap={1}
      >
        <Button
          color="primary"
          onClick={() => navigate("/")}
          variant="contained"
          sx={{ height: "48px" }}
        >
          Go to dashboard
        </Button>
        <Typography variant={"body1"} color="info">
          You will be autoredirected in 5 seconds.{" "}
        </Typography>
      </Grid>
    </>
  );
};

export default WithdrawSuccessfulSection;
