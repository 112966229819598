export const MARKETPLACE_ADDRESS = "0x6212c293918F752478cdD216A77d7cA18B7efD01";

export const MARKETPLACE_BSCTESTNET_ADDRESS =
  "0x78F5dC549D78361AdE4555B17282766eC42F88dd";
export const MARKETPLACE_MATIC_ADDRESS =
  "0x65187B30eC692fEC4788BBFf4109149C9a30379A";

export const MARKETPLACE_ABI = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_platformFee",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_feeRecipient",
        type: "address",
      },
      {
        internalType: "address",
        name: "_payableToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_payableTokenDecimals",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        indexed: false,
        internalType: "struct Order",
        name: "askOrder",
        type: "tuple",
      },
    ],
    name: "AskCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        indexed: false,
        internalType: "struct Order",
        name: "bidOrder",
        type: "tuple",
      },
    ],
    name: "BidCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        indexed: false,
        internalType: "struct Order",
        name: "askOrder",
        type: "tuple",
      },
    ],
    name: "OrderAccepted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "internalId", type: "uint256" },
      {
        internalType: "address",
        name: "_fundingTerms",
        type: "address",
      },
      { internalType: "string", name: "_logoUrl", type: "string" },
      { internalType: "string", name: "_symbol", type: "string" },
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
    ],
    name: "addSupportedFundingContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "askOrders",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "quantity", type: "uint256" },
      { internalType: "uint256", name: "fullPrice", type: "uint256" },
      {
        internalType: "uint256",
        name: "pricePerToken",
        type: "uint256",
      },
      { internalType: "bool", name: "sold", type: "bool" },
      { internalType: "address", name: "seller", type: "address" },
      { internalType: "address", name: "buyer", type: "address" },
      {
        internalType: "address",
        name: "fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "createdAt", type: "uint256" },
      { internalType: "uint256", name: "fulfilledAt", type: "uint256" },
      { internalType: "string", name: "orderType", type: "string" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "bidOrders",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "quantity", type: "uint256" },
      { internalType: "uint256", name: "fullPrice", type: "uint256" },
      {
        internalType: "uint256",
        name: "pricePerToken",
        type: "uint256",
      },
      { internalType: "bool", name: "sold", type: "bool" },
      { internalType: "address", name: "seller", type: "address" },
      { internalType: "address", name: "buyer", type: "address" },
      {
        internalType: "address",
        name: "fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "createdAt", type: "uint256" },
      { internalType: "uint256", name: "fulfilledAt", type: "uint256" },
      { internalType: "string", name: "orderType", type: "string" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_saleId", type: "uint256" },
      { internalType: "address", name: "_payToken", type: "address" },
      { internalType: "uint256", name: "_price", type: "uint256" },
    ],
    name: "buy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_price", type: "uint256" }],
    name: "calculatePlatformFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_saleId", type: "uint256" },
    ],
    name: "cancelAskOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_bidId", type: "uint256" },
    ],
    name: "cancelBidOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256", name: "_fullPrice", type: "uint256" },
    ],
    name: "createAsk",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_quantity", type: "uint256" },
      { internalType: "uint256", name: "_fullPrice", type: "uint256" },
      { internalType: "address", name: "_payToken", type: "address" },
    ],
    name: "createBid",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "feeRecipient",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "fundingTermsSupportedContracts",
    outputs: [
      { internalType: "uint256", name: "internalId", type: "uint256" },
      {
        internalType: "address",
        name: "fundingTermsAddress",
        type: "address",
      },
      { internalType: "string", name: "logoUrl", type: "string" },
      { internalType: "string", name: "symbol", type: "string" },
      { internalType: "address", name: "tokenAddress", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      {
        internalType: "address",
        name: "_tokenForSaleAddress",
        type: "address",
      },
    ],
    name: "getActiveOrders",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        internalType: "struct Order[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "getRoleMember",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleMemberCount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenForSaleAddress",
        type: "address",
      },
    ],
    name: "listAskOrdersPerToken",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        internalType: "struct Order[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "listAvailableTokensForSale",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "internalId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          { internalType: "string", name: "logoUrl", type: "string" },
          { internalType: "string", name: "symbol", type: "string" },
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "availableTokensForSale",
            type: "uint256",
          },
        ],
        internalType: "struct UserAvailableTokensForSale[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenForSaleAddress",
        type: "address",
      },
    ],
    name: "listBidOrdersPerToken",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fullPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricePerToken",
            type: "uint256",
          },
          { internalType: "bool", name: "sold", type: "bool" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "address", name: "buyer", type: "address" },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "createdAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fulfilledAt",
            type: "uint256",
          },
          { internalType: "string", name: "orderType", type: "string" },
        ],
        internalType: "struct Order[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listSupportedTokens",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "internalId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "fundingTermsAddress",
            type: "address",
          },
          { internalType: "string", name: "logoUrl", type: "string" },
          { internalType: "string", name: "symbol", type: "string" },
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
        ],
        internalType: "struct FundingTermsContractInfo[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "payableToken",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "payableTokenDecimals",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "platformFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fundingTermsAddress",
        type: "address",
      },
      { internalType: "uint256", name: "_bidId", type: "uint256" },
      { internalType: "address", name: "_payToken", type: "address" },
      { internalType: "uint256", name: "_fullPrice", type: "uint256" },
    ],
    name: "sell",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "updateFeeRecipient",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "_decimals", type: "uint256" },
    ],
    name: "updatePayableToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_platformFee", type: "uint256" },
    ],
    name: "updatePlatformFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userActiveAsksCounter",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userActiveBidsCounter",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userAsksCounter",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userBidsCounter",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];
