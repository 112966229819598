export const WidgetsColorIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="5" width="6" height="6" rx="2" fill="#D0D0D0" />
    <rect x="5" y="13" width="6" height="6" rx="2" fill="#D0D0D0" />
    <rect
      x="17.123"
      y="1.84961"
      width="6"
      height="6"
      rx="2"
      transform="rotate(33.071 17.123 1.84961)"
      fill="#F2387B"
    />
    <rect x="13" y="13" width="6" height="6" rx="2" fill="#D0D0D0" />
  </svg>
);
