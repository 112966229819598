import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import {
  basicTone50,
  basicTone500,
  mainBorderRadius,
} from "../../../../shared/styles/constants";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { states, TStates } from "./utils";

const getBackgroundByStatus = (status: string) => {
  switch (status) {
    case "signUp":
      return basicTone50;
    default:
      return basicTone500;
  }
};

const getColorByStatus = (status: string, isLightMode: boolean) => {
  switch (status) {
    case "signUp":
      return isLightMode ? "secondary" : "primary";
    default:
      return isLightMode ? "primary" : "secondary";
  }
};

export const FeaturedProjectStatus = ({ status }: { status: string }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        width: "100%",
        backgroundColor: getBackgroundByStatus(status),
        borderBottomLeftRadius: mainBorderRadius,
        borderBottomRightRadius: mainBorderRadius,
        padding: "8px ",

        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))",
      }}
    >
      <Typography
        variant="body2"
        color={getColorByStatus(status, isLightMode)}
        fontWeight={800}
        lineHeight="16px"
        letterSpacing={"0.5em"}
        textTransform="uppercase"
      >
        {status === "INVESTED" ? status : states[status as keyof TStates]}
      </Typography>
    </Grid>
  );
};

export default FeaturedProjectStatus;
