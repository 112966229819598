import React from "react";
import { Button, ButtonProps } from "@mui/material";
import {
  basicTone0,
  mainColor1,
  mainColor2,
  mainLinearGradient,
  secondaryColor1,
} from "../../shared/styles/constants";

type OutlinedPrimaryCustomButtonProps = ButtonProps & {
  dark?: boolean;
  text: string;
  StartIcon?: React.ReactNode;
  startImg?: string;
};

export const OutlinedPrimaryCustomButton = ({
  dark,
  StartIcon,
  text,
  startImg,
  endIcon,
  ...rest
}: OutlinedPrimaryCustomButtonProps) => {
  const gradientId = `linearColors${!!dark}`;

  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id={gradientId} x1={1} y1={0} x2={1} y2={2}>
          <stop offset={0} stopColor={mainColor2} />
          <stop offset={1} stopColor={mainColor1} />
        </linearGradient>
      </svg>
      <Button
        {...rest}
        color="primary"
        variant="outlined"
        sx={
          dark
            ? {
                color: basicTone0,
                border: `1px solid ${secondaryColor1}`,
                "& .MuiButton-startIcon .MuiSvgIcon-root": {
                  fill: `url(#${gradientId})`,
                },
                "&:hover": {
                  backgroundColor: mainColor2,
                  background: mainLinearGradient,
                  border: `1px solid ${secondaryColor1}`,
                  "& .MuiButton-startIcon .MuiSvgIcon-root": {
                    fill: basicTone0,
                  },
                },
              }
            : undefined
        }
        startIcon={StartIcon}
        endIcon={endIcon}
      >
        {startImg && (
          <img
            src={startImg}
            alt="ChainLogoUrl"
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
        )}
        {text}
      </Button>
    </>
  );
};

export default OutlinedPrimaryCustomButton;
