import {
  Box,
  Grid,
  IconButton,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";

import React, { useContext } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SignInButton from "../SignInButton";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { pages } from "./header-bar-utils.utils";
import { CommunityMobile } from "./CommunityMobile";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { basicTone0, basicTone700 } from "../../shared/styles/constants";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../shared/providers/account-provider";

type NavMenuDrawerProps = {
  handleOpenNavMenu: () => void;
  expandCommunity: boolean;
  onCollapseCommunity: () => void;
};

export const NavMenuDrawer = ({
  handleOpenNavMenu,
  expandCommunity,
  onCollapseCommunity,
}: NavMenuDrawerProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  return (
    <Grid
      container
      flexDirection={"column"}
      justifyContent="space-between"
      paddingBottom={"50px"}
      // sx={{ height: "100%" }}
    >
      <Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "26px" }}
        >
          <IconButton onClick={handleOpenNavMenu}>
            <CancelRoundedIcon color="info" />
          </IconButton>
        </Box>
        <Box
        // sx={{ height: "100%" }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {pages.map((item) => (
              <React.Fragment key={item.page}>
                <ListItem
                  key={item.page}
                  disablePadding
                  onClick={
                    item.page === "Community" ? onCollapseCommunity : undefined
                  }
                >
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <ListItemText>
                      <MaterialLink
                        onClick={() => {
                          const url =
                            item.page === "Dashboard"
                              ? account
                                ? item.path
                                : item.redirectPath || "/"
                              : item.path;
                          navigate(url);
                          handleOpenNavMenu();
                        }}
                        key={item.page}
                        color="secondary"
                        underline="none"
                        sx={{
                          display: "flex",
                          fontSize: "20px",
                          paddingLeft: "40px",
                          alignItems: "center",
                          color: isLightMode ? basicTone700 : basicTone0,
                        }}
                      >
                        {item.page}
                        {/* {item.page === "Community" ? (
                          expandCommunity ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : null} */}
                      </MaterialLink>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                {item.page === "Community" && (
                  // <Collapse in={expandCommunity} timeout="auto" unmountOnExit>
                  <CommunityMobile />
                  // </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "4px",
          flexWrap: "wrap",
        }}
      >
        <SignInButton dark={false} handleOpenNavMenu={handleOpenNavMenu} />
      </Box>
    </Grid>
  );
};

export default NavMenuDrawer;
