import { Card, Grid, Typography } from "@mui/material";

export const SignUpEnded = () => {
  return (
    <Card
      sx={{
        padding: "36px",
      }}
    >
      {
        <Grid container justifyContent={"center"} gap={2}>
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={800}
            fontSize="20px"
            textAlign={"center"}
          >
            Sign up ended
          </Typography>
        </Grid>
      }
    </Card>
  );
};

export default SignUpEnded;
