import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  basicTone200,
  basicTone50,
} from "../../shared/styles/constants";
import Logo from "../../logo";

import RegisterCard from "./RegisterCard";
import { AccountContext } from "../../shared/providers/account-provider";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { basicTone800 } from "../../shared/styles/constants";
import { config } from "../../config/config";

export const RegisterPage = () => {
  const { isLightMode } = useContext(ThemeModeContext);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { account, resetState } = useContext(AccountContext);
  const navigate = useNavigate();

  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const handleClose = () => navigate("/");
  return (
    <Dialog
      open
      fullScreen
      onClose={handleClose}
      sx={{ overflow: isMobile ? "auto" : "hidden" }}
      PaperProps={{
        sx: { backgroundColor: isLightMode ? basicTone50 : basicTone800 },
      }}
    >
      <Grid
        justifyContent={"center"}
        display="flex"
        flexDirection={"column"}
        padding={2}
        sx={{
          margin: "0 auto",
          height: isMobile ? "auto" : "100%",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Logo width={isMobile ? 140 : undefined} />
          <IconButton
            color="secondary"
            onClick={handleClose}
            aria-label="close"
            sx={{
              height: "40px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <CancelRoundedIcon color={isLightMode ? "disabled" : "info"} />
          </IconButton>
        </Grid>
        <Grid
          container
          paddingRight={{ xs: 0, md: 1.5}}
          marginTop={3}
          marginBottom={2}
          paddingLeft={{ xs: 0, md: 1.5 }}
          gap={2}
          flexWrap="nowrap"
        >
          <RegisterCard isNewUser={isNewUser} handleClose={handleClose} />
        
        </Grid>
        {!account && config.DEV_MODE ? (
          isNewUser ? (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Typography variant="body1" color={basicTone200}>
                Already a member?
              </Typography>
              <Button
                variant="text"
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  setIsNewUser(false);
                  resetState();
                }}
              >
                Sign In
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Typography variant="body1" color={basicTone200}>
                Not a member yet?
              </Typography>
              <Button
                variant="text"
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  setIsNewUser(true);
                  resetState();
                }}
              >
                Sign Up
              </Button>
            </Grid>
          )
        ) : null}
      </Grid>
    </Dialog>
  );
};

export default RegisterPage;
