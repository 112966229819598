import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";

export const kycApi: any = createApi({
  reducerPath: "kycApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    checkUserKyc: builder.mutation<void, { code: string; token: string }>({
      query: (payload) => ({
        url: "/web/fractal",
        method: "POST",
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
        body: { code: payload.code },
      }),
    }),
  }),
});

export const { useCheckUserKycMutation } = kycApi;
