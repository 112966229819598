import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { useGetFeaturedProjectsQuery } from "../../../../services/featuredProjectsApi";
import ProjectsSelectionButton from "./ProjectsSelectionButton";

import SearchProjects from "./Search-projects";
import FeaturedProjectCard from "./FeaturedProjectCard";
import {
  mergeSignUpEndedWithFundingReadyProjects,
  states,
  TAllProjects,
  TStates,
} from "./utils";
import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone500,
  basicTone600,
} from "../../../../shared/styles/constants";
import SvgIcon from "@mui/material/SvgIcon";
import { NotificationColorIconSVG } from "../../../../assets/icons/NotificationColorIcon";
import { RocketColorIconSVG } from "../../../../assets/icons/RocketColorIconSVG";
import { WidgetsColorIconSVG } from "../../../../assets/icons/WidgetsColorIconSVG";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import ShowOffProjectCard from "./ShowOffProjectCard";

export const FeaturedProjects = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const [allProjects, setAllProjects] = React.useState<
    TAllProjects | undefined
  >(undefined);
  const [filteredAllProjects, setFilteredAllProjects] = React.useState<
    TAllProjects | undefined
  >(undefined);

  const [searchValue, setSearchValue] = React.useState<string>("");
  const [selectedProjectType, setSelectedProjectType] =
    React.useState<string>("all");

  //FeaturedProject list Fetch from backend
  const { data, isSuccess } = useGetFeaturedProjectsQuery();

  //initialise lists
  React.useEffect(() => {
    if (isSuccess && data) {
      const projects = mergeSignUpEndedWithFundingReadyProjects(data.data);
      setAllProjects(projects);
      setFilteredAllProjects(projects);
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    if (allProjects) {
      const updatedProjects = {
        pendingFeaturedProjects: allProjects.pendingFeaturedProjects.filter(
          (proj) => proj.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
        signUpFeaturedProjects: allProjects.signUpFeaturedProjects.filter(
          (proj) => proj.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
        signUpEndedFeaturedProjects:
          allProjects.signUpEndedFeaturedProjects.filter((proj) =>
            proj.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
        fundingFeaturedProjects: allProjects?.fundingFeaturedProjects.filter(
          (proj) => proj.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
        distributingFeaturedProjects:
          allProjects.distributingFeaturedProjects.filter((proj) =>
            proj.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
        waitingFeaturedProjects: allProjects.waitingFeaturedProjects.filter(
          (proj) => proj.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
        showOffFeaturedProjects: allProjects.showOffFeaturedProjects.filter(
          (proj) => proj.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
      };
      setFilteredAllProjects(updatedProjects);
    }
  }, [searchValue]);

  return (
    <Grid container gap={2}>
      <Typography variant="h4" color="secondary">
        Browse all our projects
      </Typography>
      <Grid
        item
        xs={12}
        container
        justifyContent={{ xs: "center", sm: "space-between" }}
        gap={{ xs: 2, sm: 1, md: 0 }}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        flexWrap="nowrap"
      >
        {/* projects lists options  desktop */}
        <Grid
          item
          xs={12}
          md={7}
          container
          gap={1}
          display={{ xs: "none", md: "flex" }}
        >
          <Grid item>
            <ProjectsSelectionButton
              text="All Projects"
              onClick={() => setSelectedProjectType("all")}
              isSelected={selectedProjectType === "all"}
              icon={
                <SvgIcon>
                  <WidgetsColorIconSVG />
                </SvgIcon>
              }
            />
          </Grid>
          <Grid item>
            <ProjectsSelectionButton
              text="Whitelisting open"
              onClick={() => setSelectedProjectType("signUp")}
              isSelected={selectedProjectType === "signUp"}
              icon={
                <SvgIcon>
                  <RocketColorIconSVG />
                </SvgIcon>
              }
            />
          </Grid>
          <Grid item>
            <ProjectsSelectionButton
              text="Upcoming"
              onClick={() => setSelectedProjectType("pending")}
              isSelected={selectedProjectType === "pending"}
              icon={
                <SvgIcon>
                  <NotificationColorIconSVG />
                </SvgIcon>
              }
            />
          </Grid>
        </Grid>
        {/* project type selection mobile */}
        <Grid
          item
          container
          xs={12}
          sm={6}
          display={{ xs: "flex", md: "none" }}
        >
          <Select
            fullWidth
            value={selectedProjectType}
            onChange={(ev) => setSelectedProjectType(ev.target.value)}
            IconComponent={() => <ArrowDropDownIcon color="secondary" />}
            sx={{
              backgroundColor: isLightMode ? basicTone0 : basicTone600,
              paddingRight: "8px",
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: "8px 8px 8px 16px",
              },
            }}
          >
            <MenuItem
              value={"all"}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "12px 16px",
                gap: 1,
                borderBottom: `1px solid ${
                  isLightMode ? basicTone100 : basicTone500
                }`,
              }}
            >
              <WidgetsColorIconSVG />
              <Typography variant={"body2"} color="secondary">
                All Projects
              </Typography>
            </MenuItem>
            <MenuItem
              value={"signUp"}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px 16px",
                gap: 1,
                borderBottom: `1px solid ${
                  isLightMode ? basicTone100 : basicTone500
                }`,
              }}
            >
              <RocketColorIconSVG />
              <Typography variant={"body2"} color="secondary">
                Whitelisting open
              </Typography>
            </MenuItem>
            <MenuItem
              value={"pending"}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "12px 16px",
                gap: 1,
              }}
            >
              <NotificationColorIconSVG />
              <Typography variant={"body2"} color="secondary">
                Upcoming
              </Typography>
            </MenuItem>
          </Select>
        </Grid>
        {/* search bar */}
        <SearchProjects searchValue={searchValue} onChange={setSearchValue} />
      </Grid>

      {/* projects list */}

      <Stack gap={1} sx={{ width: "100%" }}>
        {selectedProjectType === "all" && allProjects && filteredAllProjects
          ? Object.keys(allProjects).map((category) => {
              const status = category.slice(0, -16);

              return (
                <Accordion key={status} defaultExpanded disableGutters>
                  <AccordionSummary
                    expandIcon={
                      <ExpandCircleDownIcon htmlColor={basicTone200} />
                    }
                  >
                    <Typography
                      variant="body2"
                      color="secondary"
                      fontWeight={800}
                      lineHeight={"1"}
                    >
                      {`${
                        filteredAllProjects[category as keyof TAllProjects]
                          ?.length
                      } ${states[status as keyof TStates]}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item container xs={12} spacing={4}>
                      {filteredAllProjects[category as keyof TAllProjects]?.map(
                        (proj) => (
                          <Grid key={proj.id} item xs={12} sm={6} md={4} lg={3}>
                            {category === "showOffFeaturedProjects" ? (
                              <ShowOffProjectCard {...proj} />
                            ) : (
                              <FeaturedProjectCard {...proj} />
                            )}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })
          : filteredAllProjects && (
              <Accordion
                key={selectedProjectType}
                defaultExpanded
                disableGutters
              >
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon htmlColor={basicTone200} />}
                >
                  <Typography
                    variant="body2"
                    color="secondary"
                    fontWeight={800}
                    lineHeight={"1"}
                  >{`${
                    filteredAllProjects[
                      `${selectedProjectType}FeaturedProjects` as keyof TAllProjects
                    ]?.length
                  } ${
                    states[selectedProjectType as keyof TStates]
                  }`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item container xs={12} spacing={4}>
                    {filteredAllProjects[
                      `${selectedProjectType}FeaturedProjects` as keyof TAllProjects
                    ]?.map((proj) => (
                      <Grid key={proj.id} item xs={12} sm={6} md={3}>
                        <FeaturedProjectCard {...proj} />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
      </Stack>
    </Grid>
  );
};

export default FeaturedProjects;
