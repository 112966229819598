import React from "react";
import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  mainColor1,
  secondaryColor1,
} from "../../../../shared/styles/constants";
import Divider from "@mui/material/Divider";

export const ProjectOverviewContent = (projectData: IFeaturedProject) => {
  const { overview } = projectData.currentFundingRound;
  return (
    <Grid container gap={2} paddingTop={3}>
      <Divider />
      <Grid item container alignItems="center" gap={1.5} paddingTop={3}>
        <StopRoundedIcon htmlColor={secondaryColor1} fontSize="small" />
        <Typography
          variant="h5"
          color="secondary"
          fontWeight={700}
          fontSize="20px"
        >
          Project overview
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Product
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.product}
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Problem
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.problem}
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Solution
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.solution}
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Business model
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.businessModel}
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Market
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.market}
        </Typography>
      </Grid>
      <Grid container paddingLeft={3} gap={1}>
        <Grid item container alignItems="center" gap={1.5}>
          <StopRoundedIcon htmlColor={mainColor1} fontSize="small" />
          <Typography variant="h5" color="secondary">
            Investors
          </Typography>
        </Grid>
        <Typography variant="h6" color="secondary" fontWeight={400}>
          {overview.investors}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectOverviewContent;
