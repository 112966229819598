import { Typography, Stack, Divider } from '@mui/material'

import React, { useContext } from 'react'
import { ThemeModeContext } from '../../shared/providers/theme-mode-provider'
import { basicTone100 } from '../../shared/styles/constants'

export const TermsAndConditionsPage = () => {
  const { isLightMode } = useContext(ThemeModeContext)

  return (
    <div className='Content'>
      <Stack
        gap={4}
        paddingTop={3}
        paddingBottom={8}
        margin={'0 auto'}
        paddingRight={{ xs: 2, sm: 6, md: 8 }}
        paddingLeft={{ xs: 2, sm: 6, md: 8 }}
      >
        <Stack gap={1}>
          <Typography variant='h1' color='secondary'>
            CollectiveHub General Terms and Conditions of Service
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={'16px'}
            lineHeight={'100%'}
            letterSpacing={'-0.02em'}
          >
            Updated 22.06.2023
          </Typography>
        </Stack>
        <Divider />

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Disclaimer and Risk Statement
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            By reading this document, the Visitor (as defined below) is aware
            that:
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Disclaimer
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The information contained in or provided on or through the Website
            (as defined below) is not intended to be and does not constitute
            financial advice, trading advice, or any other type of advice.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Platform (as defined below) is a for-profit corporation
            proceeding to the sale Tokens (as defined below) for the purpose of
            financing its business activity. The Platform is not a bank, a
            security firm, an asset manager, a portfolio manager or an
            investment advisor. The Platform is not a financial institution or a
            financial service provider.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Ecosystem Operator (as defined below) is not liable for any
            possible misinterpretation of the Terms & Conditions (as defined
            below).
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Visitor understands that the Ecosystem Operator allows Users to
            access Programs (as defined below) through a Wallet (as defined
            below) interfaced with the Website (as defined below), which is
            operated by Program Gateway(s) (as described below) directly through
            the Website. Within the scope of the Programs, the Ecosystem
            Operator acts solely as a technology provider as third parties
            operate the Program Gateways. The Ecosystem operator has no control
            over and shall not be responsible for the Program Gateway(s), the
            Wallet and/or the Programs. Sending crypto-assets to the Programs in
            order to generate is at your sole risk and under your sole
            liability.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Risk Statement
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Blockchain technology and crypto-assets carry significant risks,
            including the possible loss of all value allocated in crypto-assets.
            Such risks arise from the novelty of this technology, the regulatory
            uncertainty, the possibility of hacking, the high volatility and the
            information asymmetry characterizing the crypto market. Visitors
            should not purchase crypto-assets with funds they cannot afford to
            lose. Furthermore, the Visitor is strongly encouraged to seek
            financial and legal advice regarding the use of crypto-assets and
            the use of our services.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h5' color='secondary' fontWeight={700}>
            Preamble
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Agreement (as defined below) shall govern the use of
            CollectiveHub (as defined below) and shall constitute a binding
            contract between the Visitors (as defined below), the Users (as
            defined below), the Participants (as defined below), the Projects
            (as defined below), and the Ecosystem Operator (as defined below).
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            By browsing the website, any Visitor (as defined below) acknowledges
            having read and understood the Agreement and agrees to be bound by
            its terms and conditions and comply with the Agreement and all
            applicable laws and regulations. The Visitor’s consent is given once
            the Visitor ticks the box in the pop-up window that appears upon its
            first connection and says: “I accept the terms and conditions of the
            Agreement governing the use of CollectiveHub. This website uses
            cookies to ensure the Visitors get the best experience on our
            website. By continuing to use our website, the Visitors agree to the
            terms and conditions of the Agreement governing the use of
            CollectiveHub, our Privacy Policy and to our Cookies Policy”. By
            giving its consent, the Visitor confirms that its level of English
            is sufficient to understand the meaning of the terms and conditions
            of this Agreement as well as all the commitments, warranties,
            waivers and obligations contained herein.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If the Visitor is browsing the Website on behalf of a business or
            other entity, the Visitor represents and warrants that the Visitor
            has the necessary authority to bind that business or entity to the
            terms and conditions of this Agreement and that the Visitor agrees
            to this Agreement on behalf of that business or entity.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Ecosystem Operator shall have the right to unilaterally modify
            and/or update the terms and conditions of this Agreement at any time
            without notice. The continuous use of the Website by the Visitor
            shall be deemed as acceptance of this Agreement in the last and most
            updated version. Any Visitor shall periodically check the terms and
            conditions of this Agreement.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            By making use of the Website, the Visitor acknowledges and agrees
            that: (i) The Visitor is aware of the risks associated with
            crypto-assets, including but not limited to cybersecurity risks and
            regulatory risks; (ii) The Visitor shall assume all risks related to
            the use of CollectiveHub and crypto-assets transactions; and (iii)
            The Ecosystem Operator shall not be liable for any such risks or
            adverse outcomes.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            It is understood and presumed that, before purchasing SHO tokens (as
            defined below), the Participant has fully read, understood, and
            irrevocably accepted the terms and conditions of this Agreement. If
            any Participant does not agree with this Agreement in general or in
            any part, such Participant should refrain from using the Website
            and/or purchasing SHO tokens. This Agreement contains important
            provisions, including an arbitration provision that requires all
            claims to be resolved by way of legally binding arbitration.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The market value of crypto-assets may fluctuate significantly, and
            there is a substantial risk of economic losses when purchasing,
            selling, or holding digital tokens.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If any Visitor does not agree with the terms and conditions of the
            Agreement, the Visitor should refrain from using the Website.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography variant='h4' color='secondary' fontWeight={700}>
            TERMS & CONDITIONS
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            This end user agreement (the "Agreement") should be read by you (the
            "User" or "you") in its entirety prior to your use of CollectivePads
            services or products. Be aware that this Agreement constitutes a
            legally binding agreement between you and CollectiveHub (referred to
            herein as "CollectiveHub", "us" or "we") which owns and operates the
            Website on the Internet and the Service at www.collectivepad.com
            (the "Service"). You consent to adhering to all the terms set forth
            in this Agreement when/if you are provided with an “I Agree” button
            and clicking on it.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            1. Grant of License
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            1.1 CollectiveHub grants the User a non-exclusive, personal,
            non-transferable right to use the Service on your personal computer
            or other device that accesses the Internet in order to access and
            use the services available and described on the CollectiveHub.io
            website (the website and all its functionality together being the
            "Service"), subject to the terms and conditions contained herein.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            1.2 The Service is not for use by (i) minors and individuals under
            the age of 18 years, (ii) individuals who can be considered minors
            or under the age of majority adulthood in their specific
            jurisdiction(s) (iii) individuals accessing or using the Service
            from any jurisdiction in which it is illegal to do so. CollectiveHub
            does not have the ability to verify the legality of the Service in
            every jurisdiction, therefore it is entirely up to the User to
            determine whether or not their use of the Service is lawful.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            1.3 CollectiveHub and its licensors are the sole holders of all
            rights in and to the Service and code, technology, organization and
            structure, architecture, including copyright, trade secrets,
            intellectual property and other rights. You may not: (a) copy,
            create derivative works, distribute, publish, reverse engineer,
            decompile, disassemble, modify, or translate the CollectiveHub
            website or the Service; or (b) use the Service that in any way is
            prohibited by applicable laws or regulations (each of the above
            herein defined as an "Unauthorized Use").
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            1.4 CollectiveHub reserves any and all rights implied or otherwise,
            which are not expressly granted to the User hereunder and retain all
            rights, title and interest in and to the Service. You agree that you
            will be solely liable for any damage, costs or expenses arising out
            of or in connection with the commission by you of any Unauthorized
            Use. You shall notify CollectiveHub immediately upon becoming aware
            of the commission by any person of any Unauthorized Use and shall
            provide CollectiveHub with reasonable assistance with any
            investigations it conducts in light of the information provided by
            you in this respect.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            1.5 The term "CollectiveHub", its domain names and any other trade
            marks, or service marks used by CollectiveHub as part of the Service
            (the "Trade Marks"), are solely owned by CollectiveHub. In addition,
            all content on the website, including, but not limited to, the
            images, pictures, graphics, photographs, animations, videos, music,
            audio and text (the "Site Content") belongs to CollectiveHub and is
            protected by copyright and/or other intellectual property or other
            rights. You hereby acknowledge that by using the Service, you obtain
            no rights in the Site Content and/or the Trade Marks, or any part
            thereof. Under no circumstances may you use the Site Content and/or
            the Trade Marks without CollectivePads prior written consent.
            Additionally, you agree not to do anything that will harm or
            potentially harm the rights, including the intellectual property
            rights of CollectiveHub.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            2. No Warranties
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub disclaims any and all warranties, expressed or
            implied, in connection with the service which is provided to you "as
            is" and we provide you with no warranty or representation whatsoever
            regarding its quality, fitness for purpose, completeness or
            accuracy.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Regardless of CollectivePads efforts, CollectiveHub makes no
            warranty that the service will be uninterrupted, timely or
            error-free, or that defects will be corrected.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            3. Authority / Terms of Service
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            You agree to the rules of the Service provided and described on the
            CollectiveHub.io website. CollectiveHub retains all authority over
            the issuing, maintenance, and closing of the Service. The decision
            of CollectivePads management, concerning any use of the Service, or
            dispute resolution, is final and shall not be open to review or
            appeal.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            4. Your Representations and Warranties
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.1 There is a risk of losing cryptocurrency & other funds of value
            when using the Service and CollectiveHub has no responsibility to
            you for any such loss;
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.2 Your use of the Service is at your sole option, discretion and
            risk;
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.3 You are solely responsible for any applicable taxes which may be
            payable on cryptocurrency traded or transacted by you through your
            using the Service;
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.4 The telecommunications networks, blockchain networks (such as
            Polkadot and Ethereum) and Internet access services required for you
            to access and use the Service are entirely beyond the control of
            CollectiveHub and CollectiveHub shall have no liability whatsoever
            for any outages, slowness, capacity constraints or other
            deficiencies affecting the same; and
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.5 You are (i) aged 18 or over, (ii) you are of the age of majority
            in your jurisdiction, (iii) you are accessing the Service from a
            jurisdiction in which it is legal to do so, (iv) you are not a
            Politically Exposed Person (PEP) nor are you on any U.S. or EU
            Sanctions lists or terrorism finance watchlists, and (v) that you
            will inform CollectiveHub immediately if you enter onto one of these
            lists or change your residence to a prohibited jurisdiction.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            4.6 You warrant that should you discover a bug or error that may
            lead to an exploit of the Service or other loss of funds from
            CollectiveHub, you have the responsibility to notify CollectiveHub
            at Support@CollectiveHub.io in a timely manner. Furthermore, any
            attempt by you to use such a discovered exploit or bug for illicit
            financial gain is illegal and strictly prohibited under this
            Agreement. CollectiveHub reserves the right to prosecute to the
            fullest extent of the law anyone who exploits or attempts to exploit
            the Service in an unlawful manner. Such exploits or bugs should be
            disclosed in a responsible manner and in strict confidence with
            support@CollectiveHub.io and no other entity.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            5. Prohibited Uses
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Prior to your use of the Service and on an ongoing basis you
            represent, warrant, covenant and agree that:
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            5.1 PERSONAL USE. The Service is intended solely for the Users
            personal use. The User is only allowed to use the Service for
            personal use, and may not create multiple accounts, including for
            the purpose of collusion and/or abuse of service.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            5.2 EXPLOITS & BUGS. The Service is provided as is, and any
            exploitation of the Service or errors in program logic and/or code
            (bugs) constitutes a Prohibited Use and a material breach of this
            Agreement. Any funds the user may misappropriate in this manner
            pursuant to this clause shall be considered void and the immediate
            property of CollectiveHub, without limit.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            5.3 JURISDICTIONS. Persons located in or residents of the United
            States, North Korea, Iran, Venezuela or any other jurisdiction in
            which it is prohibited from using the Service (the ”Prohibited
            Jurisdictions”) are not permitted to make use of the Service. For
            the avoidance of doubt, the foregoing restrictions on Service from
            Prohibited Jurisdictions applies equally to residents and citizens
            of other nations while located in a Prohibited Jurisdiction. Any
            attempt to circumvent the restrictions on usage by any persons
            located in a Prohibited Jurisdiction or Restricted Jurisdiction, is
            a breach of this Agreement. An attempt at circumvention includes,
            but is not limited to, manipulating the information used by
            CollectiveHub to identify your location and providing CollectiveHub
            with false or misleading information regarding your location or
            place of residence.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Any and all monies (including cryptocurrencies) of a person located
            in a Prohibited Jurisdiction on the Service are VOID, and can be
            confiscated or may be returned to the person at CollectivePads sole
            discretion.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            6. Know your Customer (“KYC”) and Anti-Money Laundering (AML) Policy
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub is a permissionless and fully decentralised platform
            for token sales, swaps, and decentralized exchange. As a software
            development company, CollectiveHub has no role in enforcing KYC by
            default, however we do provide such tools for fundraising entities
            using CollectiveHub to enforce on their users, if the entities
            choose to do so. The use of KYC/AML tools on CollectiveHub by
            fundraising entities using the Service is completely at the
            discretion of said entities.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Although CollectiveHub makes no warranty as to the merit, legality
            or juridical nature of any token (including whether or not it is
            considered a security or financial instrument under any applicable
            Securities Laws), token sale or fundraiser on top of CollectiveHub,
            we nonetheless understand the need of some token projects to require
            KYC/AML on their token sale participants.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            Therefore, and at the sole behest of fundraising entities and/or
            competent regulatory authorities:
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub reserves the right, at any time, to ask for any KYC
            documentation it deems necessary to determine the identity and
            location of a User. CollectiveHub reserves the right to restrict
            service and payment until identity is sufficiently determined.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub further reserves the right to share submitted KYC
            information and documentation to 3rd parties to verify the
            authenticity of submitted information, and the end user (you) agree
            to this by using the Service.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub reserves the right to confiscate any and all funds
            that are found to be in violation of relevant and applicable
            anti-money laundering (AML) and countering terrorism financing (CFT)
            laws and regulations, and to cooperate with the competent
            authorities when and if necessary.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            AML & SANCTIONS COMPLIANCE
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub expressly prohibits and rejects the use of the Service
            for any form of illicit activity, including money laundering,
            terrorist financing or trade sanctions violations, consistent with
            various jurisdictions’ laws, regulations and norms. To that end, the
            Service is not offered to individuals or entities on any Politically
            Exposed Persons (PEP) lists, or subject to any United States,
            European Union, or other global sanctions or watch lists. By using
            the Service, end users represent and warrant that they are not on
            any such lists.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            7. Breach
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            7.1 Without prejudice to any other rights, if a User breaches in
            whole or in part any provision contained herein, CollectiveHub
            reserves the right to take such action as it sees fit, including
            terminating this Agreement or any other agreement in place with the
            User and/or taking legal action against such User.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            7.2 You agree to fully indemnify, defend and hold harmless
            CollectiveHub and its shareholders, directors, agents and employees
            from and against all claims, demands, liabilities, damages, losses,
            costs and expenses, including legal fees and any other charges
            whatsoever, howsoever caused, that may arise as a result of: (i)
            your breach of this Agreement, in whole or in part; (ii) violation
            by you of any law or any third party rights; and (iii) use by you of
            the Service.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            8. Limitations and Liability
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            8.1 Under no circumstances, including negligence, shall
            CollectiveHub be liable for any special, incidental, direct,
            indirect or consequential damages whatsoever (including, without
            limitation, damages for loss of business profits, business
            interruption, loss of business information, or any other pecuniary
            loss) arising out of the use (or misuse) of the Service even if
            CollectiveHub had prior knowledge of the possibility of such
            damages.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            8.2 Nothing in this Agreement shall exclude or limit CollectivePads
            liability for death or personal injury resulting from its
            negligence.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            9. Disputes
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If a User wishes to make a complaint, please contact CollectivePads
            customer service team at Support@CollectiveHub.io. Should any
            dispute not be resolved to your satisfaction you may pursue remedies
            in the governing law jurisdiction set forth below.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            10. Amendment
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub reserves the right to update or modify this Agreement
            or any part thereof at any time or otherwise change the Service
            without notice and you will be bound by such amended Agreement upon
            posting. Therefore, we encourage you to check the terms and
            conditions contained in the version of the Agreement in force at
            such time. Your continued use of the Service shall be deemed to
            attest to your agreement to any amendments to the Agreement.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            11. Governing Law
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            The Agreement and any matters relating hereto shall be governed by,
            and construed in accordance with, the laws of the British Virgin
            Islands. You irrevocably agree that, subject as provided below, the
            courts of the British Virgin Islands shall have exclusive
            jurisdiction in relation to any claim, dispute or difference
            concerning the Agreement and any matter arising therefrom and
            irrevocably waive any right that it may have to object to an action
            being brought in those courts, or to claim that the action has been
            brought in an inconvenient forum, or that those courts do not have
            jurisdiction. Nothing in this clause shall limit the right of
            CollectiveHub to take proceedings against you in any other court of
            competent jurisdiction, nor shall the taking of proceedings in any
            one or more jurisdictions preclude the taking of proceedings in any
            other jurisdictions, whether concurrently or not, to the extent
            permitted by the law of such other jurisdiction.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            12. Severability
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            If a provision of this Agreement is or becomes illegal, invalid or
            unenforceable in any jurisdiction, that shall not affect the
            validity or enforceability in that jurisdiction of any other
            provision hereof or the validity or enforceability in other
            jurisdictions of that or any other provision hereof.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            13. Assignment
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CollectiveHub reserves the right to assign this agreement, in whole
            or in part, at any time without notice. The User may not assign any
            of his/her rights or obligations under this Agreement.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            14. Miscellaneous
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            14.1 No waiver by CollectiveHub of any breach of any provision of
            this Agreement (including the failure of CollectiveHub to require
            strict and literal performance of or compliance with any provision
            of this Agreement) shall in any way be construed as a waiver of any
            subsequent breach of such provision or of any breach of any other
            provision of this Agreement.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            14.2 Nothing in this Agreement shall create or confer any rights or
            other benefits in favour of any third parties not party to this
            Agreement.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            14.3 Nothing in this Agreement shall create or be deemed to create a
            partnership, agency, trust arrangement, fiduciary relationship or
            joint venture between you and CollectiveHub. residence.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            14.4 CollectiveHub may assign, transfer, charge, sub-license, or
            deal in any other manner with this Agreement, or sub-contract any of
            its rights and obligations under this Agreement, to any other party.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            14.5 This Agreement constitutes the entire understanding and
            agreement between you and CollectiveHub regarding the Service and
            supersedes any prior agreement, understanding, or arrangement
            between you and CollectiveHub.
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
            fontWeight={700}
          >
            15. Cryptocurrency
          </Typography>

          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            CRYPTOCURRENCY VALUES CAN FLUCTUATE GREATLY IN VALUE DEPENDING ON
            MARKET CONDITIONS.
          </Typography>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            THE USER WARRANTS THAT IT IS AWARE OF THE VOLATILE NATURE OF
            CRYPTOCURRENCIES, AND HOLDS COLLECTIVEPAD HARMLESS FOR ANY LOSS OR
            DAMAGES ARISING FROM SUCH VOLATILITY{' '}
          </Typography>
        </Stack>
        <Stack gap={2} marginTop={10}>
          <Typography
            fontSize={'16px'}
            textAlign={'justify'}
            color={isLightMode ? 'secondary' : basicTone100}
          >
            To ask questions or make comments on this Notice or to make a
            complaint about our compliance with applicable privacy laws, please
            contact us through our email address:{' '}
            <strong>info@collectivepad.com</strong>
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}

export default TermsAndConditionsPage
