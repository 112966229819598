import { useContext } from "react";
import { TCoin } from "./types";
import LoggedInTable from "./marketplace-lists/LoggedInTable";
import LoggedOutTable from "./marketplace-lists/LoggedOutTable";
import { useEthers } from "@usedapp/core";
import { AccountContext } from "../../shared/providers/account-provider";

export const MarketplaceList = ({ tokensList }: { tokensList: TCoin[] }) => {
  const { isConnectedToMetamask } = useContext(AccountContext);

  return isConnectedToMetamask ? (
    <LoggedInTable tokensList={tokensList} />
  ) : (
    <LoggedOutTable tokensList={tokensList} />
  );
};

export default MarketplaceList;
