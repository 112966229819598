export const RocketColorIconSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.81312 16.2534C9.87243 18.7269 6.77391 18.4076 4.36914 20.1034C6.05999 17.7059 5.74806 14.6191 8.19756 14.6594C8.65607 15.2707 9.19386 15.8029 9.81312 16.2534Z"
      fill="#F7AF48"
    />
    <path
      d="M10.9321 8.54235L6.87684 8.43971C6.35043 8.42633 5.8416 8.62962 5.46936 9.00186L3.6579 10.8133C3.36554 11.1057 3.49854 11.6049 3.89753 11.7132L6.77919 12.4946"
      fill="#0D0D0E"
    />
    <path
      d="M10.9321 8.54235L6.87684 8.43971C6.35043 8.42633 5.8416 8.62962 5.46936 9.00186L3.6579 10.8133C3.36554 11.1057 3.49854 11.6049 3.89753 11.7132L6.77919 12.4946"
      fill="url(#paint0_linear_2345_5683)"
    />
    <path
      d="M15.9307 13.541L16.0334 17.5963C16.0468 18.1227 15.8435 18.6315 15.4712 19.0038L13.6598 20.8152C13.3674 21.1076 12.8682 20.9746 12.7599 20.5756L11.9785 17.6939"
      fill="#0D0D0E"
    />
    <path
      d="M15.9307 13.541L16.0334 17.5963C16.0468 18.1227 15.8435 18.6315 15.4712 19.0038L13.6598 20.8152C13.3674 21.1076 12.8682 20.9746 12.7599 20.5756L11.9785 17.6939"
      fill="url(#paint1_linear_2345_5683)"
    />
    <path
      d="M15.7523 4.823C13.0332 6.36847 9.94923 8.89984 6.47168 12.4944C7.51231 15.125 9.34794 16.9607 11.9788 18.0015C15.5733 14.524 18.1049 11.44 19.6504 8.72091C18.9125 6.86012 17.6131 5.56068 15.7523 4.823Z"
      fill="#D0D0D0"
    />
    <path
      d="M14.4401 11.9917C15.4618 11.9917 16.2899 11.1635 16.2899 10.1419C16.2899 9.1203 15.4618 8.29211 14.4401 8.29211C13.4185 8.29211 12.5903 9.1203 12.5903 10.1419C12.5903 11.1635 13.4185 11.9917 14.4401 11.9917Z"
      fill="#0D0D0E"
    />
    <path
      d="M15.7524 4.82352C17.6476 3.74655 19.3658 3.14745 20.916 3.00227C21.2336 2.97252 21.5004 3.23931 21.4706 3.55683C21.3254 5.10709 20.7265 6.8253 19.6494 8.72044C17.9523 7.8196 16.6533 6.52056 15.7524 4.82352Z"
      fill="#0D0D0E"
    />
    <path
      d="M15.7524 4.82352C17.6476 3.74655 19.3658 3.14745 20.916 3.00227C21.2336 2.97252 21.5004 3.23931 21.4706 3.55683C21.3254 5.10709 20.7265 6.8253 19.6494 8.72044C17.9523 7.8196 16.6533 6.52056 15.7524 4.82352Z"
      fill="url(#paint2_linear_2345_5683)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2345_5683"
        x1="3.5"
        y1="8.43909"
        x2="11.2607"
        y2="9.2173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2345_5683"
        x1="11.9785"
        y1="13.541"
        x2="16.2521"
        y2="13.6686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2345_5683"
        x1="15.7524"
        y1="3"
        x2="21.7679"
        y2="3.32915"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF377F" />
        <stop offset="1" stopColor="#FF3743" />
      </linearGradient>
    </defs>
  </svg>
);
