import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { authenticationApi } from "../services/authenticationApi";
import { blogApi } from "../services/blogApi";
import { contactApi } from "../services/contactApi";
import { featuredProjectsApi } from "../services/featuredProjectsApi";
import { fundingFeaturedProjectsApi } from "../services/fundingFeaturedProjectsApi";
import { walletApi } from "../services/walletApi";
import { kycApi } from "../services/kycAPI";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [featuredProjectsApi.reducerPath]: featuredProjectsApi.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [fundingFeaturedProjectsApi.reducerPath]:
      fundingFeaturedProjectsApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [kycApi.reducerPath]: kycApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      featuredProjectsApi.middleware,
      authenticationApi.middleware,
      fundingFeaturedProjectsApi.middleware,
      walletApi.middleware,
      blogApi.middleware,
      contactApi.middleware,
      kycApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
