import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { secondaryColor1 } from "../../../../shared/styles/constants";
import Divider from "@mui/material/Divider";

export const Highlights = (projectData: IFeaturedProject) => {
  const { overview } = projectData.currentFundingRound;
  return (
    <Grid container gap={2} paddingTop={3}>
      <Divider />
      <Grid item container alignItems="center" gap={1.5} paddingTop={3}>
        <StopRoundedIcon htmlColor={secondaryColor1} fontSize="small" />
        <Typography
          variant="h5"
          color="secondary"
          fontWeight={700}
          fontSize="20px"
        >
          Highlights
        </Typography>
      </Grid>
      <Typography
        variant="h6"
        color="secondary"
        fontWeight={400}
        lineHeight="28px"
      >
        {overview.highlights}
      </Typography>
    </Grid>
  );
};
export default Highlights;
