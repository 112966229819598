import React, { useContext, useState, useEffect } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { Contract, utils } from "ethers";

import { SupportedToken } from "./types";
import {
  basicTone50,
  basicTone500,
  basicTone700,
  whiteTone,
} from "../../shared/styles/constants";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";

export const SupportedTokenCard = (token: SupportedToken) => {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Card
      sx={{
        padding: "16px",
        display: "flex",
        borderRadius: "4px",
        boxShadow: "none",
        border: isLightMode
          ? `1px solid ${basicTone50}`
          : `1px solid ${basicTone500}`,
        backgroundColor: isLightMode ? whiteTone : basicTone700,
      }}
    >
      <Grid container alignItems={"center"} gap={1} flexWrap={"nowrap"}>
        <img
          src={token.logoUrl}
          alt="logourl"
          style={{
            height: "24px",
            width: "24px",
            borderRadius: "50%",
          }}
        />
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {token.symbol}
        </Typography>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        justifyContent="flex-end"
        width="auto"
      >
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {token.balance}
        </Typography>
      </Grid>
    </Card>
  );
};
