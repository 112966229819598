import React from "react";
import { Grid, Typography, Stack } from "@mui/material";

export const ManageTabContent = () => {
  return (
    <Grid paddingTop={4}>
      <Stack gap={2} justifyContent="center" paddingBottom="50px">
        <Typography variant="h4" color="secondary">
          Manage
        </Typography>
        <Typography variant="h4" color="secondary">
          Coming soon...
        </Typography>
      </Stack>
    </Grid>
  );
};

export default ManageTabContent;
