import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";
import { ICreateProject, IFeaturedProject } from "../models/project.model";

//Set a return type for result from backend(object)
interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const contactApi: any = createApi({
  reducerPath: "contactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    contactUs: builder.mutation<
      void,
      { email: string; name: string; message: string }
    >({
      query: (data) => ({
        url: "/support-messages/create-support-message",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useContactUsMutation } = contactApi;
