import React, { createContext, FC, useState, useEffect } from "react";
import { Alert, Slide, Snackbar, Typography } from "@mui/material";

type TNotification = {
  message: string | undefined;
  type: "success" | "error";
};

export interface INotificationsContext {
  setNotification: (notification: TNotification | undefined) => void;
  notificationData: TNotification | undefined;
}

export const NotificationsContext = createContext<INotificationsContext>({
  setNotification: (notification: TNotification | undefined) => {},
  notificationData: undefined,
});

export const NotificationsProvider: FC<{ children?: React.ReactNode }> = (
  props
) => {
  const [notificationData, setNotificationData] = useState<
    TNotification | undefined
  >(undefined);

  const setNotification = (notification: TNotification | undefined) => {
    setNotificationData(notification);
  };

  return (
    <NotificationsContext.Provider
      value={{
        setNotification,
        notificationData,
      }}
    >
      {props.children}
      <Snackbar
        open={!!notificationData}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        onClose={() => setNotificationData(undefined)}
        TransitionComponent={(props: any) => (
          <Slide {...props} direction="up" />
        )}
      >
        <Alert
          severity={notificationData?.type}
          sx={{ width: "100%" }}
          onClose={() => setNotificationData(undefined)}
        >
          {notificationData?.message}
        </Alert>
      </Snackbar>
    </NotificationsContext.Provider>
  );
};
