import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AppHeaderBar from "./components/AppHeaderBar/AppHeaderBar";
import Footer from "./components/Footer/Footer";
import {
  BlogPage,
  ContactPage,
  DashboardPage,
  Error404Page,
  ExplorePage,
  FeaturedProjectPage,
  KYCProcessFinishedPage,
  KYCVerificationStepsPage,
  MarketplacePage,
  MarketTokenPage,
  PartnerDepositPage,
  PrivacyPolicyPage,
  RegisterPage,
  TermsAndConditionsPage,
  VerifyEmailPage,
  WalletPage,
} from "./pages";
import { ThemeModeContext } from "./shared/providers/theme-mode-provider";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <div className={isLightMode ? "AppLight" : "AppDark"}>
      <AppHeaderBar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<ExplorePage />} />
          <Route path="/validate-email" element={<VerifyEmailPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/my-wallet" element={<WalletPage />} />
          <Route path="/marketplace" element={<MarketplacePage />} />
          <Route
            path="/marketplace/:tokenAddress/:fundingTermsAddress"
            element={<MarketTokenPage />}
          />

          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/kyc-info" element={<KYCVerificationStepsPage />} />
          <Route path="/kyc" element={<KYCProcessFinishedPage />} />
          <Route
            path="/partner/contract/:contractAddress"
            element={<PartnerDepositPage />}
          />
          <Route path="/404" element={<Error404Page />} />
          <Route path="/:projectUrl" element={<FeaturedProjectPage />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
