import { Card } from "@mui/material";

import { useCall } from "@usedapp/core";

import PartnerDepositTabs from "./PartnerDepositTabs";

type PartnerDepositCardProps = {
  fundingContract: any;
  fundingContractAddress: string;
};

export const PartnerDepositCard = ({
  fundingContractAddress,
  fundingContract,
}: PartnerDepositCardProps) => {
  const { value: tokenForSaleAddress, error: tokenForSaleError } =
    useCall({
      contract: fundingContract,
      method: "tokenForSaleAddress",
      args: [],
    }) ?? {};

  return (
    <Card
      sx={{
        margin: "40px 0",
        height: "450px",
      }}
    >
      {tokenForSaleAddress ? (
        <PartnerDepositTabs
          fundingContract={fundingContract}
          tokenForSaleAddress={tokenForSaleAddress[0]}
          fundingContractAddress={fundingContractAddress}
        />
      ) : null}
    </Card>
  );
};

export default PartnerDepositCard;
