import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  basicTone200,
  secondaryColor1,
} from "../../../../shared/styles/constants";
import Stack from "@mui/material/Stack";
import { formatBigNumbersStrings, formatDate } from "../../../../shared/utils";
import Divider from "@mui/material/Divider";

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid item xs={6} sm={4} paddingBottom={2}>
    <Stack gap={0.5}>
      <Typography variant="body2" color={basicTone200}>
        {label}
      </Typography>
      <Typography variant="h5" color={"secondary"} fontWeight={700}>
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Stack>
  </Grid>
);

export const TokenomicsContent = (projectData: IFeaturedProject) => {
  const {
    name,
    targetAmount,
    dollarTargetAmount,
    pricePerToken,
    minAllocation,
    maxAllocation,
    signUpDeadline,
    signUpStartDate,
    vestingDuration,
    vestingStartDate,
    initialMarketCap,
    initialTokenSupply,
  } = projectData.currentFundingRound;

  return (
    <Grid container gap={2} paddingTop={3}>
      <Divider />
      <Grid item container alignItems="center" gap={1.5} paddingTop={3}>
        <StopRoundedIcon htmlColor={secondaryColor1} fontSize="small" />
        <Typography
          variant="h5"
          color="secondary"
          fontWeight={700}
          fontSize="20px"
        >
          Tokenomics
        </Typography>
      </Grid>
      <Grid item container gap={3}>
        <Grid item container>
          <ValueWithLabel label="Round name" value={name} />
          <ValueWithLabel label="Target amount" value={targetAmount} />
          <ValueWithLabel
            label="Dollar target amount"
            value={dollarTargetAmount}
            valPrefix="$"
          />
        </Grid>
        <Grid item container>
          <ValueWithLabel
            label="Price per token"
            value={pricePerToken}
            valPrefix="$"
          />
          <ValueWithLabel
            label="Min allocation"
            value={minAllocation}
            valPrefix="$"
          />
          <ValueWithLabel
            label="Max allocation"
            value={maxAllocation}
            valPrefix="$"
          />
        </Grid>
        <Grid item container>
          <ValueWithLabel
            label="Sign up start date"
            value={formatDate(signUpStartDate)}
          />
          <ValueWithLabel
            label="Sign up deadline"
            value={formatDate(signUpDeadline)}
          />
        </Grid>
        <Grid item container>
          <ValueWithLabel
            label="Vesting start date"
            value={formatDate(vestingStartDate)}
          />
          <ValueWithLabel
            label="Vesting duration"
            value={vestingDuration}
            valSufix={vestingDuration === "1" ? " Day" : " Days"}
          />
        </Grid>
        <Grid item container>
          <ValueWithLabel
            label="Initial market cap"
            value={formatBigNumbersStrings(initialMarketCap)}
            valPrefix="$"
          />
          <ValueWithLabel
            label="Initial token supply"
            value={initialTokenSupply}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TokenomicsContent;
